import { useSelector } from 'react-redux';
import axios from 'axios';
import _get from 'lodash/get';

import { State } from 'state/types';
import { API_MARKETPLACE_URL } from 'config/constants/envConst';
import { setItems, setMetaItem } from '.';
import { useAppDispatch } from 'state';
import { setLoading } from 'state/authen';

export const useItems = () => {
  const dispatch = useAppDispatch();

  const getItems = async (body: any) => {
    try {
      dispatch(setLoading(true));
      const { data } = await axios.post(
        `${API_MARKETPLACE_URL}/api/v1/item`,
        body
      );
      dispatch(setLoading(false));
      const items = _get(data, 'data', []);
      const metaItem = _get(data, 'meta', null);

      dispatch(setItems(items));
      dispatch(setMetaItem(metaItem));
    } catch (error) {
      dispatch(setLoading(false));
    }
  };

  const getItemDetail = async (token_id: string) => {
    return await axios.get(
      `${API_MARKETPLACE_URL}/api/v1/item/detail?token_id=${token_id}`
    );
  };

  const getMonsterInGameDetail = async (token_id: string) => {
    return await axios.get(
      `${API_MARKETPLACE_URL}/api/v1/monster/detail?monsterId=${token_id}`
    );
  };

  return { getItems, getItemDetail, getMonsterInGameDetail };
};

export const useItemState = () => {
  const box = useSelector((state: State) => state.item);

  return box;
};
