import _get from 'lodash/get';
import { useHistory } from 'react-router-dom';
import { useWeb3React } from '@web3-react/core';

import { API_MARKETPLACE_URL } from 'config/constants/envConst';
import ApiService from 'services/api';
import { signMessage } from 'utils/web3React';
import { setEmail, setNonce, setLoading } from '.';
import { useAppDispatch } from 'state';
import { State } from 'state/types';
import { useSelector } from 'react-redux';
import useAuth from 'hooks/useAuth';

export const useAuthen = () => {
  const history = useHistory();
  const { library } = useWeb3React();
  const dispatch = useAppDispatch();
  const { logout } = useAuth();

  const requestNonceMessage = (wallet: string) => {
    return ApiService.post(`${API_MARKETPLACE_URL}/api/v1/user/nonce`, {
      wallet,
    });
  };

  const requestAuth = (wallet: string, signature: string) => {
    return ApiService.post(`${API_MARKETPLACE_URL}/api/v1/user/auth`, {
      wallet,
      signature,
    });
  };

  const verifyEmail = (wallet: string, email: string) => {
    return ApiService.post(
      `${API_MARKETPLACE_URL}/api/v1/user/send-verification-code`,
      {
        wallet,
        email,
      }
    );
  };

  const linkWallet = (
    wallet: string,
    email: string,
    otp: string,
    refCode?: string
  ) => {
    return ApiService.put(`${API_MARKETPLACE_URL}/api/v1/user/update`, {
      wallet,
      email,
      otp,
      code: refCode,
    });
  };

  const sign = async (wallet: string) => {
    dispatch(setLoading(true));
    try {
      const res1 = await requestNonceMessage(wallet);

      if (res1.data.status === 'success') {
        const message = _get(res1, 'data.message', '');

        if (message) {
          const signature = await signMessage(library, wallet, message);
          dispatch(setNonce(signature));

          const res2 = await requestAuth(wallet, signature);

          if (res2.data.status === 'success') {
            dispatch(setLoading(false));
            const access_token = _get(res2, 'data.data.access_token', '');
            localStorage.setItem('access_token', access_token);
            const email = _get(res2, 'data.data.email', '');

            if (!email) {
              history.push('/user-link');
              dispatch(setEmail(''));
            } else {
              dispatch(setEmail(email));
              history.push('/');
            }
          }
        }
      }
    } catch (e) {
      logout();
      dispatch(setLoading(false));
      console.log(e);
    }
  };

  return {
    requestNonceMessage,
    requestAuth,
    verifyEmail,
    sign,
    linkWallet,
  };
};

export const useAuthenState = () => {
  const authen = useSelector((state: State) => state.authen);

  return authen;
};
