/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import _isEmpty from 'lodash/isEmpty';
import _isArray from 'lodash/isArray';

import MyNftLayout from 'components/Layout/MyNftLayout';
import { useMyNft, useMyNftState } from 'state/myNft/hooks';
import { useWeb3React } from '@web3-react/core';
import CommingSoonModal from 'components/CommingSoonModal';
import Filter from './components/Filter';
import { useAuthenState } from 'state/authen/hooks';
import MonsterDetail from './components/MonsterDetail';
import { Link, useHistory } from 'react-router-dom';
import ItemDetail from './components/ItemDetail';

const bgMonster: any = {
  N: 'images/card/grey.png',
  R: 'images/card/blue.png',
  SR: 'images/card/pink.png',
  SSR: 'images/card/gold.png',
};

const InGame = () => {
  const { getMonstersInGame, getItemsInGame } = useMyNft();
  const { account } = useWeb3React();
  const { monstersInGame, itemsInGame } = useMyNftState();
  const { email } = useAuthenState();
  const history = useHistory();

  const [isOpenModalComing, setOpenModalComing] = useState(false);
  const [selected, setSelected] = useState(null);
  const [data, setData] = useState(monstersInGame);
  const [cloneData, setCloneData] = useState(monstersInGame);
  const [type, setType] = useState('monsters');
  const [status, setStatus] = useState(['onMarket', 'notOnMarket']);

  const token = localStorage.getItem('access_token');

  useEffect(() => {
    if (account && token && !email) {
      history.push('/user-link');
    }
  }, [token, account, email]);

  useEffect(() => {
    async function fetchData(address: string) {
      await getMonstersInGame(address);
      await getItemsInGame(address);
    }

    if (account) {
      fetchData(account);
    }
  }, [account]);

  useEffect(() => {
    if (_isArray(data)) {
      let filteredData = [...cloneData];

      if (status.length === 0) {
        filteredData = [];
      }
      if (status.length === 2) {
        filteredData = [...cloneData];
      }

      if (status.length === 1 && status.includes('onMarket')) {
        filteredData = cloneData.filter(
          (item: any) => item.is_listing === true
        );
      }

      if (status.length === 1 && status.includes('onNotMarket')) {
        filteredData = cloneData.filter(
          (item: any) => item.is_listing === false
        );
      }

      setData(filteredData);
    }
  }, [status]);

  useEffect(() => {
    if (!_isEmpty(monstersInGame) && type === 'monsters') {
      setData(monstersInGame);
      setCloneData(monstersInGame);
    }

    if (!_isEmpty(itemsInGame) && type === 'items') {
      setData(itemsInGame);
      setCloneData(itemsInGame);
    }
  }, [type, JSON.stringify(monstersInGame), JSON.stringify(itemsInGame)]);

  return (
    <MyNftLayout>
      <div className="in-nft-wrapper">
        <div className="in-wallet in-nft" style={{ width: '100%' }}>
          <div className="in-nft-wrapper">
            <div className="in-nft-left">
              <div className="in-nft-header">
                <Link to="/in-wallet" className={'header-tab cursor-pointer'}>
                  <img src={'images/wallet/in-deact.png'} alt="active" />
                  <span className="in-nft-txt">In Wallet</span>
                </Link>
                <Link
                  to="/in-game"
                  className={'header-tab cursor-pointer active'}
                >
                  <img src={'images/wallet/in-active.png'} alt="active" />
                  <span className="in-nft-txt">In game</span>
                </Link>
              </div>

              {!selected && (
                <div className="in-nft-body">
                  <div className="custom-scrollbar-wallet">
                    {_isEmpty(data) && (
                      <div className="empty-txt">
                        <span>
                          Buy your first nft on the Etermon marketplace
                        </span>
                      </div>
                    )}
                    <div className="list-card ">
                      {!_isEmpty(data) && (
                        <div className="list-nft-row">
                          {type === 'monsters' && (
                            <>
                              {data.map((item: any, idx: number) => (
                                <div
                                  onClick={() => setSelected(item)}
                                  className="list-monster-col cursor-pointer"
                                  key={idx}
                                >
                                  <div className="monster-id">
                                    <span className="id-number">
                                      {item.tokenNFT
                                        ? `#${item.tokenNFT}`
                                        : '---'}
                                    </span>
                                  </div>

                                  <div className="monster-img-box">
                                    <img
                                      src={item.class_icon}
                                      className="monster-class"
                                      alt="class"
                                    />
                                    {item.is_listing && (
                                      <div className="ribbon ribbon-top-right">
                                        <span>selling</span>
                                      </div>
                                    )}

                                    <img
                                      src={bgMonster[item.rank]}
                                      className="monster-bg"
                                      alt="background"
                                    />
                                    <img
                                      src={item.image}
                                      className="monster-img"
                                      alt="monster"
                                    />
                                    <img
                                      src={item.rank_icon}
                                      className="monster-rank"
                                      alt="rank"
                                    />
                                    <img
                                      className="icon-gender"
                                      src={
                                        item.gender === 'male'
                                          ? 'images/gender/male.png'
                                          : 'images/gender/female.png'
                                      }
                                      alt="gender male icon"
                                    />
                                    <div className="monster-info">
                                      <img
                                        className="monster-element"
                                        src={item.element_icon}
                                        alt="element"
                                      />
                                      <span className="monster-name">
                                        {item.name}
                                      </span>
                                    </div>
                                    <span className="monster-lv">
                                      Level {item.level}
                                    </span>
                                  </div>
                                </div>
                              ))}
                            </>
                          )}

                          {type === 'items' && (
                            <>
                              {data.map((item: any, idx: number) => (
                                <div
                                  onClick={() => setSelected(item)}
                                  key={idx}
                                  className="list-item-col"
                                >
                                  <div className="monster-id">
                                    <span className="id-number">
                                      {item.tokenNFT
                                        ? `#${item.tokenNFT}`
                                        : '---'}
                                    </span>
                                  </div>

                                  <div className="item-img-box">
                                    <img
                                      src={item.image}
                                      className="item-img"
                                      alt="item 1"
                                    />
                                    <div className="name-box">
                                      <span>{item.name}</span>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}

              {type === 'monsters' && selected && (
                <MonsterDetail setSelected={setSelected} item={selected} />
              )}

              {type === 'items' && selected && (
                <ItemDetail setSelected={setSelected} item={selected} />
              )}
            </div>

            <Filter
              status={status}
              setStatus={setStatus}
              type={type}
              setType={setType}
              setData={setData}
              setSelected={setSelected}
            />
          </div>
        </div>
      </div>

      <CommingSoonModal
        isOpen={isOpenModalComing}
        closeModal={() => setOpenModalComing(false)}
      />
    </MyNftLayout>
  );
};

export default InGame;
