/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import _isEmpty from 'lodash/isEmpty';
import dayjs from 'dayjs';
import Countdown from 'react-countdown';

import ApiService from 'services/api';
import { API_MARKETPLACE_URL } from 'config/constants/envConst';
import { truncate } from 'utils';
import { useWeb3React } from '@web3-react/core';
import { toast } from 'react-toastify';
import { Nav, TabContent, TabPane } from 'reactstrap';
import MonsterClaimConfirm from './MonsterClaimConfirm';
import femaleImg from 'assets/images/gender/female.png';
import maleImg from 'assets/images/gender/male.png';

interface Props {
  item: any;
  setSelected: (item: any) => void;
}

const MonsterDetail: React.FC<Props> = ({ setSelected, item }) => {
  const { account } = useWeb3React();

  const [isOpenModalConfirm, setOpenModalConfirm] = useState(false);
  const [monster, setMonster] = useState<any>(null);
  const [activeTab, setActiveTab] = useState('ability');

  const bscUrl = `${process.env.REACT_APP_BSC_SCAN}/address`;

  useEffect(() => {
    async function getMonster(id: string) {
      try {
        const { data } = await ApiService.get(
          `${API_MARKETPLACE_URL}/api/v1/nft/monsters/detail?id=${id}`
        );

        if (data.status === 'success') {
          setMonster(data.data);
        }
      } catch (error) {
        console.log(error);
      }
    }

    if (item.id) {
      getMonster(item.id);
    }
  }, []);

  let isLock = false;
  if (monster) {
    isLock = dayjs().isBefore(dayjs(monster.lockTime));
  }
  return (
    <div className="in-nft-body box-detail">
      {monster && (
        <div className="body-detail row">
          <div className="col-12 col-md-12 col-lg-6">
            <div className="monster-left-container">
              <div className="monster-detail-back">
                <div
                  onClick={() => setSelected(null)}
                  className="back-wrapper cursor-pointer"
                >
                  <img src="images/back.png" className="back-img" alt="back" />
                </div>
              </div>
              <div className="monster-img-cover">
                <img
                  src={monster.rank_icon}
                  className="m-detail-rank"
                  alt="rank"
                />
                <img
                  src="images/detail/monster-frame.png"
                  className="monster-frame"
                  alt="frame"
                />
                <img
                  src={monster.image_open_box}
                  className="m-detail-img"
                  alt="item"
                />
                <img
                  src="images/detail/stage.png"
                  className="monster-stage"
                  alt="stage"
                />
              </div>
              <div className="monster-detail-info">
                <div className="monster-detail-element">
                  <img
                    src={monster.element_icon}
                    className="detail-element"
                    alt="element"
                  />
                </div>
                <div className="monster-detail-class">
                  <img
                    src={monster.class_icon}
                    className="detail-class"
                    alt="class"
                    style={{ width: '100%', height: 'auto' }}
                  />
                </div>
                <div className="monster-detail-gender">
                  <img
                    src={monster.gender === 'male' ? maleImg : femaleImg}
                    className="detail-gender"
                    alt="gender"
                  />
                </div>
                <div className="monster-detail-breed">
                  <span className="breed-number">{monster.breed}</span>
                  <span className="breed-count">Breed count</span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-12 col-lg-6">
            <div className="monster-right-container">
              <div className="detail-info detail-split">
                <div className="detail-owner">
                  Owner:{' '}
                  <a
                    href={`${bscUrl}/${account}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {truncate(account || '', 6, 6)}
                  </a>
                  <button
                    className="btn btn-copy"
                    id="jsCopyButton"
                    onClick={() => {
                      if (navigator.clipboard) {
                        navigator.clipboard.writeText(account || '');
                        toast.info('Copied');
                      }
                    }}
                  >
                    <i className="fas fa-copy"></i>
                  </button>
                </div>
                <div className="detail-owner">#{monster.tokenNFT}</div>
              </div>
              <div className="detail-info detail-split detail-monster mb-3">
                <nav className="nav-detail">
                  <Nav className="nav nav-tabs" id="nav-tab" role="tablist">
                    <button
                      className={cn('nav-link', {
                        active: activeTab === 'ability',
                      })}
                      id="nav-ability-tab"
                      onClick={() => setActiveTab('ability')}
                      type="button"
                      role="tab"
                    >
                      Ability
                    </button>
                    <button
                      className={cn('nav-link', {
                        active: activeTab === 'skill',
                      })}
                      id="nav-skill-tab"
                      onClick={() => setActiveTab('skill')}
                      type="button"
                      role="tab"
                    >
                      Skill
                    </button>
                    {/* <button
                    className="nav-link"
                    id="nav-parent-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#nav-parent"
                    type="button"
                    role="tab"
                    aria-controls="nav-parent"
                    aria-selected="false"
                  >
                    Parent
                  </button>
                  <button
                    className="nav-link"
                    id="nav-story-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#nav-story"
                    type="button"
                    role="tab"
                    aria-controls="nav-story"
                    aria-selected="false"
                  >
                    Story
                  </button> */}
                  </Nav>
                </nav>
                <TabContent activeTab={activeTab} id="nav-tabContent">
                  <TabPane
                    tabId="ability"
                    role="tabpanel"
                    aria-labelledby="nav-ability-tab"
                  >
                    <div className="m-ability-list monster-right-bg">
                      <div className="ability-box">
                        <div className="ability">
                          <img
                            className="ability-img"
                            src="images/detail/damage.png"
                            alt="ability 1"
                          />
                        </div>
                        <span className="ability-number">
                          {monster.ability.stats.atk}
                        </span>
                      </div>
                      <div className="ability-box">
                        <div className="ability">
                          <img
                            className="ability-img"
                            src="images/detail/luck.png"
                            alt="ability 2"
                          />
                        </div>
                        <span className="ability-number">
                          {monster.ability.stats.matk}
                        </span>
                      </div>

                      <div className="ability-box">
                        <div className="ability">
                          <img
                            className="ability-img"
                            src="images/detail/book.png"
                            alt="ability 4"
                          />
                        </div>
                        <span className="ability-number">
                          {monster.ability.stats.mdef}
                        </span>
                      </div>
                      <div className="ability-box">
                        <div className="ability">
                          <img
                            className="ability-img"
                            src="images/detail/armor.png"
                            alt="ability 5"
                          />
                        </div>
                        <span className="ability-number">
                          {monster.ability.stats.def}
                        </span>
                      </div>
                      <div className="ability-box">
                        <div className="ability">
                          <img
                            className="ability-img"
                            src="images/detail/speed.png"
                            alt="ability 6"
                          />
                        </div>
                        <span className="ability-number">
                          {monster.ability.stats.spd}
                        </span>
                      </div>

                      <div className="ability-box">
                        <div className="ability">
                          <img
                            className="ability-img"
                            src="images/detail/heart.png"
                            alt="ability 8"
                          />
                        </div>
                        <span className="ability-number">
                          {monster.ability.stats.hp}
                        </span>
                      </div>

                      <div className="ability-box">
                        <div className="ability">
                          <img
                            className="ability-img"
                            src="images/detail/def.png"
                            alt="ability 7"
                          />
                        </div>
                        <span className="ability-number">
                          {monster.ability.stats.dodge}
                        </span>
                      </div>

                      <div className="ability-box">
                        <div className="ability">
                          <img
                            className="ability-img"
                            src="images/detail/crit.png"
                            alt="ability 3"
                          />
                        </div>
                        <span className="ability-number">
                          {monster.ability.stats.crit}
                        </span>
                      </div>
                    </div>
                  </TabPane>
                  <TabPane
                    tabId="skill"
                    role="tabpanel"
                    aria-labelledby="nav-skill-tab"
                  >
                    <div className="m-detail-skill-list monster-right-bg">
                      {!_isEmpty(monster.skills) &&
                        monster.skills.map((item: any) => (
                          <div className="m-detail-skill-item" key={item.id}>
                            <img
                              src={item.image}
                              className="m-detail-skill-img"
                              alt="skill"
                              style={{ width: 41, height: 43 }}
                            />
                            <span className="m-detail-skill-name">
                              {item.name}
                            </span>
                            <span className="m-detail-skill-lv">
                              Level {item.level}
                            </span>
                          </div>
                        ))}
                    </div>
                  </TabPane>
                  {/* <div
                  className="tab-pane fade"
                  id="nav-parent"
                  role="tabpanel"
                  aria-labelledby="nav-parent-tab"
                >
                  <div className="m-detail-parent-list monster-right-bg">
                    <div className="m-parent">
                      <div className="m-parent-gender">DAD</div>
                      <div className="m-parent-id">#94411643180400</div>
                    </div>
                    <div className="m-parent-info">
                      <div className="m-parent-info-group">
                        <div className="parent-name">
                          Name: <span>Windora Flamedragon</span>
                        </div>
                        <div className="parent-rank">
                          Rank: <span>#1000</span>
                        </div>
                      </div>
                      <div className="parent-ability">
                        Ability: <span></span>
                      </div>
                      <div className="parent-level">
                        Level: <span></span>
                      </div>
                    </div>
                    <div className="m-parent">
                      <div className="m-parent-gender">MoM</div>
                      <div className="m-parent-id">#94411643180400</div>
                    </div>
                    <div className="m-parent-info">
                      <div className="m-parent-info-group">
                        <div className="parent-name">
                          Name: <span>Windora Flamedragon</span>
                        </div>
                        <div className="parent-rank">
                          Rank: <span>#1000</span>
                        </div>
                      </div>
                      <div className="parent-ability">
                        Ability: <span></span>
                      </div>
                      <div className="parent-level">
                        Level: <span></span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="nav-story"
                  role="tabpanel"
                  aria-labelledby="nav-story-tab"
                >
                  <div className="m-detail-story-list monster-right-bg">
                    <span className="m-detail-story-txt">
                      Living in the central volcano Radius of the Eternity
                      planet, the Windora is the king of this mountain, they
                      have a flexible body and huge attack power and of course,
                      hot-tempered indeed. So be careful and do not make them
                      mad
                    </span>
                  </div>
                </div> */}
                </TabContent>
              </div>
              <div className="detail-info-footer wallet-action">
                <div className="d-flex justify-content-around">
                  <div className="claim-btn-wrapper w-100">
                    <button
                      className="claim-btn"
                      disabled={isLock}
                      onClick={() => {
                        if (item.isHasInTeam) {
                          toast.info('Monster is on the team!');
                        } else if (item.isHasEquipment) {
                          toast.info('Monster is equipping item!');
                        } else if (item.isBreed) {
                          toast.info('Monster is breeding!');
                        } else {
                          setOpenModalConfirm(true);
                        }
                      }}
                    >
                      {isLock && (
                        <Countdown date={new Date(monster.lockTime)} />
                      )}
                      {!isLock && 'Claim'}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {monster && (
        <MonsterClaimConfirm
          isOpen={isOpenModalConfirm}
          item={monster}
          closeModal={() => setOpenModalConfirm(false)}
          setSelected={setSelected}
        />
      )}
    </div>
  );
};

export default MonsterDetail;
