import React from 'react';
import { Modal } from 'reactstrap';

interface ModalProps {
  isOpen: boolean;
  closeModal: () => void;
}

const CommingSoonModal: React.FC<ModalProps> = ({ isOpen, closeModal }) => {
  return (
    <Modal
      isOpen={isOpen}
      toggle={closeModal}
      centered={true}
      modalClassName="modal-coming"
      id="comingSoonModal"
    >
      <div className="modal-content">
        <div className="modal-body">
          <span> Coming Soon... </span>
        </div>
      </div>
    </Modal>
  );
};

export default CommingSoonModal;
