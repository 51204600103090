import { createSlice } from '@reduxjs/toolkit';

export interface BoxState {
  boxes: any;
  metaBox: any;
}

const initialState: BoxState = {
  boxes: [],
  metaBox: null,
};

export const boxSlice = createSlice({
  name: 'box',
  initialState,
  reducers: {
    setBoxes: (state, action) => {
      state.boxes = action.payload;
    },
    setMetaBox: (state, action) => {
      state.metaBox = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setBoxes, setMetaBox } = boxSlice.actions;

export default boxSlice.reducer;
