import { ConnectorNames } from 'config';

export interface ConfigWallet {
  title: string;
  icon: any;
  connectorId: ConnectorNames;
  priority: number;
}

export const connectors: ConfigWallet[] = [
  {
    title: 'Metamask',
    icon: '/images/connect/metamask.svg',
    connectorId: ConnectorNames.Injected,
    priority: 1,
  },
  {
    title: 'WalletConnect',
    icon: '/images/connect/wallet-connect.svg',
    connectorId: ConnectorNames.WalletConnect,
    priority: 2,
  },
  {
    title: 'Trust Wallet',
    icon: '/images/connect/trust-wallet.svg',
    connectorId: ConnectorNames.Injected,
    priority: 3,
  },
  {
    title: 'MathWallet',
    icon: '/images/connect/math-wallet.svg',
    connectorId: ConnectorNames.Injected,
    priority: 999,
  },
  {
    title: 'TokenPocket',
    icon: '/images/connect/token-pocket.svg',
    connectorId: ConnectorNames.Injected,
    priority: 999,
  },

  {
    title: 'Binance Chain',
    icon: '/images/connect/binance-chain.svg',
    connectorId: ConnectorNames.BSC,
    priority: 999,
  },
  {
    title: 'SafePal',
    icon: '/images/connect/safepal.svg',
    connectorId: ConnectorNames.Injected,
    priority: 999,
  },
  {
    title: 'Coin98',
    icon: '/images/connect/coin98.svg',
    connectorId: ConnectorNames.Injected,
    priority: 999,
  },
];
