/* eslint-disable react-hooks/exhaustive-deps */
import Layout from 'components/Layout';
import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import { Nav, Spinner, TabContent, TabPane } from 'reactstrap';
import { Link, useParams } from 'react-router-dom';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import { toast } from 'react-toastify';

import monsterFrameImg from '../../assets/images/detail/monster-frame.png';
import stageImg from '../../assets/images/detail/stage.png';
import damageImg from '../../assets/images/detail/damage.png';
import luckImg from '../../assets/images/detail/luck.png';
import critImg from '../../assets/images/detail/crit.png';
import bookImg from '../../assets/images/detail/book.png';
import armorImg from '../../assets/images/detail/armor.png';
import speedImg from '../../assets/images/detail/speed.png';
import defImg from '../../assets/images/detail/def.png';
import heartImg from '../../assets/images/detail/heart.png';
import femaleImg from 'assets/images/gender/female.png';
import maleImg from 'assets/images/gender/male.png';
import { useMonsters } from 'state/monster/hooks';
import { useMarket, useMarketData } from 'state/market/hooks';
import {
  ETERMON_MONSTER_ADDRESS,
  TOKEN_ADDRESS,
} from 'config/constants/envConst';
import { truncate } from 'utils';
import { useWeb3React } from '@web3-react/core';
import ConnectModal from 'components/WalletModal/ConnectModal';
import { getMarketplaceAddress } from 'utils/addressHelpers';

interface Props {}

const DetailMonsterPage: React.FC<Props> = () => {
  const { getMonsterDetail } = useMonsters();
  const params: any = useParams();
  const { account } = useWeb3React();
  const {
    matchOrder,
    loading,
    setApprovalBEP20ForAll,
    checkApprovedBEP20ForAll,
    cancelListing,
    getTransactionRawData,
  } = useMarket();
  const { isApproved } = useMarketData();

  const [activeTab, setActiveTab] = useState('ability');
  const [monster, setMonster] = useState<any>(null);
  const [rawMonster, setRawMonster] = useState(null);
  const [isOpenModalConnect, setOpenModalConnect] = useState(false);

  const id = _get(params, 'id', 0);
  const bscUrl = `${process.env.REACT_APP_BSC_SCAN}/address`;
  const seller = _get(monster, 'seller', '');
  const isOwner = account ? seller === account?.toLowerCase() : false;

  useEffect(() => {
    const fetchDetail = async () => {
      try {
        const { data } = await getMonsterDetail(id);

        if (data.status && data.data) {
          setMonster(data.data);
        }
      } catch (error) {}
    };

    const fetchRawTransaction = async () => {
      try {
        const { data } = await getTransactionRawData(
          id,
          'MONSTER',
          ETERMON_MONSTER_ADDRESS
        );

        setRawMonster(data.data);
      } catch (error) {}
    };
    if (id) {
      fetchDetail();
      fetchRawTransaction();
    }
  }, [id]);

  const buy = () => {
    matchOrder(rawMonster);
  };

  const handApprove = async () => {
    await setApprovalBEP20ForAll(TOKEN_ADDRESS, getMarketplaceAddress());
    await checkApprovedBEP20ForAll(
      TOKEN_ADDRESS,
      getMarketplaceAddress(),
      _get(monster, 'price', 0)
    );
  };

  useEffect(() => {
    if (account && monster) {
      checkApprovedBEP20ForAll(
        TOKEN_ADDRESS,
        getMarketplaceAddress(),
        _get(monster, 'price', 0)
      );
    }
  }, [account, monster]);

  const stopCelling = () => {
    cancelListing(rawMonster);
  };

  return (
    <Layout rootClass="main-item-detail">
      <div className="main-nav">
        <div className="white-space"></div>
        <div className="nav-custom nav-detail-custom">
          <div className="nav-item">
            <Link
              className="nav-link nav-back active"
              aria-current="page"
              to="/monster"
            >
              Detail
            </Link>
          </div>
        </div>
      </div>
      {monster && (
        <div className="box-detail container">
          <div className="box-bg">
            <div className="body-detail row">
              <div className="col-12 col-md-12 col-lg-6">
                <div className="monster-left-container">
                  <div className="monster-detail-name">
                    <span>{monster.name}</span>
                  </div>
                  <div className="monster-img-cover">
                    <img
                      src={monster.rank_icon}
                      className="m-detail-rank"
                      alt="rank"
                    />
                    <img
                      src={monsterFrameImg}
                      className="monster-frame"
                      alt="frame"
                    />
                    <img
                      src={monster.image_open_box}
                      className="m-detail-img"
                      alt="item"
                    />
                    <img src={stageImg} className="monster-stage" alt="stage" />
                  </div>
                  <div className="monster-detail-info">
                    <div className="monster-detail-element">
                      <img
                        src={monster.element_icon}
                        className="detail-element"
                        alt="element"
                      />
                    </div>
                    <div className="monster-detail-class">
                      <img
                        src={monster.class_icon}
                        className="detail-class"
                        alt="class"
                        style={{ width: '100%', height: 'auto' }}
                      />
                    </div>
                    <div className="monster-detail-gender">
                      <img
                        src={monster.gender === 'male' ? maleImg : femaleImg}
                        className="detail-gender"
                        alt="gender"
                      />
                    </div>
                    <div className="monster-detail-breed">
                      <span className="breed-number">{monster.breed}</span>
                      <span className="breed-count">Breed count</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-12 col-lg-6">
                <div className="monster-right-container">
                  <div className="detail-info detail-split">
                    <div className="detail-owner">
                      Owner:{' '}
                      <a
                        href={`${bscUrl}/${monster.seller}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {truncate(monster.seller, 6, 6)}
                      </a>
                      <button
                        className="btn btn-copy"
                        id="jsCopyButton"
                        onClick={() => {
                          if (navigator.clipboard) {
                            navigator.clipboard.writeText(monster.seller || '');
                            toast.info('Copied');
                          }
                        }}
                      >
                        <i className="fas fa-copy"></i>
                      </button>
                    </div>
                    <div className="detail-owner">#{monster.tokenNFT}</div>
                  </div>
                  <div className="detail-info detail-split detail-monster mb-3">
                    <nav className="nav-detail">
                      <Nav className="nav nav-tabs" id="nav-tab" role="tablist">
                        <button
                          className={cn('nav-link', {
                            active: activeTab === 'ability',
                          })}
                          onClick={() => setActiveTab('ability')}
                          type="button"
                          role="tab"
                        >
                          Ability
                        </button>
                        <button
                          className={cn('nav-link', {
                            active: activeTab === 'skill',
                          })}
                          onClick={() => setActiveTab('skill')}
                          id="nav-skill-tab"
                          type="button"
                          role="tab"
                        >
                          Skill
                        </button>
                        {/* <button
                          className={cn('nav-link', {
                            active: activeTab === 'parent',
                          })}
                          onClick={() => setActiveTab('parent')}
                          id="nav-parent-tab"
                          type="button"
                          role="tab"
                        >
                          Parent
                        </button>
                        <button
                          className={cn('nav-link', {
                            active: activeTab === 'story',
                          })}
                          onClick={() => setActiveTab('story')}
                          id="nav-story-tab"
                          type="button"
                          role="tab"
                        >
                          Story
                        </button> */}
                      </Nav>
                    </nav>
                    <TabContent activeTab={activeTab} id="nav-tabContent">
                      <TabPane
                        tabId="ability"
                        role="tabpanel"
                        aria-labelledby="nav-ability-tab"
                      >
                        <div className="m-ability-list monster-right-bg">
                          <div className="ability-box">
                            <div className="ability">
                              <img
                                className="ability-img"
                                src={damageImg}
                                alt="ability 1"
                              />
                            </div>
                            <span className="ability-number">
                              {monster.ability.stats.atk}
                            </span>
                          </div>
                          <div className="ability-box">
                            <div className="ability">
                              <img
                                className="ability-img"
                                src={luckImg}
                                alt="ability 2"
                              />
                            </div>
                            <span className="ability-number">
                              {monster.ability.stats.matk}
                            </span>
                          </div>

                          <div className="ability-box">
                            <div className="ability">
                              <img
                                className="ability-img"
                                src={bookImg}
                                alt="ability 4"
                              />
                            </div>
                            <span className="ability-number">
                              {monster.ability.stats.mdef}
                            </span>
                          </div>
                          <div className="ability-box">
                            <div className="ability">
                              <img
                                className="ability-img"
                                src={armorImg}
                                alt="ability 5"
                              />
                            </div>
                            <span className="ability-number">
                              {monster.ability.stats.def}
                            </span>
                          </div>
                          <div className="ability-box">
                            <div className="ability">
                              <img
                                className="ability-img"
                                src={speedImg}
                                alt="ability 6"
                              />
                            </div>
                            <span className="ability-number">
                              {monster.ability.stats.spd}
                            </span>
                          </div>

                          <div className="ability-box">
                            <div className="ability">
                              <img
                                className="ability-img"
                                src={heartImg}
                                alt="ability 8"
                              />
                            </div>
                            <span className="ability-number">
                              {monster.ability.stats.hp}
                            </span>
                          </div>

                          <div className="ability-box">
                            <div className="ability">
                              <img
                                className="ability-img"
                                src={defImg}
                                alt="ability 7"
                              />
                            </div>
                            <span className="ability-number">
                              {monster.ability.stats.dodge}
                            </span>
                          </div>

                          <div className="ability-box">
                            <div className="ability">
                              <img
                                className="ability-img"
                                src={critImg}
                                alt="ability 3"
                              />
                            </div>
                            <span className="ability-number">
                              {monster.ability.stats.crit}
                            </span>
                          </div>
                        </div>
                      </TabPane>
                      <TabPane
                        tabId="skill"
                        role="tabpanel"
                        aria-labelledby="nav-skill-tab"
                      >
                        <div className="m-detail-skill-list monster-right-bg">
                          {!_isEmpty(monster.skills) &&
                            monster.skills.map((item: any) => (
                              <div
                                className="m-detail-skill-item"
                                key={item.id}
                              >
                                <img
                                  src={item.image}
                                  className="m-detail-skill-img"
                                  alt="skill"
                                  style={{ width: 41, height: 43 }}
                                />
                                <span className="m-detail-skill-name">
                                  {item.name}
                                </span>
                                <span className="m-detail-skill-lv">
                                  Level {item.level}
                                </span>
                              </div>
                            ))}
                        </div>
                      </TabPane>
                      {/* <TabPane
                        tabId="parent"
                        role="tabpanel"
                        aria-labelledby="nav-parent-tab"
                      >
                        <div className="m-detail-parent-list monster-right-bg">
                          <div className="m-parent">
                            <div className="m-parent-gender">DAD</div>
                            <div className="m-parent-id">#94411643180400</div>
                          </div>
                          <div className="m-parent-info">
                            <div className="m-parent-info-group">
                              <div className="parent-name">
                                Name: <span>Windora Flamedragon</span>
                              </div>
                              <div className="parent-rank">
                                Rank: <span>#1000</span>
                              </div>
                            </div>
                            <div className="parent-ability">
                              Ability: <span></span>
                            </div>
                            <div className="parent-level">
                              Level: <span></span>
                            </div>
                          </div>
                          <div className="m-parent">
                            <div className="m-parent-gender">MoM</div>
                            <div className="m-parent-id">#94411643180400</div>
                          </div>
                          <div className="m-parent-info">
                            <div className="m-parent-info-group">
                              <div className="parent-name">
                                Name: <span>Windora Flamedragon</span>
                              </div>
                              <div className="parent-rank">
                                Rank: <span>#1000</span>
                              </div>
                            </div>
                            <div className="parent-ability">
                              Ability: <span></span>
                            </div>
                            <div className="parent-level">
                              Level: <span></span>
                            </div>
                          </div>
                        </div>
                      </TabPane>
                      <TabPane
                        tabId="story"
                        role="tabpanel"
                        aria-labelledby="nav-story-tab"
                      >
                        <div className="m-detail-story-list monster-right-bg">
                          <span className="m-detail-story-txt">
                            Living in the central volcano Radius of the Eternity
                            planet, the Windora is the king of this mountain,
                            they have a flexible body and huge attack power and
                            of course, hot-tempered indeed. So be careful and do
                            not make them mad
                          </span>
                        </div>
                      </TabPane> */}
                    </TabContent>
                  </div>
                  <div className="detail-info-footer">
                    <div className="detail-price">
                      <span>
                        {_get(monster, 'price', 0).toLocaleString('en-US', {
                          maximumFractionDigits: 3,
                        })}{' '}
                        ETM
                      </span>
                    </div>

                    {isOwner && (
                      <div className="action-btn-wrapper">
                        <button
                          disabled={loading}
                          className="action-btn"
                          onClick={stopCelling}
                        >
                          {loading && <Spinner size="sm" />}
                          {!loading && 'Stop Selling'}
                        </button>
                      </div>
                    )}

                    {!isOwner && (
                      <div className="action-btn-wrapper">
                        {!account && (
                          <button
                            onClick={() => setOpenModalConnect(true)}
                            className="action-btn"
                          >
                            Connect Wallet
                          </button>
                        )}
                        {account && (
                          <>
                            {isApproved && (
                              <button
                                disabled={loading}
                                className="action-btn"
                                onClick={buy}
                              >
                                {loading && <Spinner size="sm" />}
                                {!loading && 'Buy now'}
                              </button>
                            )}
                            {!isApproved && (
                              <button
                                disabled={loading}
                                className="action-btn"
                                onClick={handApprove}
                              >
                                {loading && <Spinner size="sm" />}
                                {!loading && 'Approve ETM'}
                              </button>
                            )}
                          </>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <ConnectModal
        isOpen={isOpenModalConnect}
        closeModal={() => setOpenModalConnect(false)}
      />
    </Layout>
  );
};

export default DetailMonsterPage;
