/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Modal, Spinner } from 'reactstrap';
import numeral from 'numeral';
import { ethers } from 'ethers';
import { useWeb3React } from '@web3-react/core';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import Web3 from 'web3';

import { useMarket, useMarketData } from 'state/market/hooks';
import {
  BUSD_ADDRESS,
  ETERMON_BOX_ADDRESS,
  ETERMON_ITEM_ADDRESS,
  ETERMON_MONSTER_ADDRESS,
  TOKEN_ADDRESS,
} from 'config/constants/envConst';
import { signMessage } from 'utils/web3React';
import { getMarketplaceAddress } from 'utils/addressHelpers';

interface ModalProps {
  isOpen: boolean;
  closeModal: () => void;
  item: any;
  type: string;
}

export const displayNumeralNoDecimal = (amount: any) =>
  numeral(amount).format('0,0');

const SellingModal: React.FC<ModalProps> = ({
  isOpen,
  closeModal,
  item,
  type,
}) => {
  const web3 = new Web3(
    new Web3.providers.HttpProvider('https://bsc-dataseed.binance.org/')
  );
  const { needApproveAll } = useMarketData();
  const { setApprovalForAll, checkApprovedForAll, listingNFT, loading } =
    useMarket();
  const { account, library } = useWeb3React();
  const history = useHistory();

  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [error, setError] = useState('');

  const [displayValue, setDisplayValue] = useState('');
  const [valueFinal, setValueFinal] = useState('');

  let nftAddress = ETERMON_BOX_ADDRESS;
  if (type === 'monster') {
    nftAddress = ETERMON_MONSTER_ADDRESS;
  } else if (type === 'item') {
    nftAddress = ETERMON_ITEM_ADDRESS;
  }

  useEffect(() => {
    if (account) {
      checkApprovedForAll(nftAddress, getMarketplaceAddress());
    }
  }, [account]);

  const inputChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.currentTarget.value;

    setError('');

    const regex = /^-?\d*[.,]?\d*$/;
    const value = val.split(',').join('');

    if (parseFloat(value) < 9999999999) {
      if (regex.test(value)) {
        // @ts-ignore
        setValueFinal(numeral(value).value());

        if (val.length >= 2 && val.charAt(0) === '0' && val.charAt(1) === '0') {
          return setDisplayValue('0');
        }
        if (value.indexOf('.') > -1) {
          const decimal = value.substring(
            value.indexOf('.') + 1,
            value.indexOf('.') + 19
          );
          const int = value.substring(0, value.indexOf('.'));
          const data = displayNumeralNoDecimal(int) + '.' + decimal;
          return setDisplayValue(data);
        }
        setDisplayValue(value ? numeral(value).format('0,0') : '');
      }
    }
  };

  const handApprove = async () => {
    await setApprovalForAll(nftAddress, getMarketplaceAddress());
    await checkApprovedForAll(nftAddress, getMarketplaceAddress());
  };

  const onSubmit = async () => {
    if (valueFinal === '0' || valueFinal === '') {
      setError('Wrong price for sale. Please check again!');
    } else if (parseFloat(valueFinal) > 99999999) {
      setError('The number you typed is too large.');
    } else {
      if (account) {
        try {
          setLoadingSubmit(true);
          const contract = getMarketplaceAddress();
          const paymentMethod = type !== 'box' ? TOKEN_ADDRESS : BUSD_ADDRESS;

          const seller = account;
          const timestamp = Math.floor(Date.now() / 1000);
          const tokenId = parseInt(
            type !== 'box' ? item.tokenNFT : item.token_id
          );
          const price = parseFloat(valueFinal);

          const hashedDataToSign = web3.utils.soliditySha3(
            contract,
            nftAddress,
            paymentMethod,
            seller,
            timestamp,
            tokenId,
            web3.utils.toWei(price.toString(), 'ether')
          );

          let messageHashBinary = ethers.utils.arrayify(hashedDataToSign || '');

          const signature = await signMessage(
            library,
            account,
            messageHashBinary
          );

          const res = await listingNFT({
            signature,
            message_hash: hashedDataToSign,
            message: {
              seller,
              token_id: tokenId,
              nft_address: nftAddress,
              payment_method: paymentMethod,
              price,
              address: contract,
              timestamp,
            },
            type,
          });

          if (res.status === 200) {
            toast.success('Listing box success!');
            closeModal();
            history.push(`/${type}`);
          }

          setLoadingSubmit(false);
        } catch (e: any) {
          setLoadingSubmit(false);
          if (e.message) {
            toast.error(e.message);
          } else {
            toast.error('Error! Try again.');
          }
        }
      }
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      wrapClassName="modal-confirm"
      className="modal-xl"
      id="sellingModal"
      backdrop="static"
    >
      <button type="button" className="btn-close" onClick={() => closeModal()}>
        <i className="fas fa-times"></i>
      </button>
      <img
        src="images/wallet/confirm-bg.png"
        alt="confirm background"
        className="modal-link-bg"
      />
      <div className="modal-body">
        <div className="form">
          <h1 className="modal-confirm-heading">Selling Price</h1>
          {error && <span className="invalid-input-message">{error}</span>}
          <div className="input-group">
            <button
              className="btn btn-outline-secondary"
              type="button"
              id="button-addon1"
            >
              {type !== 'box' ? 'ETM' : 'BUSD'}
            </button>
            <input
              className="form-control"
              placeholder="0.0"
              value={displayValue}
              onChange={inputChanged}
            />
          </div>
          <div className="service-fee-wrapper">
            <span className="service-fee-txt">Service Fee (5%)</span>
            <span className="service-fee-number">
              <span>
                {valueFinal
                  ? (parseFloat(valueFinal) * 0.05).toLocaleString('en-US', {
                      maximumFractionDigits: 3,
                    })
                  : 0}
              </span>{' '}
              {type !== 'box' ? 'ETM' : 'BUSD'}
            </span>
          </div>
          {!needApproveAll && (
            <button
              disabled={loadingSubmit}
              onClick={onSubmit}
              className="modal-btn-action"
            >
              {loadingSubmit && <Spinner size="sm" className="me-1" />}
              {!loadingSubmit && 'Confirm'}
            </button>
          )}
          {needApproveAll && (
            <button
              disabled={loading}
              className="modal-btn-action"
              onClick={handApprove}
            >
              {loading && <Spinner size="sm" className="me-1" />}
              {!loading && 'Approve'}
            </button>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default SellingModal;
