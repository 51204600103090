/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import _get from 'lodash/get';

import ApiService from 'services/api';
import {
  API_MARKETPLACE_URL,
  ETERMON_ITEM_ADDRESS,
} from 'config/constants/envConst';
import { truncate } from 'utils';
import { useWeb3React } from '@web3-react/core';
import { toast } from 'react-toastify';
import { Spinner } from 'reactstrap';
import { useMarket } from 'state/market/hooks';
// import MonsterClaimConfirm from './MonsterClaimConfirm';
import dayjs from 'dayjs';
import SellingModal from './SellingModal';
import ItemDepositConfirm from './ItemDepositConfirm';
import { useMyNft } from 'state/myNft/hooks';
import Countdown from 'react-countdown';
// import ItemClaimConfirm from './ItemClaimConfirm';

interface Props {
  item: any;
  setSelected: (item: any) => void;
}

const ItemDetail: React.FC<Props> = ({ setSelected, item }) => {
  const { account } = useWeb3React();
  const { cancelListing, getTransactionRawData, loading } = useMarket();
  const { getItems } = useMyNft();

  const [isOpenModalConfirm, setOpenModalConfirm] = useState(false);
  const [itemDetail, setItem] = useState<any>(null);
  const [isOpenSelling, setOpenSelling] = useState(false);
  const [rawData, setRawData] = useState(null);

  const bscUrl = `${process.env.REACT_APP_BSC_SCAN}/address`;
  const isListing = _get(item, 'is_listing', false);

  useEffect(() => {
    async function getItem(id: string) {
      try {
        const { data } = await ApiService.get(
          `${API_MARKETPLACE_URL}/api/v1/nft/item/detail?id=${id}`
        );

        if (data.status === 'success') {
          setItem(data.data);
        }
      } catch (error) {
        console.log(error);
      }
    }

    if (item.id) {
      getItem(item.id);
    }
  }, []);

  useEffect(() => {
    const fetchRawTransaction = async () => {
      try {
        const { data } = await getTransactionRawData(
          item.tokenNFT,
          'ITEM',
          ETERMON_ITEM_ADDRESS
        );
        debugger;
        setRawData(data.data);
      } catch (error) {}
    };
    if (item.tokenNFT && item.is_listing && itemDetail) {
      fetchRawTransaction();
    }
  }, [item, itemDetail]);

  let isLock = false;

  if (itemDetail) {
    isLock = dayjs().isBefore(dayjs(itemDetail.lockTime));
  }

  const stopCelling = async () => {
    await cancelListing(rawData);
    setSelected(null);
    if (account) {
      getItems(account);
    }
  };
  return (
    <div className="in-nft-body box-detail">
      {itemDetail && (
        <div className="body-detail row">
          <div className="col-12 col-md-12 col-lg-5">
            <div className="d-lg-flex">
              <div className="monster-detail-back">
                <div
                  onClick={() => setSelected(null)}
                  className="back-wrapper cursor-pointer"
                >
                  <img src="images/back.png" className="back-img" alt="back" />
                </div>
              </div>
              <div className="m-auto">
                <div className="item-title-bg">
                  <span className="item-txt">{itemDetail.name}</span>
                </div>
                <div className="box-img-container">
                  <img
                    src={itemDetail.image}
                    className="detail-img item-detail-img"
                    alt="item"
                    style={{ top: 0 }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-12 col-lg-6">
            <div className="monster-right-container">
              <div className="detail-info detail-split">
                <div className="detail-owner">
                  Owner:{' '}
                  <a
                    href={`${bscUrl}/${account}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {truncate(account || '', 6, 6)}
                  </a>
                  <button
                    className="btn btn-copy"
                    id="jsCopyButton"
                    onClick={() => {
                      if (navigator.clipboard) {
                        navigator.clipboard.writeText(account || '');
                        toast.info('Copied');
                      }
                    }}
                  >
                    <i className="fas fa-copy"></i>
                  </button>
                </div>
              </div>
              <div className="detail-info detail-split detail-monster mb-3">
                <div className="detail-title">
                  <span>Ability</span>
                </div>

                <div className="m-ability-list monster-right-bg">
                  <div className="ability-box">
                    <div className="ability">
                      <img
                        className="ability-img"
                        src="images/detail/damage.png"
                        alt="ability 1"
                      />
                    </div>
                    <span className="ability-number">
                      {itemDetail.ability.atk}
                    </span>
                  </div>
                  <div className="ability-box">
                    <div className="ability">
                      <img
                        className="ability-img"
                        src="images/detail/luck.png"
                        alt="ability 2"
                      />
                    </div>
                    <span className="ability-number">
                      {itemDetail.ability.matk}
                    </span>
                  </div>

                  <div className="ability-box">
                    <div className="ability">
                      <img
                        className="ability-img"
                        src="images/detail/book.png"
                        alt="ability 4"
                      />
                    </div>
                    <span className="ability-number">
                      {itemDetail.ability.mdef}
                    </span>
                  </div>
                  <div className="ability-box">
                    <div className="ability">
                      <img
                        className="ability-img"
                        src="images/detail/armor.png"
                        alt="ability 5"
                      />
                    </div>
                    <span className="ability-number">
                      {itemDetail.ability.def}
                    </span>
                  </div>
                  <div className="ability-box">
                    <div className="ability">
                      <img
                        className="ability-img"
                        src="images/detail/speed.png"
                        alt="ability 6"
                      />
                    </div>
                    <span className="ability-number">
                      {itemDetail.ability.spd}
                    </span>
                  </div>

                  <div className="ability-box">
                    <div className="ability">
                      <img
                        className="ability-img"
                        src="images/detail/heart.png"
                        alt="ability 8"
                      />
                    </div>
                    <span className="ability-number">
                      {itemDetail.ability.hp}
                    </span>
                  </div>

                  <div className="ability-box">
                    <div className="ability">
                      <img
                        className="ability-img"
                        src="images/detail/def.png"
                        alt="ability 7"
                      />
                    </div>
                    <span className="ability-number">
                      {itemDetail.ability.dodge}
                    </span>
                  </div>

                  <div className="ability-box">
                    <div className="ability">
                      <img
                        className="ability-img"
                        src="images/detail/crit.png"
                        alt="ability 3"
                      />
                    </div>
                    <span className="ability-number">
                      {itemDetail.ability.crit}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="detail-info-footer wallet-action">
              <div className="d-flex justify-content-around">
                <div className="claim-btn-wrapper w-100">
                  {!isListing && (
                    <button
                      className="claim-btn"
                      onClick={() => setOpenSelling(true)}
                    >
                      Selling
                    </button>
                  )}
                  {isListing && (
                    <button
                      disabled={loading}
                      className="claim-btn"
                      onClick={stopCelling}
                    >
                      {loading && <Spinner size="sm" />}
                      {!loading && 'Stop Selling'}
                    </button>
                  )}
                </div>
                <div className="claim-btn-wrapper w-100">
                  <button
                    className="claim-btn"
                    disabled={isLock}
                    onClick={() => {
                      if (isListing) {
                        toast.error('Please stop selling first!');
                      } else {
                        setOpenModalConfirm(true);
                      }
                    }}
                  >
                    {isLock && (
                      <Countdown date={new Date(itemDetail.lockTime)} />
                    )}
                    {!isLock && 'Deposit'}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {isOpenSelling && (
        <SellingModal
          isOpen={isOpenSelling}
          item={itemDetail}
          closeModal={() => setOpenSelling(false)}
          type="item"
        />
      )}

      {isOpenModalConfirm && (
        <ItemDepositConfirm
          isOpen={isOpenModalConfirm}
          item={itemDetail}
          closeModal={() => setOpenModalConfirm(false)}
          setSelected={setSelected}
        />
      )}
    </div>
  );
};

export default ItemDetail;
