/* eslint-disable jsx-a11y/anchor-is-valid */
import { Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';

import { useWallet } from 'hooks/useWallet';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useWeb3React } from '@web3-react/core';
import useAuth from 'hooks/useAuth';
import ConnectModal from 'components/WalletModal/ConnectModal';
import { toast } from 'react-toastify';
import CommingSoonModal from 'components/CommingSoonModal';
import logoImg from 'assets/images/LOGO.png';

const Header = () => {
  const history = useHistory();
  const { pathname } = useLocation();
  const { addNetwork } = useWallet();
  const { account } = useWeb3React();
  const { logout } = useAuth();

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [isOpenModalConnect, setOpenModalConnect] = useState(false);
  const [isOpenModalComing, setOpenModalComing] = useState(false);
  const isMobileScreen = useMediaQuery({ maxWidth: 480 });
  const [openDrawer, setOpenDrawer] = useState(false);
  const [style, setStyle] = useState({});
  const bscUrl = `${process.env.REACT_APP_BSC_SCAN}/address`;
  const accountEllipsis = account
    ? `${account.substring(0, 2)}...${account.substring(account.length - 4)}`
    : null;

  useEffect(() => {
    if (!isMobileScreen) {
      setOpenDrawer(false);
      setStyle({});
    } else {
      if (openDrawer) {
        setStyle({ transform: 'none', visibility: 'visible' });
      } else {
        setStyle({});
      }
    }
  }, [openDrawer, isMobileScreen]);

  const changeAccount = () => {
    logout();
    setOpenModalConnect(true);
  };

  const handleLogout = () => {
    logout();
    history.push('/');
  };

  return (
    <>
      <header className="header">
        {isMobileScreen && (
          <div className="box-header-mobile">
            <a
              href="https://etermon.io"
              className="d-flex align-items-center header-logo"
            >
              <img
                src={logoImg}
                alt="logo"
                className="d-inline-block align-text-top"
              />
            </a>

            <div className="special-button">
              <button className="sp-button" onClick={() => setOpenDrawer(true)}>
                <i className="fas fa-bars"></i>
              </button>
            </div>
          </div>
        )}
        {!isMobileScreen && (
          <div className="box-header">
            <ul className="nav-left">
              <li className={['/box'].includes(pathname) ? 'active' : ''}>
                <a href="/" className="nav-link">
                  Marketplace
                </a>
              </li>
              <li>
                <a href="https://box.etermon.io/" className="nav-link">
                  Etermon box
                </a>
              </li>
              <li>
                <a
                  style={{ cursor: 'pointer' }}
                  href="https://vesting.etermon.io"
                  rel="noreferrer"
                  className="nav-link"
                  target="_blank"
                >
                  Vesting
                </a>
              </li>
            </ul>

            <a
              href="https://etermon.io"
              className="d-flex align-items-center header-logo"
            >
              <img
                src={logoImg}
                alt="logo"
                className="d-inline-block align-text-top"
              />
            </a>

            <div className="nav-right">
              <a
                style={{ cursor: 'pointer' }}
                href="https://staking.etermon.io"
                rel="noreferrer"
                target="_blank"
                className="nav-right-item"
              >
                Staking
              </a>

              <>
                {account && (
                  <Dropdown
                    isOpen={dropdownOpen}
                    toggle={() => setDropdownOpen(!dropdownOpen)}
                    id="1234"
                  >
                    <DropdownToggle
                      caret
                      className="btn btn-custom dropdown-toggle"
                    >
                      {accountEllipsis}
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu dropdown-account">
                      <li>
                        <Link to="/in-wallet" className="dropdown-item">
                          <i className="fas fa-box-open me-1"></i>My NFT
                        </Link>
                      </li>

                      <li>
                        <Link to="/wallet" className="dropdown-item">
                          <i className="fas fa-wallet me-1"></i>My Wallet
                        </Link>
                      </li>

                      <li>
                        <a
                          className="dropdown-item"
                          href={`${bscUrl}/${account}`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <i className="far fa-eye me-1"></i>View on BSC
                        </a>
                      </li>
                      <li>
                        <a
                          className="dropdown-item"
                          style={{ cursor: 'pointer' }}
                          onClick={() => {
                            if (navigator.clipboard) {
                              navigator.clipboard.writeText(account || '');
                              toast.info('Copied');
                            }
                          }}
                        >
                          <i className="far fa-copy me-1"></i>Copy
                        </a>
                      </li>
                      <li>
                        <a
                          className="dropdown-item"
                          style={{ cursor: 'pointer' }}
                          onClick={() => changeAccount()}
                        >
                          <i className="fas fa-exchange-alt me-1"></i>Change
                        </a>
                      </li>
                      <li>
                        <a
                          className="dropdown-item"
                          onClick={() => handleLogout()}
                          style={{ cursor: 'pointer' }}
                        >
                          <i className="fas fa-sign-out-alt me-1"></i>Logout
                        </a>
                      </li>
                    </DropdownMenu>
                  </Dropdown>
                )}
                {!account && (
                  <>
                    <div className="special-button">
                      <button
                        className="sp-button"
                        onClick={() => addNetwork()}
                      >
                        Add BSC
                      </button>
                    </div>

                    <div className="special-button">
                      <button
                        className="sp-button"
                        onClick={() => setOpenModalConnect(true)}
                      >
                        Connect Wallet
                      </button>
                    </div>
                  </>
                )}
              </>
            </div>
          </div>
        )}
      </header>

      {isMobileScreen && (
        <div
          className="offcanvas offcanvas-box offcanvas-start"
          id="offcanvasExample"
          aria-labelledby="offcanvasExampleLabel"
          style={style}
        >
          <div className="offcanvas-header">
            <a href="/" className="d-flex align-items-center header-logo">
              <img
                src="images/LOGO.png"
                alt="logo"
                className="d-inline-block align-text-top"
              />
            </a>

            <button
              type="button"
              className="btn-close text-reset"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
              onClick={() => setOpenDrawer(false)}
            ></button>
          </div>
          <div className="offcanvas-body">
            <ul className="nav-left">
              <li>
                <Link
                  to="/box"
                  className={`nav-link ${
                    ['/box'].includes(pathname) ? 'active' : ''
                  }`}
                >
                  Etermon box
                </Link>
              </li>
              <li>
                <Link
                  to="/"
                  className={`nav-link ${
                    ['/'].includes(pathname) ? 'active' : ''
                  }`}
                >
                  Marketplace
                </Link>
              </li>
              <li>
                <a
                  style={{ cursor: 'pointer' }}
                  href="https://vesting.etermon.io"
                  rel="noreferrer"
                  className="nav-link"
                >
                  Vesting
                </a>
              </li>
              <li>
                <a
                  style={{ cursor: 'pointer' }}
                  href="https://staking.etermon.io"
                  rel="noreferrer"
                  className="nav-link nav-right-item"
                >
                  Staking
                </a>
              </li>
            </ul>

            <div className="nav-right">
              {!account && (
                <>
                  <div className="special-button">
                    <button className="sp-button" onClick={() => addNetwork()}>
                      Add BSC
                    </button>
                  </div>
                  <div className="special-button">
                    <button
                      type="button"
                      className="sp-button"
                      onClick={() => setOpenModalConnect(true)}
                    >
                      Connect Wallet
                    </button>
                  </div>
                </>
              )}

              {account && (
                <Dropdown
                  isOpen={dropdownOpen}
                  toggle={() => setDropdownOpen(!dropdownOpen)}
                  id="1234"
                >
                  <DropdownToggle
                    caret
                    className="btn btn-custom dropdown-toggle"
                  >
                    {accountEllipsis}
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-menu dropdown-account">
                    <li>
                      <a className="dropdown-item" href="/in-wallet">
                        <i className="fas fa-box-open me-1"></i>My NFT
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="/wallet">
                        <i className="fas fa-wallet me-1"></i>My Wallet
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        href={`${bscUrl}/${account}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i className="far fa-eye me-1"></i>View on BSC
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          if (navigator.clipboard) {
                            navigator.clipboard.writeText(account || '');
                            toast.info('Copied');
                          }
                        }}
                      >
                        <i className="far fa-copy me-1"></i>Copy
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        style={{ cursor: 'pointer' }}
                        onClick={() => changeAccount()}
                      >
                        <i className="fas fa-exchange-alt me-1"></i>Change
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        onClick={() => handleLogout()}
                        style={{ cursor: 'pointer' }}
                      >
                        <i className="fas fa-sign-out-alt me-1"></i>Logout
                      </a>
                    </li>
                  </DropdownMenu>
                </Dropdown>
              )}
            </div>
          </div>
        </div>
      )}

      <ConnectModal
        isOpen={isOpenModalConnect}
        closeModal={() => setOpenModalConnect(false)}
      />
      <CommingSoonModal
        isOpen={isOpenModalComing}
        closeModal={() => setOpenModalComing(false)}
      />
    </>
  );
};

export default Header;
