import { createSlice } from '@reduxjs/toolkit';

export interface GlobalState {
  etmBalance: number;
  etcBalance: number;
  etmInGameBalance: number;
  etcInGameBalance: number;
  bnbBalance: number;
  busdBalance: number;
  countdown: any;
}

const initialState: GlobalState = {
  etmBalance: 0,
  etcBalance: 0,
  bnbBalance: 0,
  busdBalance: 0,
  etmInGameBalance: 0,
  etcInGameBalance: 0,
  countdown: null,
};

export const globalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    setEtmBalance: (state, action) => {
      state.etmBalance = action.payload;
    },
    setEtcBalance: (state, action) => {
      state.etcBalance = action.payload;
    },
    setBnbBalance: (state, action) => {
      state.bnbBalance = action.payload;
    },
    setBusdBalance: (state, action) => {
      state.busdBalance = action.payload;
    },
    setETMInGameBalance: (state, action) => {
      state.etmInGameBalance = action.payload;
    },
    setETCInGameBalance: (state, action) => {
      state.etcInGameBalance = action.payload;
    },
    setCountDown: (state, action) => {
      state.countdown = action.payload;
    },
  },
});

export const {
  setEtmBalance,
  setEtcBalance,
  setBnbBalance,
  setBusdBalance,
  setETMInGameBalance,
  setETCInGameBalance,
  setCountDown,
} = globalSlice.actions;

export default globalSlice.reducer;
