import { createSlice } from '@reduxjs/toolkit';

export interface ItemState {
  items: any;
  metaItem: any;
}

const initialState: ItemState = {
  items: [],
  metaItem: null,
};

export const itemSlice = createSlice({
  name: 'item',
  initialState,
  reducers: {
    setItems: (state, action) => {
      state.items = action.payload;
    },
    setMetaItem: (state, action) => {
      state.metaItem = action.payload;
    },
  },
});

export const { setItems, setMetaItem } = itemSlice.actions;

export default itemSlice.reducer;
