import { toast } from 'react-toastify';

import { paramsMainnet, paramsTetnet } from 'config';

export const useWallet = () => {
  const params =
    process.env.REACT_APP_CHAIN_ID === '97' ? paramsTetnet : paramsMainnet;

  const addNetwork = async () => {
    if (window.ethereum && window.ethereum.request) {
      try {
        await window.ethereum.request({
          method: 'wallet_addEthereumChain',
          params: params,
        });
        toast.success('Added BSC network to metamask');
        return true;
      } catch (error) {
        toast.error('Failed to add  BSC network to metamask');
        return false;
      }
    }
  };

  const registerToken = async () => {
    if (window.ethereum && window.ethereum.request) {
      const tokenAddress = process.env.REACT_APP_TOKEN_ADDRESS;
      const tokenSymbol = 'REAL';
      const tokenDecimals = 18;
      const tokenImage = 'https://resources.realrealm.net/common/real.svg';

      const tokenAdded = await window.ethereum.request({
        method: 'wallet_watchAsset',
        params: {
          type: 'ERC20',
          options: {
            address: tokenAddress,
            symbol: tokenSymbol,
            decimals: tokenDecimals,
            image: tokenImage,
          },
        },
      });

      return tokenAdded;
    }
  };

  return { addNetwork, registerToken };
};
