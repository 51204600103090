/* eslint-disable react-hooks/exhaustive-deps */
import Layout from 'components/Layout';
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import _get from 'lodash/get';

import boxDetailImg from '../../assets/images/detail/box-detail.png';
import nImg from '../../assets/images/detail/n.png';
import rImg from '../../assets/images/detail/r.png';
import srImg from '../../assets/images/detail/sr.png';
import ssrImg from '../../assets/images/detail/ssr.png';
import { useBoxes } from 'state/box/hooks';
import { truncate } from 'utils';
import { BOX_TYPE } from 'config';
import { useMarket, useMarketData } from 'state/market/hooks';
import { Spinner } from 'reactstrap';
import { BUSD_ADDRESS, ETERMON_BOX_ADDRESS } from 'config/constants/envConst';
import useActiveWeb3React from 'hooks/useActiveWeb3React';
import { toast } from 'react-toastify';
import ConnectModal from 'components/WalletModal/ConnectModal';
import { getMarketplaceAddress } from 'utils/addressHelpers';

interface Props {}

const DetailBoxPage: React.FC<Props> = () => {
  const params: any = useParams();
  const { account } = useActiveWeb3React();
  const { getBoxDetail } = useBoxes();
  const {
    matchOrder,
    loading,
    setApprovalBEP20ForAll,
    checkApprovedBEP20ForAll,
    cancelListing,
    getTransactionRawData,
  } = useMarket();
  const { isApproved } = useMarketData();

  const [box, setBox] = useState(null);
  const [rawBox, setRawBox] = useState(null);
  const [isOpenModalConnect, setOpenModalConnect] = useState(false);

  const id = _get(params, 'id', 0);
  const seller = _get(rawBox, 'seller', '');
  const isOwner = account ? seller === account?.toLowerCase() : false;
  const bscUrl = `${process.env.REACT_APP_BSC_SCAN}/address`;
  useEffect(() => {
    const fetchDetail = async () => {
      try {
        const { data } = await getBoxDetail(id);
        if (data.status) {
          setBox(data);
        }
      } catch (error) {}
    };

    const fetchRawTransaction = async () => {
      try {
        const { data } = await getTransactionRawData(
          id,
          'BOX',
          ETERMON_BOX_ADDRESS
        );

        setRawBox(data.data);
      } catch (error) {}
    };
    if (id) {
      fetchDetail();
      fetchRawTransaction();
    }
  }, [id]);

  const buy = () => {
    matchOrder(rawBox);
  };

  const handApprove = async () => {
    await setApprovalBEP20ForAll(BUSD_ADDRESS, getMarketplaceAddress());
    await checkApprovedBEP20ForAll(
      BUSD_ADDRESS,
      getMarketplaceAddress(),
      _get(box, 'price', 0)
    );
  };

  useEffect(() => {
    if (account && box) {
      checkApprovedBEP20ForAll(
        BUSD_ADDRESS,
        getMarketplaceAddress(),
        _get(box, 'price', 0)
      );
    }
  }, [account, box]);

  const stopCelling = () => {
    cancelListing(rawBox);
  };
  return (
    <Layout rootClass="main-item-detail">
      <div className="main-nav">
        <div className="white-space"></div>
        <div className="nav-custom nav-detail-custom">
          <div className="nav-item">
            <Link
              className="nav-link nav-back active"
              aria-current="page"
              to="/box"
            >
              Detail
            </Link>
          </div>
        </div>
      </div>
      <div className="box-detail container">
        <div className="box-bg">
          <div className="body-detail row">
            <div className="col-12 col-md-12 col-lg-5">
              <div className="box-img-container">
                <img
                  src={box ? BOX_TYPE[_get(box, 'type', '')].img : boxDetailImg}
                  className="detail-img"
                  alt="box"
                />
              </div>
              <div className="box-title-bg">
                <span className="box-txt">
                  {box ? BOX_TYPE[_get(box, 'type', '')].name : '---'}
                </span>
              </div>
            </div>
            <div className="col-12 col-md-12 col-lg-7">
              <div className="detail-info detail-split">
                <div className="detail-owner">
                  Owner:{' '}
                  <a
                    href={`${bscUrl}/${_get(box, 'seller', '')}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {truncate(_get(box, 'seller', '---'), 4, 8)}
                  </a>
                  <button
                    className="btn btn-copy"
                    id="jsCopyButton"
                    onClick={() => {
                      if (navigator.clipboard) {
                        navigator.clipboard.writeText(_get(box, 'seller', ''));
                        toast.info('Copied');
                      }
                    }}
                  >
                    <i className="fas fa-copy"></i>
                  </button>
                </div>
                <div className="detail-owner">
                  #{_get(box, 'token_id', '---')}
                </div>
              </div>
              <div className="detail-info">
                <div className="detail-title">
                  <span>Box description</span>
                </div>
                <span className="detail-txt">
                  {_get(box, 'box_decription', '---')}
                </span>
              </div>
              <div className="detail-info detail-split mb-3">
                <div className="detail-title">
                  <span>Drop Rate Detail</span>
                </div>
                <div className="detail-rate row">
                  <div className="rate col-12 col-md-6">
                    <div className="rate-img-container">
                      <img src={nImg} alt="normal" />
                    </div>
                    <div className="rate-percent">
                      {_get(box, 'box_droprate.N', '---')}
                    </div>
                  </div>
                  <div className="rate col-12 col-md-6">
                    <div className="rate-img-container">
                      <img src={rImg} alt="rare" />
                    </div>
                    <div className="rate-percent">
                      {_get(box, 'box_droprate.R', '---')}
                    </div>
                  </div>
                  <div className="rate col-12 col-md-6">
                    <div className="rate-img-container">
                      <img src={srImg} alt="super rare" />
                    </div>
                    <div className="rate-percent">
                      {_get(box, 'box_droprate.SR', '---')}
                    </div>
                  </div>
                  <div className="rate col-12 col-md-6">
                    <div className="rate-img-container">
                      <img src={ssrImg} alt="s super rare" />
                    </div>
                    <div className="rate-percent">
                      {_get(box, 'box_droprate.SSR', '---')}
                    </div>
                  </div>
                </div>
              </div>
              <div className="detail-price price-busd">
                <span className="ms-1">
                  {_get(box, 'price', 0).toLocaleString('en-US', {
                    maximumFractionDigits: 3,
                  })}{' '}
                  BUSD
                </span>
              </div>
              {isOwner && (
                <div className="action-btn-wrapper">
                  <button
                    disabled={loading}
                    className="action-btn"
                    onClick={stopCelling}
                  >
                    {loading && <Spinner size="sm" />}
                    {!loading && 'Stop Selling'}
                  </button>
                </div>
              )}

              {!isOwner && (
                <div className="action-btn-wrapper">
                  {!account && (
                    <button
                      onClick={() => setOpenModalConnect(true)}
                      className="action-btn"
                    >
                      Connect Wallet
                    </button>
                  )}
                  {account && (
                    <>
                      {isApproved && (
                        <button
                          disabled={loading}
                          className="action-btn"
                          onClick={buy}
                        >
                          {loading && <Spinner size="sm" />}
                          {!loading && 'Buy now'}
                        </button>
                      )}
                      {!isApproved && (
                        <button
                          disabled={loading}
                          className="action-btn"
                          onClick={handApprove}
                        >
                          {loading && <Spinner size="sm" />}
                          {!loading && 'Approve BUSD'}
                        </button>
                      )}
                    </>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <ConnectModal
        isOpen={isOpenModalConnect}
        closeModal={() => setOpenModalConnect(false)}
      />
    </Layout>
  );
};

export default DetailBoxPage;
