/* eslint-disable jsx-a11y/anchor-is-valid */
import { useWeb3React } from '@web3-react/core';
import cn from 'classnames';
import QRCodeModal from 'pages/InWallet/components/QRCodeModal';
import React, { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Link, useLocation } from 'react-router-dom';
import { useAuthenState } from 'state/authen/hooks';
import { truncate } from 'utils';
import Layout from '.';

interface Props {
  rootClass?: any;
}

const MyNftLayout: React.FC<Props> = ({ children, rootClass }) => {
  const { email } = useAuthenState();
  const { account } = useWeb3React();
  const [isOpen, setOpen] = useState(false);
  const { pathname } = useLocation();
  const isMobileScreen = useMediaQuery({ maxWidth: 1600 });

  const [style, setStyle] = useState({});
  const [styleOverlay, setStyleOverlay] = useState({});
  const [openDrawer, setOpenDrawer] = useState(false);

  const layoutClass = cn('user-wallet user-nft', rootClass);

  useEffect(() => {
    if (!isMobileScreen) {
      setOpenDrawer(false);
      setStyle({});
      setStyleOverlay({});
    } else {
      if (openDrawer) {
        setStyle({ left: 0 });
        setStyleOverlay({ display: 'block' });
      } else {
        setStyle({});
        setStyleOverlay({});
      }
    }
  }, [openDrawer, isMobileScreen]);

  return (
    <Layout rootClass={layoutClass}>
      <div className="main-split">
        <div className="overlay" id="overlay" style={styleOverlay}></div>
        <div id="jsSideMenu" className="main-menu-left" style={style}>
          <span
            className="close-btn"
            id="jsCloseSideMenu"
            onClick={() => setOpenDrawer(false)}
          >
            ×
          </span>
          <div className="user-menu-left">
            <div className="user-image-border">
              <img
                src="images/avatar/avatar.png"
                alt="avatar"
                className="avatar-img"
              />
            </div>
            <span className="user-name">{truncate(email, 4, 10)}</span>
            <span className="user-id-wallet">
              {truncate(account || '', 4, 4)}
            </span>
            <button
              type="button"
              className="btn-show-qr"
              onClick={() => setOpen(true)}
            >
              Show QR
            </button>
            <div className="user-link-section">
              <Link
                to="/in-wallet"
                className={['/in-wallet'].includes(pathname) ? 'active' : ''}
              >
                My NFT
              </Link>
              <Link
                to="wallet"
                className={['/wallet'].includes(pathname) ? 'active' : ''}
              >
                Wallet
              </Link>
              <Link
                to="/settings"
                className={['/settings'].includes(pathname) ? 'active' : ''}
              >
                Settings
              </Link>
              <Link
                to="/user-transactions"
                className={
                  ['/user-transactions'].includes(pathname) ? 'active' : ''
                }
              >
                Transactions
              </Link>
            </div>
          </div>
        </div>
        <div className="main-list-right">
          <span
            className="filter-toggle ps-3 pt-3"
            id="jsFilterToggle"
            onClick={() => setOpenDrawer(true)}
          >
            <i className="fas fa-bars"></i> Menu
          </span>

          {children}
        </div>
      </div>

      <QRCodeModal isOpen={isOpen} closeModal={() => setOpen(!isOpen)} />
    </Layout>
  );
};

export default MyNftLayout;
