/* eslint-disable react-hooks/exhaustive-deps */
import { useWeb3React } from '@web3-react/core';

import {
  connectorLocalStorageKey,
  ConnectorNames,
  walletLocalStorageKey,
} from 'config';
import useAuth from 'hooks/useAuth';
import React, { useEffect, useState } from 'react';
import { Modal } from 'reactstrap';
import { useAuthen } from 'state/authen/hooks';
import { ConfigWallet, connectors } from './walletConfigs';

interface ModalProps {
  isOpen: boolean;
  closeModal: () => void;
}

const ConnectModal: React.FC<ModalProps> = ({ isOpen, closeModal }) => {
  const { login } = useAuth();
  const { sign } = useAuthen();
  const { account } = useWeb3React();

  const [isSignMessage, setSignMessage] = useState(false);

  useEffect(() => {
    if (account && isSignMessage) {
      sign(account);
    }
  }, [account]);

  const handleLogin = (item: any) => {
    const isIOS =
      /iPad|iPhone|iPod/.test(navigator.userAgent) &&
      // @ts-ignore
      !window.MSStream;

    // Since iOS does not support Trust Wallet we fall back to WalletConnect
    if (item.title === 'Trust Wallet' && isIOS) {
      login(ConnectorNames.WalletConnect);
    } else {
      login(item.connectorId);
    }

    localStorage.setItem(walletLocalStorageKey, item.title);
    localStorage.setItem(connectorLocalStorageKey, item.connectorId);
    closeModal();
    setSignMessage(true);
  };

  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      modalClassName="modal-connect"
      className="modal-connect modal-wallet"
      toggle={closeModal}
      backdrop="static"
    >
      <div className="modal-content">
        <div className="modal-body">
          <button
            onClick={closeModal}
            type="button"
            className="btn-close close-button-modal"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
          <div>
            <h2 className="text-center title text-upper">
              Connect to a wallet
            </h2>
            <div className="mt-4 connect-wallet-wrap">
              {connectors.map((item: ConfigWallet) => (
                <div
                  className="d-flex flex-column justify-content-center align-items-center connect-wallet-item"
                  key={item.title}
                  onClick={() => handleLogin(item)}
                >
                  <img src={item.icon} alt={item.title} />
                  <div className="connect-txt">{item.title}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ConnectModal;
