/* eslint-disable react-hooks/exhaustive-deps */
import Layout from 'components/Layout';
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import _get from 'lodash/get';

import damageImg from '../../assets/images/detail/damage.png';
import luckImg from '../../assets/images/detail/luck.png';
import critImg from '../../assets/images/detail/crit.png';
import bookImg from '../../assets/images/detail/book.png';
import armorImg from '../../assets/images/detail/armor.png';
import speedImg from '../../assets/images/detail/speed.png';
import defImg from '../../assets/images/detail/def.png';
import heartImg from '../../assets/images/detail/heart.png';
import { useItems } from 'state/item/hooks';
import { useMarket, useMarketData } from 'state/market/hooks';
import { ETERMON_ITEM_ADDRESS, TOKEN_ADDRESS } from 'config/constants/envConst';
import { useWeb3React } from '@web3-react/core';
import { getMarketplaceAddress } from 'utils/addressHelpers';
import Items from 'pages/ItemPage/components/Items';
import { truncate } from 'utils';
import { Spinner } from 'reactstrap';
import { toast } from 'react-toastify';
import ConnectModal from 'components/WalletModal/ConnectModal';

interface Props {}

const DetailItemPage: React.FC<Props> = () => {
  const { getItemDetail } = useItems();
  const params: any = useParams();
  const {
    matchOrder,
    loading,
    setApprovalBEP20ForAll,
    checkApprovedBEP20ForAll,
    cancelListing,
    getTransactionRawData,
  } = useMarket();
  const { isApproved } = useMarketData();
  const { account } = useWeb3React();

  const [item, setItem] = useState<any>(null);
  const [rawItem, setRawItem] = useState(null);
  const [isOpenModalConnect, setOpenModalConnect] = useState(false);

  const id = _get(params, 'id', 0);
  const bscUrl = `${process.env.REACT_APP_BSC_SCAN}/address`;
  const seller = _get(item, 'seller', '');
  const isOwner = account ? seller === account?.toLowerCase() : false;

  useEffect(() => {
    const fetchDetail = async () => {
      try {
        const { data } = await getItemDetail(id);

        if (data.status && data.data) {
          setItem(data.data);
        }
      } catch (error) {}
    };

    const fetchRawTransaction = async () => {
      try {
        const { data } = await getTransactionRawData(
          id,
          'ITEM',
          ETERMON_ITEM_ADDRESS
        );

        setRawItem(data.data);
      } catch (error) {}
    };
    if (id) {
      fetchDetail();
      fetchRawTransaction();
    }
  }, [id]);

  const buy = () => {
    matchOrder(rawItem);
  };

  const handApprove = async () => {
    await setApprovalBEP20ForAll(TOKEN_ADDRESS, getMarketplaceAddress());
    await checkApprovedBEP20ForAll(
      TOKEN_ADDRESS,
      getMarketplaceAddress(),
      _get(Items, 'price', 0)
    );
  };

  const stopCelling = () => {
    cancelListing(rawItem);
  };

  useEffect(() => {
    if (account && item) {
      checkApprovedBEP20ForAll(
        TOKEN_ADDRESS,
        getMarketplaceAddress(),
        _get(item, 'price', 0)
      );
    }
  }, [account, item]);

  return (
    <Layout rootClass="main-item-detail">
      <div className="main-nav">
        <div className="white-space"></div>
        <div className="nav-custom nav-detail-custom">
          <div className="nav-item">
            <Link
              className="nav-link nav-back active"
              aria-current="page"
              to="/item"
            >
              Detail
            </Link>
          </div>
        </div>
      </div>

      {item && (
        <div className="box-detail container">
          <div className="box-bg">
            <div className="body-detail row">
              <div className="col-12 col-md-12 col-lg-5">
                <div className="item-title-bg">
                  <span className="item-txt">{item.name}</span>
                </div>
                <div className="box-img-container">
                  <img
                    src={item.image}
                    className="detail-img item-detail-img"
                    alt="item"
                  />
                </div>
              </div>
              <div className="col-12 col-md-12 col-lg-7">
                <div className="detail-info detail-split">
                  <div className="detail-owner">
                    Owner:{' '}
                    <a
                      href={`${bscUrl}/${item.seller}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {truncate(item.seller, 6, 6)}
                    </a>
                    <button
                      className="btn btn-copy"
                      id="jsCopyButton"
                      onClick={() => {
                        if (navigator.clipboard) {
                          navigator.clipboard.writeText(item.seller || '');
                          toast.info('Copied');
                        }
                      }}
                    >
                      <i className="fas fa-copy"></i>
                    </button>
                  </div>
                  <div className="detail-owner">#{item.tokenNFT}</div>
                </div>
                <div className="detail-info detail-split mb-3">
                  <div className="detail-title">
                    <span>Ability</span>
                  </div>
                  <div className="m-ability-list monster-right-bg">
                    <div className="ability-box">
                      <div className="ability">
                        <img
                          className="ability-img"
                          src={damageImg}
                          alt="ability 1"
                        />
                      </div>
                      <span className="ability-number">{item.ability.atk}</span>
                    </div>
                    <div className="ability-box">
                      <div className="ability">
                        <img
                          className="ability-img"
                          src={luckImg}
                          alt="ability 2"
                        />
                      </div>
                      <span className="ability-number">
                        {item.ability.matk}
                      </span>
                    </div>

                    <div className="ability-box">
                      <div className="ability">
                        <img
                          className="ability-img"
                          src={bookImg}
                          alt="ability 4"
                        />
                      </div>
                      <span className="ability-number">
                        {item.ability.mdef}
                      </span>
                    </div>
                    <div className="ability-box">
                      <div className="ability">
                        <img
                          className="ability-img"
                          src={armorImg}
                          alt="ability 5"
                        />
                      </div>
                      <span className="ability-number">{item.ability.def}</span>
                    </div>
                    <div className="ability-box">
                      <div className="ability">
                        <img
                          className="ability-img"
                          src={speedImg}
                          alt="ability 6"
                        />
                      </div>
                      <span className="ability-number">{item.ability.spd}</span>
                    </div>

                    <div className="ability-box">
                      <div className="ability">
                        <img
                          className="ability-img"
                          src={heartImg}
                          alt="ability 8"
                        />
                      </div>
                      <span className="ability-number">{item.ability.hp}</span>
                    </div>

                    <div className="ability-box">
                      <div className="ability">
                        <img
                          className="ability-img"
                          src={defImg}
                          alt="ability 7"
                        />
                      </div>
                      <span className="ability-number">
                        {item.ability.dodge}
                      </span>
                    </div>

                    <div className="ability-box">
                      <div className="ability">
                        <img
                          className="ability-img"
                          src={critImg}
                          alt="ability 3"
                        />
                      </div>
                      <span className="ability-number">
                        {item.ability.crit}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="detail-info-footer">
                  <div className="detail-price">
                    <span>
                      {_get(item, 'price', 0).toLocaleString('en-US', {
                        maximumFractionDigits: 3,
                      })}{' '}
                      ETM
                    </span>
                  </div>

                  {isOwner && (
                    <div className="action-btn-wrapper">
                      <button
                        disabled={loading}
                        className="action-btn"
                        onClick={stopCelling}
                      >
                        {loading && <Spinner size="sm" />}
                        {!loading && 'Stop Selling'}
                      </button>
                    </div>
                  )}

                  {!isOwner && (
                    <div className="action-btn-wrapper">
                      {!account && (
                        <button
                          onClick={() => setOpenModalConnect(true)}
                          className="action-btn"
                        >
                          Connect Wallet
                        </button>
                      )}
                      {account && (
                        <>
                          {isApproved && (
                            <button
                              disabled={loading}
                              className="action-btn"
                              onClick={buy}
                            >
                              {loading && <Spinner size="sm" />}
                              {!loading && 'Buy now'}
                            </button>
                          )}
                          {!isApproved && (
                            <button
                              disabled={loading}
                              className="action-btn"
                              onClick={handApprove}
                            >
                              {loading && <Spinner size="sm" />}
                              {!loading && 'Approve ETM'}
                            </button>
                          )}
                        </>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <ConnectModal
        isOpen={isOpenModalConnect}
        closeModal={() => setOpenModalConnect(false)}
      />
    </Layout>
  );
};

export default DetailItemPage;
