import { Contract } from '@ethersproject/contracts';
import { getAddress } from '@ethersproject/address';
import { AddressZero } from '@ethersproject/constants';
import { JsonRpcSigner, Web3Provider } from '@ethersproject/providers';

// returns the checksummed address if the address is valid, otherwise returns false
export function isAddress(value: any): string | false {
  try {
    return getAddress(value);
  } catch {
    return false;
  }
}

// account is not optional
export function getSigner(
  library: Web3Provider,
  account: string
): JsonRpcSigner {
  return library.getSigner(account).connectUnchecked();
}

// account is optional
export function getProviderOrSigner(
  library: Web3Provider,
  account?: string
): Web3Provider | JsonRpcSigner {
  return account ? getSigner(library, account) : library;
}

// account is optional
export function getContract(
  address: string,
  ABI: any,
  library: Web3Provider,
  account?: string
): Contract {
  if (!isAddress(address) || address === AddressZero) {
    throw Error(`Invalid 'address' parameter '${address}'.`);
  }

  return new Contract(
    address,
    ABI,
    getProviderOrSigner(library, account) as any
  );
}

export function getChainId(): any {
  return process.env.REACT_APP_CHAIN_ID;
}

export function getChainName(): string {
  return getChainId() === '97' ? 'bsc testnet' : 'bsc';
}

export function parseErrorString(error: any): string {
  if (typeof error === 'object') {
    if (error.data && error.data.message) {
      return error.data.message.toString();
    } else if (error.message) {
      return error.message.toString();
    } else {
      return error.constructor.name || '';
    }
  } else {
    return error.toString();
  }
}

export const truncate = (text: string, start = 4, end = 4) => {
  return text
    ? `${text.substring(0, start)}...${text.substring(text.length - end)}`
    : '';
};
