/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';

import { useMonsters, useMonsterState } from 'state/monster/hooks';

const bgMonster: any = {
  N: 'images/card/grey.png',
  R: 'images/card/blue.png',
  SR: 'images/card/pink.png',
  SSR: 'images/card/gold.png',
};

interface Props {
  filter: any;
}

const Monsters: React.FC<Props> = ({ filter }) => {
  const { monsters, metaMonster } = useMonsterState();
  const { getMonsters } = useMonsters();

  const hasPreviousPage = _get(metaMonster, 'hasPreviousPage', false);
  const hasNextPage = _get(metaMonster, 'hasNextPage', false);
  const pageCount = _get(metaMonster, 'pageCount', 0);
  const page = _get(metaMonster, 'page', 1);

  let pages = [];
  for (let i = 0; i < pageCount; i++) {
    pages.push(i + 1);
  }

  const handleChangePage = (p: number) => {
    const temp = { ...filter };

    temp.page = p;

    getMonsters(temp);
  };

  return (
    <>
      <div className="list-card custom-scrollbar">
        <div className="list-monster-row">
          {_isEmpty(monsters) && (
            <div className="empty-txt w-100">
              <span>Not monsters available</span>
            </div>
          )}

          {!_isEmpty(monsters) &&
            monsters.map((item: any) => (
              <Link
                to={`/monster/${item.tokenNFT}`}
                className="list-monster-col"
                key={item._id}
              >
                <div className="monster-id">
                  <span className="id-number">#{item.tokenNFT}</span>
                </div>
                <div className="monster-img-box">
                  <img
                    src={item.class_icon}
                    className="monster-class"
                    alt="class"
                  />
                  <img
                    src={bgMonster[item.rank]}
                    className="monster-bg"
                    alt="background"
                  />
                  <img src={item.image} className="monster-img" alt="monster" />
                  <img
                    src={item.rank_icon}
                    className="monster-rank"
                    alt="rank"
                  />
                  <img
                    className="icon-gender"
                    src={
                      item.gender === 'male'
                        ? 'images/gender/male.png'
                        : 'images/gender/female.png'
                    }
                    alt="gender male icon"
                  />
                  <div className="monster-info">
                    <img
                      className="monster-element"
                      src={item.element_icon}
                      alt="element"
                    />
                    <span className="monster-name">{item.name}</span>
                  </div>
                  <span className="monster-lv">Level {item.level}</span>
                  <div className="price-box">
                    <div className="price">
                      <img src="images/jew.png" alt="jew" />
                    </div>
                    <span className="price-number">{item.price}</span>
                  </div>
                </div>
              </Link>
            ))}
        </div>
      </div>
      <div className="list-footer">
        {pages.length > 0 && (
          <nav aria-label="Page navigation">
            <ul className="pagination">
              <li
                className={cn('page-item', { disabled: !hasPreviousPage })}
                onClick={() => {
                  if (hasPreviousPage) handleChangePage(page - 1);
                }}
              >
                <a className="page-link" aria-label="Previous">
                  <span aria-hidden="true">
                    <i className="fas fa-chevron-left"></i>
                  </span>
                </a>
              </li>
              {pages.map((item) => (
                <li
                  className={cn('page-item cursor-pointer', {
                    active: page === item,
                  })}
                  onClick={() => {
                    if (page !== item) handleChangePage(item);
                  }}
                  key={item}
                >
                  <a className="page-link">{item}</a>
                </li>
              ))}

              <li
                className={cn('page-item', { disabled: !hasNextPage })}
                onClick={() => {
                  if (hasNextPage) handleChangePage(page + 1);
                }}
              >
                <a className="page-link" aria-label="Next">
                  <span aria-hidden="true">
                    <i className="fas fa-chevron-right"></i>
                  </span>
                </a>
              </li>
            </ul>
          </nav>
        )}

        <div className="list-social">
          <a
            href="https://t.me/EtermonAnnouncement"
            className="social-item"
            target="_blank"
            rel="noreferrer"
          >
            <img src="images/marketplace-footer/tele.png" alt="telegram" />
          </a>
          <a
            href="https://discord.com/invite/m6QTpYK7pW"
            className="social-item"
            target="_blank"
            rel="noreferrer"
          >
            <img src="images/marketplace-footer/dc.png" alt="discord" />
          </a>
          <a
            href="https://twitter.com/EtermonOfficial"
            className="social-item"
            target="_blank"
            rel="noreferrer"
          >
            <img src="images/marketplace-footer/tw.png" alt="twitter" />
          </a>
          <a
            href="https://www.facebook.com/groups/EtermonCommunity"
            className="social-item"
            target="_blank"
            rel="noreferrer"
          >
            <img src="images/marketplace-footer/fb.png" alt="facebook" />
          </a>
          <a
            href="https://www.youtube.com/EtermonOfficial"
            className="social-item"
            target="_blank"
            rel="noreferrer"
          >
            <img src="images/marketplace-footer/ytb.png" alt="youtube" />
          </a>
        </div>
      </div>
    </>
  );
};

export default Monsters;
