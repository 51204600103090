import { ethers } from 'ethers';
import { simpleRpcProvider } from 'utils/providers';

import BoxStoreAbi from 'config/abi/BoxStore.json';
import MarketplaceAbi from 'config/abi/Marketplace.json';
import GameAbi from 'config/abi/Game.json';
import MysteryBoxAbi from 'config/abi/EtermonBoxAbi.json';
import bep20Abi from 'config/abi/erc20.json';
import erc721Abi from 'config/abi/erc721.json';
import {
  getBoxStoreAddress,
  getEtermonBoxAddress,
  getMarketplaceAddress,
  getGameAddress,
} from './addressHelpers';

const getContract = (
  abi: any,
  address: string,
  signer?: ethers.Signer | ethers.providers.Provider
) => {
  const signerOrProvider = signer ?? simpleRpcProvider;
  return new ethers.Contract(address, abi, signerOrProvider);
};

export const getBoxStoreContract = (
  signer?: ethers.Signer | ethers.providers.Provider
) => {
  return getContract(BoxStoreAbi, getBoxStoreAddress(), signer);
};

export const getEtermonBoxContract = (
  signer?: ethers.Signer | ethers.providers.Provider
) => {
  return getContract(MysteryBoxAbi, getEtermonBoxAddress(), signer);
};

export const getBep20Contract = (
  address: string,
  signer?: ethers.Signer | ethers.providers.Provider
) => {
  return getContract(bep20Abi, address, signer);
};

export const getMarketplaceContract = (
  signer?: ethers.Signer | ethers.providers.Provider
) => {
  return getContract(MarketplaceAbi, getMarketplaceAddress(), signer);
};

export const getGameContract = (
  signer?: ethers.Signer | ethers.providers.Provider
) => {
  return getContract(GameAbi, getGameAddress(), signer);
};

export const getErc721Contract = (
  address: string,
  signer?: ethers.Signer | ethers.providers.Provider
) => {
  return getContract(erc721Abi, address, signer);
};
