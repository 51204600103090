/* eslint-disable react-hooks/exhaustive-deps */
import { useWeb3React } from '@web3-react/core';
import { ETERMON_MONSTER_ADDRESS } from 'config/constants/envConst';
import React, { useEffect } from 'react';
import { Modal, Spinner } from 'reactstrap';
import { useMarket, useMarketData } from 'state/market/hooks';
import { useMyNft } from 'state/myNft/hooks';
import { getGameAddress } from 'utils/addressHelpers';

interface ModalProps {
  isOpen: boolean;
  item: any;
  closeModal: () => void;
  setSelected: (selected: any) => void;
}

const MonsterDepositConfirm: React.FC<ModalProps> = ({
  isOpen,
  item,
  closeModal,
  setSelected,
}) => {
  const { needApproveAll } = useMarketData();
  const { account } = useWeb3React();
  const {
    setApprovalForAll,
    checkApprovedForAll,
    depositERC721,
    loading,
    loadingDepositNft,
  } = useMarket();
  const { getMonsters } = useMyNft();

  const handleDeposit = async () => {
    await depositERC721(ETERMON_MONSTER_ADDRESS, item.tokenNFT, 'monster');
    closeModal();
    setSelected(null);
    if (account) {
      getMonsters(account);
    }
  };

  const handApprove = async () => {
    await setApprovalForAll(ETERMON_MONSTER_ADDRESS, getGameAddress());
    await checkApprovedForAll(ETERMON_MONSTER_ADDRESS, getGameAddress());
  };

  useEffect(() => {
    if (account) {
      checkApprovedForAll(ETERMON_MONSTER_ADDRESS, getGameAddress());
    }
  }, [account]);

  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      wrapClassName="modal-confirm"
      className="modal-xl"
      id="depositModal"
      backdrop="static"
    >
      <div className="modal-content">
        <button
          type="button"
          className="btn-close"
          onClick={() => closeModal()}
        >
          <i className="fas fa-times"></i>
        </button>
        <img
          src="images/wallet/confirm-bg.png"
          alt="confirm background"
          className="modal-link-bg"
        />
        <div className="modal-body">
          <div className="form">
            <h1 className="modal-confirm-heading">Deposit Confirmation</h1>
            <span className="modal-small-heading">
              Are you sure you want to deposit?
            </span>
            {!needApproveAll && (
              <button
                disabled={loadingDepositNft}
                onClick={handleDeposit}
                className="modal-btn-action"
              >
                {loadingDepositNft && <Spinner size="sm" className="me-1" />}
                {!loadingDepositNft && 'Deposit'}
              </button>
            )}
            {needApproveAll && (
              <button
                disabled={loading}
                className="modal-btn-action"
                onClick={handApprove}
              >
                {loading && <Spinner size="sm" className="me-1" />}
                {!loading && 'Approve'}
              </button>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default MonsterDepositConfirm;
