import React from 'react';
import { Provider } from 'react-redux';
import { Web3ReactProvider } from '@web3-react/core';

import { getLibrary } from 'utils/web3React';
import { ToastContainer } from 'react-toastify';

import store from './state';
import 'react-toastify/dist/ReactToastify.css';

const Providers: React.FC = ({ children }) => {
  return (
    <Web3ReactProvider getLibrary={getLibrary}>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Provider store={store}>{children}</Provider>
    </Web3ReactProvider>
  );
};

export default Providers;
