/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import cn from 'classnames';
import { Nav, NavItem } from 'reactstrap';
import { Link, useLocation } from 'react-router-dom';
import CommingSoonModal from 'components/CommingSoonModal';

const NavMarket = () => {
  const { pathname } = useLocation();

  const [isOpenModalComing, setOpenModalComing] = useState(false);
  return (
    <>
      <Nav className="nav-custom">
        <NavItem>
          <Link
            to={'/box'}
            className={cn('nav-link', {
              active: ['/box'].includes(pathname),
            })}
          >
            boxes
          </Link>
          {['/boxes'].includes(pathname) && (
            <div className="nav-link-bottom">&nbsp;</div>
          )}
        </NavItem>

        <NavItem>
          <Link
            to={'/monster'}
            className={cn('nav-link', {
              active: ['/monster'].includes(pathname),
            })}
          >
            Monsters
          </Link>
          {['/monster'].includes(pathname) && (
            <div className="nav-link-bottom">&nbsp;</div>
          )}
        </NavItem>
        <NavItem>
          <Link
            to={'/item'}
            className={cn('nav-link', {
              active: ['/item'].includes(pathname),
            })}
          >
            Items
          </Link>
          {['/item'].includes(pathname) && (
            <div className="nav-link-bottom">&nbsp;</div>
          )}
        </NavItem>
      </Nav>

      <CommingSoonModal
        isOpen={isOpenModalComing}
        closeModal={() => setOpenModalComing(false)}
      />
    </>
  );
};

export default NavMarket;
