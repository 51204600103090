import { getBep20Contract, getErc721Contract } from 'utils/contractHelpers';
import { State } from 'state/types';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import { toast } from 'react-toastify';
import Web3 from 'web3';
import { useHistory } from 'react-router-dom';
import _get from 'lodash/get';

import { useCallWithGasPrice } from 'hooks/useCallWithGasPrice';
import { useAppDispatch } from 'state';
import { setApproved, setNeedApproveAll } from '.';
import useActiveWeb3React from 'hooks/useActiveWeb3React';
import { useGameContract, useMarketplaceContract } from 'hooks/useContract';
import ApiService from 'services/api';
import {
  API_MARKETPLACE_URL,
  BUSD_ADDRESS,
  TOKEN_ADDRESS,
} from 'config/constants/envConst';
import { ethers } from 'ethers';
import { formatBigNumber } from 'utils/formatBalance';

async function delay(ms: number) {
  // return await for better async stack trace support in case of errors.
  return await new Promise((resolve) => setTimeout(resolve, ms));
}

export const useMarketState = () => {
  return useSelector((state: State) => state.market);
};

export const useMarket = () => {
  const dispatch = useAppDispatch();
  const { library, account } = useActiveWeb3React();
  const [loading, setLoading] = useState(false);
  const [loadingDepositNft, setLoadingDepositNft] = useState(false);
  const { callWithGasPrice } = useCallWithGasPrice();
  const contract = useMarketplaceContract();
  const gameContract = useGameContract();
  const history = useHistory();

  const web3 = new Web3(
    new Web3.providers.HttpProvider('https://bsc-dataseed.binance.org/')
  );

  const checkApprovedForAll = async (
    nftAddress: string,
    contractAddress: string
  ) => {
    try {
      const contract = getErc721Contract(nftAddress, library?.getSigner());

      const isApprovedForAll = await contract.isApprovedForAll(
        account,
        contractAddress
      );
      dispatch(setNeedApproveAll(!isApprovedForAll));
    } catch (e: any) {
      if (e.message) {
        toast.error(e.message);
      } else {
        toast.error('Error! Try again.');
      }
    }
  };

  const checkApprovedBEP20ForAll = async (
    nftAddress: string,
    addressNeedApprove: string,
    price: number
  ) => {
    try {
      const contract = getBep20Contract(nftAddress, library?.getSigner());

      const allowance = await contract.allowance(account, addressNeedApprove);
      const isApproved = Number(formatBigNumber(allowance)) > price;

      dispatch(setApproved(isApproved));
    } catch (e: any) {
      if (e.message) {
        toast.error(e.message);
      } else {
        toast.error('Error! Try again.');
      }
    }
  };

  const setApprovalForAll = async (
    nftAddress: string,
    contractAddress: string
  ) => {
    const contract = getErc721Contract(nftAddress, library?.getSigner());
    try {
      setLoading(true);
      const tx = await callWithGasPrice(contract, 'setApprovalForAll', [
        contractAddress,
        true,
      ]);

      await tx.wait();
      toast.success('Approve NFT success! Please confirm to proceed');
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      if (error.data) {
        toast.error(error.data.message);
      } else {
        if (typeof error.message === 'object') {
          toast.error('Approve error!');
          console.log(error.message);
        } else {
          toast.error(error.message);
        }
      }
    }
  };

  const setApprovalBEP20ForAll = async (
    tokenAddress: string,
    addressNeedApprove: string
  ) => {
    const contract = getBep20Contract(tokenAddress, library?.getSigner());
    try {
      setLoading(true);
      const tx = await callWithGasPrice(contract, 'approve', [
        addressNeedApprove,
        ethers.constants.MaxUint256,
      ]);
      await tx.wait();
      toast.success('Approve success!');
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      if (error.data) {
        toast.error(error.data.message);
      } else {
        if (typeof error.message === 'object') {
          toast.error('Approve error!');
          console.log(error.message);
        } else {
          toast.error(error.message);
        }
      }
    }
  };

  const verifyTransaction = (body: any) => {
    return ApiService.post(
      `${API_MARKETPLACE_URL}/api/v1/listing/verify-transaction`,
      body
    );
  };

  const listingNFT = async (body: any) => {
    return await ApiService.post(
      `${API_MARKETPLACE_URL}/api/v1/listing/nft`,
      body
    );
  };

  const getTransactionRawData = async (
    token_id: string,
    type: string,
    nftAddress: string
  ) => {
    return await ApiService.get(
      `${API_MARKETPLACE_URL}/api/v1/listing/transaction?token_id=${token_id}&nft_address=${nftAddress}&type=${type}`
    );
  };

  const getSignature = (body: any) => {
    return ApiService.post(
      `${API_MARKETPLACE_URL}/api/v1/nft/token/get-signature`,
      body
    );
  };

  const verifyOpenBox = (body: any) => {
    return ApiService.post(`${API_MARKETPLACE_URL}/api/v1/box/openbox`, body);
  };

  const verifyDepositMonster = (body: any) => {
    return ApiService.post(
      `${API_MARKETPLACE_URL}/api/v1/nft/monsters/deposit`,
      body
    );
  };

  const verifyDepositItem = (body: any) => {
    return ApiService.post(
      `${API_MARKETPLACE_URL}/api/v1/nft/item/deposit`,
      body
    );
  };

  const verifyClaimMonster = (body: any) => {
    return ApiService.post(
      `${API_MARKETPLACE_URL}/api/v1/nft/monsters/claim`,
      body
    );
  };

  const verifyClaimItem = (body: any) => {
    return ApiService.post(
      `${API_MARKETPLACE_URL}/api/v1/nft/item/claim`,
      body
    );
  };

  const verifyDepositToken = (body: any) => {
    return ApiService.post(
      `${API_MARKETPLACE_URL}/api/v1/nft/token/deposit`,
      body
    );
  };

  const verifyClaimToken = (body: any) => {
    return ApiService.post(
      `${API_MARKETPLACE_URL}/api/v1/nft/token/claim`,
      body
    );
  };

  const cancelListing = async (item: any) => {
    try {
      debugger;

      const params = {
        address: item.nft_address,
        paymentMethod: item.payment_method,
        randomNumber: item.timestamp,
        tokenId: item.token_id,
        price: web3.utils.toWei(item.price.toString(), 'ether'),
        signature: item.signature,
      };
      setLoading(true);
      const tx = await callWithGasPrice(contract, 'cancelSale', [
        params.address,
        params.paymentMethod,
        params.randomNumber,
        params.tokenId,
        params.price,
        params.signature,
      ]);

      const receipt = await tx.wait();

      const bodyVerify = {
        transaction_hash: receipt.transactionHash,
        token_id: item.token_id,
        nft_address: item.nft_address,
        type: item.type,
        event: 'CANCEL_SELL',
      };

      let result: any = await verifyTransaction(bodyVerify);

      if (result.data.status !== 'success') {
        await delay(3000);
        result = await verifyTransaction(bodyVerify);
        await delay(3000);
      }

      if (result.data.status !== 'success') {
        result = await verifyTransaction(bodyVerify);
        await delay(3000);
      }

      if (result.data.status !== 'success') {
        result = await verifyTransaction(bodyVerify);
        await delay(3000);
      }

      if (result.data.status !== 'success') {
        result = await verifyTransaction(bodyVerify);
        await delay(3000);
      }

      if (result.data.status !== 'success') {
        result = await verifyTransaction(bodyVerify);
        await delay(3000);
      }

      if (result.data.status !== 'success') {
        result = await verifyTransaction(bodyVerify);
        await delay(3000);
      }

      if (result.data.status !== 'success') {
        result = await verifyTransaction(bodyVerify);
        await delay(3000);
      }

      if (result.data.status !== 'success') {
        result = await verifyTransaction(bodyVerify);
        await delay(3000);
      }

      if (result.data.status !== 'success') {
        result = await verifyTransaction(bodyVerify);
        await delay(3000);
      }

      if (result.data.status !== 'success') {
        result = await verifyTransaction(bodyVerify);
      }
      if (result.data.status !== 'success') {
        setLoading(false);
        toast.error('Your transaction is being processed');
        return;
      }

      setLoading(false);
      toast.success('Cancel listing success!');
      history.push('/in-wallet');
      return result;
    } catch (err: any) {
      console.log(err);
      setLoading(false);
      const message = _get(err, 'data.message', '');
      if (message) {
        toast.error(message);
      } else {
        const message1 = _get(err, 'data.error', '');
        if (message1) {
          toast.error(message1);
        } else {
          toast.error('Error! Try again.');
        }
      }
      return null;
    }
  };

  const matchOrder = async (nftItem: any) => {
    try {
      const params = {
        nftAddress: nftItem.nft_address,
        paymentMethod: nftItem.type === 'BOX' ? BUSD_ADDRESS : TOKEN_ADDRESS,
        seller: nftItem.seller,
        randomNumber: nftItem.timestamp,
        tokenId: nftItem.token_id,
        price: web3.utils.toWei(nftItem.price.toString(), 'ether'),
        signature: nftItem.signature,
      };

      setLoading(true);
      const tx = await callWithGasPrice(contract, 'matchOrder', [
        params.nftAddress,
        params.paymentMethod,
        params.seller,
        params.randomNumber,
        params.tokenId,
        params.price,
        params.signature,
      ]);

      const receipt = await tx.wait();
      const bodyVerify = {
        transaction_hash: receipt.transactionHash,
        token_id: nftItem.token_id,
        nft_address: nftItem.nft_address,
        type: nftItem.type,
        event: 'MATCH_ORDER',
      };

      let result: any = await verifyTransaction(bodyVerify);

      if (result.data.status !== 'success') {
        await delay(3000);
        result = await verifyTransaction(bodyVerify);
        await delay(3000);
      }

      if (result.data.status !== 'success') {
        result = await verifyTransaction(bodyVerify);
        await delay(3000);
      }

      if (result.data.status !== 'success') {
        result = await verifyTransaction(bodyVerify);
        await delay(3000);
      }

      if (result.data.status !== 'success') {
        result = await verifyTransaction(bodyVerify);
        await delay(3000);
      }

      if (result.data.status !== 'success') {
        result = await verifyTransaction(bodyVerify);
        await delay(3000);
      }

      if (result.data.status !== 'success') {
        result = await verifyTransaction(bodyVerify);
        await delay(3000);
      }

      if (result.data.status !== 'success') {
        result = await verifyTransaction(bodyVerify);
        await delay(3000);
      }

      if (result.data.status !== 'success') {
        result = await verifyTransaction(bodyVerify);
        await delay(3000);
      }

      if (result.data.status !== 'success') {
        result = await verifyTransaction(bodyVerify);
        await delay(3000);
      }

      if (result.data.status !== 'success') {
        result = await verifyTransaction(bodyVerify);
      }

      if (result.data.status !== 'success') {
        setLoading(false);
        toast.error('Your transaction is being processed');
        return;
      }

      setLoading(false);
      toast.success('Buy success!');
      history.push('/in-wallet');
    } catch (err: any) {
      setLoading(false);
      const message = _get(err, 'data.message', '');
      if (message) {
        toast.error(message);
      } else {
        const message1 = _get(err, 'data.error', '');
        if (message1) {
          toast.error(message1);
        } else {
          toast.error('Error! Try again.');
        }
      }
    }
  };

  const openBox = async (box: any) => {
    if (account) {
      try {
        const params = {
          tokenId: box.token_id,
        };
        const boxId = parseInt(params.tokenId);

        setLoading(true);
        const tx = await callWithGasPrice(gameContract, 'openBox', [boxId]);

        const receipt = await tx.wait();
        const bodyVerify = {
          transaction_hash: receipt.transactionHash,
        };

        let result: any = await verifyOpenBox(bodyVerify);

        if (result.data.status !== 'success') {
          await delay(3000);
          result = await verifyOpenBox(bodyVerify);
          await delay(3000);
        }

        if (result.data.status !== 'success') {
          result = await verifyOpenBox(bodyVerify);
          await delay(3000);
        }

        if (result.data.status !== 'success') {
          result = await verifyOpenBox(bodyVerify);
          await delay(3000);
        }

        if (result.data.status !== 'success') {
          result = await verifyOpenBox(bodyVerify);
          await delay(3000);
        }

        if (result.data.status !== 'success') {
          result = await verifyOpenBox(bodyVerify);
          await delay(3000);
        }

        if (result.data.status !== 'success') {
          result = await verifyOpenBox(bodyVerify);
          await delay(3000);
        }

        if (result.data.status !== 'success') {
          result = await verifyOpenBox(bodyVerify);
          await delay(3000);
        }

        if (result.data.status !== 'success') {
          result = await verifyOpenBox(bodyVerify);
          await delay(3000);
        }

        if (result.data.status !== 'success') {
          result = await verifyOpenBox(bodyVerify);
          await delay(3000);
        }

        if (result.data.status !== 'success') {
          result = await verifyOpenBox(bodyVerify);
        }

        if (result.data.status !== 'success') {
          setLoading(false);
          toast.error('Your transaction is being processed');
          return;
        }

        setLoading(false);

        return result;
      } catch (err: any) {
        setLoading(false);
        const message = _get(err, 'data.message', '');
        if (message) {
          toast.error(message);
        } else {
          const message1 = _get(err, 'data.error', '');
          if (message1) {
            toast.error(message1);
          } else {
            toast.error('Error! Try again.');
          }
        }
        return null;
      }
    }
  };

  const depositERC721 = async (
    nftAddress: string,
    tokenId: string,
    type: string
  ) => {
    if (account) {
      try {
        setLoadingDepositNft(true);
        const tx = await callWithGasPrice(gameContract, 'depositERC721', [
          nftAddress,
          tokenId,
        ]);

        const receipt = await tx.wait();
        const bodyVerify = {
          tx_id: receipt.transactionHash,
        };

        let verifyDeposit =
          type === 'monster' ? verifyDepositMonster : verifyDepositItem;

        let result: any = await verifyDeposit(bodyVerify);

        if (result.data.status !== 'success') {
          await delay(3000);
          result = await verifyDeposit(bodyVerify);
          await delay(3000);
        }

        if (result.data.status !== 'success') {
          result = await verifyDeposit(bodyVerify);
          await delay(3000);
        }

        if (result.data.status !== 'success') {
          result = await verifyDeposit(bodyVerify);
          await delay(3000);
        }

        if (result.data.status !== 'success') {
          result = await verifyDeposit(bodyVerify);
          await delay(3000);
        }

        if (result.data.status !== 'success') {
          result = await verifyDeposit(bodyVerify);
          await delay(3000);
        }

        if (result.data.status !== 'success') {
          result = await verifyDeposit(bodyVerify);
          await delay(3000);
        }

        if (result.data.status !== 'success') {
          result = await verifyDeposit(bodyVerify);
          await delay(3000);
        }

        if (result.data.status !== 'success') {
          result = await verifyDeposit(bodyVerify);
          await delay(3000);
        }

        if (result.data.status !== 'success') {
          result = await verifyDeposit(bodyVerify);
          await delay(3000);
        }

        if (result.data.status !== 'success') {
          result = await verifyDeposit(bodyVerify);
        }

        if (result.data.status !== 'success') {
          setLoading(false);
          toast.error('Your transaction is being processed');
          return;
        }

        setLoadingDepositNft(false);

        return true;
      } catch (err: any) {
        setLoadingDepositNft(false);
        const message = _get(err, 'data.message', '');
        if (message) {
          toast.error(message);
        } else {
          const message1 = _get(err, 'data.error', '');
          if (message1) {
            toast.error(message1);
          } else {
            toast.error('Error! Try again.');
          }
        }
        return null;
      }
    }
  };

  const claimERC721 = async (params: any) => {
    if (account) {
      try {
        setLoading(true);
        let tx: any;

        if (params.tokenId) {
          tx = await callWithGasPrice(
            gameContract,
            'claimERC721(uint256[],bytes,address,uint256,uint256)',
            [
              params.burnIds,
              params.signature,
              params.etermonNft,
              params.tokenId,
              params.randomNumber,
            ]
          );
        } else {
          tx = await callWithGasPrice(
            gameContract,
            'claimERC721(uint256[],bytes,address,uint256)',
            [
              params.burnIds,
              params.signature,
              params.etermonNft,
              params.randomNumber,
            ]
          );
        }

        const receipt = await tx.wait();
        const bodyVerify = {
          tx_id: receipt.transactionHash,
          id: params.id,
        };
        let verifyClaim =
          params.type === 'monster' ? verifyClaimMonster : verifyClaimItem;

        let result: any = await verifyClaim(bodyVerify);

        if (result.data.status !== 'success') {
          await delay(3000);
          result = await verifyClaim(bodyVerify);
          await delay(3000);
        }

        if (result.data.status !== 'success') {
          result = await verifyClaim(bodyVerify);
          await delay(3000);
        }

        if (result.data.status !== 'success') {
          result = await verifyClaim(bodyVerify);
          await delay(3000);
        }

        if (result.data.status !== 'success') {
          result = await verifyClaim(bodyVerify);
          await delay(3000);
        }

        if (result.data.status !== 'success') {
          result = await verifyClaim(bodyVerify);
          await delay(3000);
        }

        if (result.data.status !== 'success') {
          result = await verifyClaim(bodyVerify);
          await delay(3000);
        }

        if (result.data.status !== 'success') {
          result = await verifyClaim(bodyVerify);
          await delay(3000);
        }

        if (result.data.status !== 'success') {
          result = await verifyClaim(bodyVerify);
          await delay(3000);
        }

        if (result.data.status !== 'success') {
          result = await verifyClaim(bodyVerify);
          await delay(3000);
        }

        if (result.data.status !== 'success') {
          result = await verifyClaim(bodyVerify);
        }

        if (result.data.status !== 'success') {
          setLoading(false);
          toast.error('Your transaction is being processed');
          return;
        }

        setLoading(false);

        return result;
      } catch (err: any) {
        console.log(err);
        setLoading(false);
        const message = _get(err, 'data.message', '');
        if (message) {
          toast.error(message);
        } else {
          const message1 = _get(err, 'data.error', '');
          if (message1) {
            toast.error(message1);
          } else {
            toast.error('Error! Try again.');
          }
        }
        return null;
      }
    }
  };

  const depositERC20 = async (params: any) => {
    if (account) {
      try {
        setLoading(true);
        const tx = await callWithGasPrice(gameContract, 'depositERC20', [
          params.amount,
          params.token,
        ]);

        const receipt = await tx.wait();
        const bodyVerify = {
          transaction_hash: receipt.transactionHash,
          type: params.type,
        };

        let result: any = await verifyDepositToken(bodyVerify);

        if (result.data.status !== 'success') {
          await delay(3000);
          result = await verifyDepositToken(bodyVerify);
          await delay(3000);
        }

        if (result.data.status !== 'success') {
          result = await verifyDepositToken(bodyVerify);
          await delay(3000);
        }

        if (result.data.status !== 'success') {
          result = await verifyDepositToken(bodyVerify);
          await delay(3000);
        }

        if (result.data.status !== 'success') {
          result = await verifyDepositToken(bodyVerify);
          await delay(3000);
        }

        if (result.data.status !== 'success') {
          result = await verifyDepositToken(bodyVerify);
          await delay(3000);
        }

        if (result.data.status !== 'success') {
          result = await verifyDepositToken(bodyVerify);
          await delay(3000);
        }

        if (result.data.status !== 'success') {
          result = await verifyDepositToken(bodyVerify);
          await delay(3000);
        }

        if (result.data.status !== 'success') {
          result = await verifyDepositToken(bodyVerify);
          await delay(3000);
        }

        if (result.data.status !== 'success') {
          result = await verifyDepositToken(bodyVerify);
          await delay(3000);
        }

        if (result.data.status !== 'success') {
          result = await verifyDepositToken(bodyVerify);
        }

        if (result.data.status !== 'success') {
          setLoading(false);
          toast.error('Your transaction is being processed');
          return;
        }

        setLoading(false);

        return true;
      } catch (err: any) {
        console.log(err);
        setLoading(false);
        const message = _get(err, 'data.message', '');
        if (message) {
          toast.error(message);
        } else {
          const message1 = _get(err, 'data.error', '');
          if (message1) {
            toast.error(message1);
          } else {
            toast.error('Error! Try again.');
          }
        }
        return null;
      }
    }
  };

  const claimERC20 = async (params: any) => {
    if (account) {
      try {
        setLoading(true);
        const tx = await callWithGasPrice(gameContract, 'claimERC20', [
          params.signature,
          params.token,
          params.amount,
          params.randomNumber,
        ]);

        const receipt = await tx.wait();
        const bodyVerify = {
          type: params.type,
          transaction_hash: receipt.transactionHash,
        };

        let result: any = await verifyClaimToken(bodyVerify);

        if (result.data.status !== 'success') {
          await delay(3000);
          result = await verifyClaimToken(bodyVerify);
          await delay(3000);
        }

        if (result.data.status !== 'success') {
          result = await verifyClaimToken(bodyVerify);
          await delay(3000);
        }

        if (result.data.status !== 'success') {
          result = await verifyClaimToken(bodyVerify);
          await delay(3000);
        }

        if (result.data.status !== 'success') {
          result = await verifyClaimToken(bodyVerify);
          await delay(3000);
        }

        if (result.data.status !== 'success') {
          result = await verifyClaimToken(bodyVerify);
          await delay(3000);
        }

        if (result.data.status !== 'success') {
          result = await verifyClaimToken(bodyVerify);
          await delay(3000);
        }

        if (result.data.status !== 'success') {
          result = await verifyClaimToken(bodyVerify);
          await delay(3000);
        }

        if (result.data.status !== 'success') {
          result = await verifyClaimToken(bodyVerify);
          await delay(3000);
        }

        if (result.data.status !== 'success') {
          result = await verifyClaimToken(bodyVerify);
          await delay(3000);
        }

        if (result.data.status !== 'success') {
          result = await verifyClaimToken(bodyVerify);
        }

        if (result.data.status !== 'success') {
          setLoading(false);
          toast.error('Your transaction is being processed');
          return;
        }

        setLoading(false);

        return true;
      } catch (err: any) {
        console.log(err);
        setLoading(false);
        const message = _get(err, 'data.message', '');
        if (message) {
          toast.error(message);
        } else {
          const message1 = _get(err, 'data.error', '');
          if (message1) {
            toast.error(message1);
          } else {
            toast.error('Error! Try again.');
          }
        }
        return null;
      }
    }
  };

  return {
    checkApprovedForAll,
    setApprovalForAll,
    checkApprovedBEP20ForAll,
    setApprovalBEP20ForAll,
    loading,
    loadingDepositNft,
    cancelListing,
    matchOrder,
    openBox,
    getTransactionRawData,
    listingNFT,
    depositERC721,
    claimERC721,
    depositERC20,
    claimERC20,
    getSignature,
  };
};

export const useMarketData = () => {
  const marketData = useSelector((state: State) => state.market);

  return marketData;
};
