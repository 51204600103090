/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import { useMediaQuery } from 'react-responsive';
import { Nav, NavItem, TabContent, TabPane } from 'reactstrap';
import 'nouislider/dist/nouislider.css';

import CheckboxGroup from 'components/CheckboxGroup';
import Nouislider from 'components/Nouislider';
import { useItems } from 'state/item/hooks';

interface Props {
  filter: any;
  openDrawer: boolean;
  setFilter: (filter: any) => void;
  setOpenDrawer: (openDrawer: boolean) => void;
}

const ItemsFilter: React.FC<Props> = ({
  filter,
  setFilter,
  openDrawer,
  setOpenDrawer,
}) => {
  const [activeTab, setActiveTab] = useState('color');
  const isMobileScreen = useMediaQuery({ maxWidth: 480 });
  const { getItems } = useItems();

  const [priceMin, setPriceMin] = useState(0);
  const [priceMax, setPriceMax] = useState(99999999);
  const [style, setStyle] = useState({});
  const [styleOverlay, setStyleOverlay] = useState({});
  const [color, setColor] = useState<string[]>([
    'green',
    'blue',
    'red',
    'violet',
    'gold',
  ]);

  const [star, setStar] = useState<number[]>([1, 2, 3]);
  const [type, setType] = useState<string[]>([
    'gem',
    'helmet',
    'armor',
    'weapon',
    'shoes',
  ]);

  useEffect(() => {
    if (!isMobileScreen) {
      setOpenDrawer(false);
      setStyle({});
      setStyleOverlay({});
    } else {
      if (openDrawer) {
        setStyle({ left: 0 });
        setStyleOverlay({ display: 'block' });
      } else {
        setStyle({});
        setStyleOverlay({});
      }
    }
  }, [openDrawer, isMobileScreen]);

  const onEnd = (value: any, type: string) => {
    if (type === 'price') {
      setPriceMin(parseInt(value[0]));
      setPriceMax(parseInt(value[1]));
    }
  };

  const handleClearAll = () => {
    window.location.reload();
  };

  const handleSubmit = () => {
    const temp = { ...filter };
    temp.price = {};
    temp.price.min = priceMin;
    temp.price.max = priceMax;

    temp.color = color;
    temp.star = star;
    temp.type = type;

    temp.page = 1;
    setFilter(temp);
    getItems(temp);
  };

  return (
    <>
      <div className="overlay" id="overlay" style={styleOverlay}></div>
      <div id="jsSideMenu" className="main-menu-left" style={style}>
        <span
          className="close-btn"
          id="jsCloseSideMenu"
          style={{ fontSize: 33 }}
          onClick={() => setOpenDrawer(false)}
        >
          &times;
        </span>
        <div className="menu-clear">
          <span>Filter</span>
          <button
            className="clear-filter"
            id="clearFilter"
            onClick={() => handleClearAll()}
          >
            Clear all
          </button>
        </div>
        <div className="menu-tabs">
          <Nav pills className="mb-4" id="pills-tab">
            <NavItem>
              <button
                className={cn('nav-link', {
                  active: activeTab === 'color',
                })}
                id="color-tab"
                onClick={() => setActiveTab('color')}
                role="tab"
              >
                Color
              </button>
            </NavItem>
            <NavItem>
              <button
                type="button"
                className={cn('nav-link', {
                  active: activeTab === 'star',
                })}
                id="star-tab"
                onClick={() => setActiveTab('star')}
                role="tab"
              >
                Star
              </button>
            </NavItem>

            <NavItem>
              <button
                type="button"
                className={cn('nav-link', {
                  active: activeTab === 'type',
                })}
                id="type-tab"
                onClick={() => setActiveTab('type')}
                role="tab"
              >
                Type
              </button>
            </NavItem>
          </Nav>

          <TabContent activeTab={activeTab} id="pills-tabContent">
            <TabPane tabId="color">
              <div className="general-body">
                <div className="general-item general-price">
                  <h5 className="menu-header">Price</h5>

                  <Nouislider
                    range={{ min: 0, max: 99999999 }}
                    start={[0, 99999999]}
                    connect
                    onEnd={(value: any) => onEnd(value, 'price')}
                    className="noUi-background"
                    id="m-price-range"
                  />
                  <div className="common-range-slider">
                    <div className="slider-info slider-labels">
                      <div className="caption">
                        <span id="price-range-value1">{priceMin}</span>
                      </div>
                      <div className="text-right caption">
                        <span id="price-range-value2">{priceMax}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <CheckboxGroup name="color" value={color} onChange={setColor}>
                  {(Checkbox) => (
                    <div className="d-flex flex-column color-body">
                      <div className="d-flex justify-content-between align-items-center color-item form-check ps-0">
                        <label htmlFor="color1">
                          <img alt="color 1" src="images/color/color1.png" />
                        </label>

                        <Checkbox value="green" className="form-check-input " />
                      </div>
                      <div className="d-flex justify-content-between align-items-center color-item form-check ps-0">
                        <label htmlFor="color2">
                          <img alt="color 2" src="images/color/color2.png" />
                        </label>

                        <Checkbox value="blue" className="form-check-input" />
                      </div>
                      <div className="d-flex justify-content-between align-items-center color-item form-check ps-0">
                        <label htmlFor="color3">
                          <img alt="color 3" src="images/color/color3.png" />
                        </label>

                        <Checkbox value="red" className="form-check-input" />
                      </div>
                      <div className="d-flex justify-content-between align-items-center color-item form-check ps-0">
                        <label htmlFor="color3">
                          <img alt="color43" src="images/color/color4.png" />
                        </label>

                        <Checkbox value="violet" className="form-check-input" />
                      </div>
                      <div className="d-flex justify-content-between align-items-center color-item form-check ps-0">
                        <label htmlFor="color3">
                          <img alt="color 5" src="images/color/color5.png" />
                        </label>

                        <Checkbox value="gold" className="form-check-input" />
                      </div>
                    </div>
                  )}
                </CheckboxGroup>
              </div>
            </TabPane>

            <TabPane tabId="star">
              <div className="star-body">
                <CheckboxGroup name="star" value={star} onChange={setStar}>
                  {(Checkbox) => (
                    <div className="common-form-check">
                      <div className="form-check">
                        <label
                          className="form-check-label"
                          htmlFor="ratingCheckbox1"
                        >
                          <img src="images/star/star1.png" alt="star 1" />
                        </label>
                        <Checkbox value={1} className="form-check-input" />
                      </div>
                      <div className="form-check">
                        <label
                          className="form-check-label"
                          htmlFor="ratingCheckbox2"
                        >
                          <img src="images/star/star2.png" alt="element 2" />
                        </label>
                        <Checkbox value={2} className="form-check-input" />
                      </div>
                      <div className="form-check">
                        <label
                          className="form-check-label"
                          htmlFor="ratingCheckbox3"
                        >
                          <img src="images/star/star3.png" alt="element 3" />
                        </label>
                        <Checkbox value={3} className="form-check-input" />
                      </div>
                    </div>
                  )}
                </CheckboxGroup>
              </div>
            </TabPane>

            <TabPane tabId="type">
              <div className="type-body">
                <CheckboxGroup name="type" value={type} onChange={setType}>
                  {(Checkbox) => (
                    <div className="common-form-check">
                      <div className="form-check">
                        <label
                          className="form-check-label pe-1"
                          htmlFor="typeCheckbox1"
                        >
                          <img src="images/type/type1.png" alt="type 1" />
                        </label>

                        <Checkbox value="gem" className="form-check-input" />
                      </div>
                      <div className="form-check">
                        <label
                          className="form-check-label pe-1"
                          htmlFor="typeCheckbox2"
                        >
                          <img src="images/type/type2.png" alt="type 2" />
                        </label>
                        <Checkbox value="helmet" className="form-check-input" />
                      </div>
                      <div className="form-check">
                        <label
                          className="form-check-label pe-1"
                          htmlFor="typeCheckbox3"
                        >
                          <img src="images/type/type3.png" alt="type 3" />
                        </label>
                        <Checkbox value="armor" className="form-check-input" />
                      </div>
                      <div className="form-check">
                        <label
                          className="form-check-label pe-1"
                          htmlFor="typeCheckbox4"
                        >
                          <img src="images/type/type4.png" alt="type 4" />
                        </label>
                        <Checkbox value="weapon" className="form-check-input" />
                      </div>
                      <div className="form-check">
                        <label
                          className="form-check-label pe-1"
                          htmlFor="typeCheckbox5"
                        >
                          <img src="images/type/type5.png" alt="type 5" />
                        </label>
                        <Checkbox value="shoes" className="form-check-input" />
                      </div>
                    </div>
                  )}
                </CheckboxGroup>
              </div>
            </TabPane>
            <div className="special-button m-auto mt-5">
              <button className="sp-button" onClick={handleSubmit}>
                Submit
              </button>
            </div>
          </TabContent>
        </div>
      </div>
    </>
  );
};

export default ItemsFilter;
