import React, { useState } from 'react';
import { Collapse } from 'reactstrap';

import CheckboxGroup from 'components/CheckboxGroup';

interface Props {
  type: string;
  status: any;
  setStatus: (status: any) => void;
  setType: (type: string) => void;
  setData: (data: any) => void;
  setSelected: (data: any) => void;
}

const Filter: React.FC<Props> = ({
  type,
  status,
  setStatus,
  setType,
  setData,
  setSelected,
}) => {
  const [collapse, setCollapse] = useState(true);

  const onChangeType = (e: any) => {
    setData([]);
    setType(e.target.id);
    setSelected(null);
  };

  return (
    <div className="in-nft-right">
      <div className="dropdown-accordion">
        <div className="accordion" id="accordionFilter">
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingOne">
              <button
                className="accordion-button"
                type="button"
                onClick={() => setCollapse(!collapse)}
              >
                Filter
              </button>
            </h2>
            <Collapse
              isOpen={collapse}
              id="collapseOne"
              className="accordion-collapse"
            >
              <div className="accordion-body">
                <div className="accordion-filter-header">Type</div>
                <div className="box-form-check" onChange={onChangeType}>
                  <div className="form-check">
                    <label className="form-check-label" htmlFor="monsters">
                      Monsters
                    </label>
                    <input
                      className="form-check-input"
                      type="radio"
                      name="type"
                      id="monsters"
                      checked={type === 'monsters'}
                      onChange={(e) => {}}
                    />
                  </div>
                  <div className="form-check">
                    <label className="form-check-label" htmlFor="items">
                      Items
                    </label>
                    <input
                      className="form-check-input"
                      type="radio"
                      name="type"
                      id="items"
                      checked={type === 'items'}
                      onChange={(e) => {}}
                    />
                  </div>
                </div>

                <div className="accordion-filter-header">Status</div>
                <CheckboxGroup
                  name="status"
                  value={status}
                  onChange={setStatus}
                >
                  {(Checkbox) => (
                    <div className="box-form-check">
                      <div className="form-check">
                        <label
                          className="form-check-label"
                          htmlFor="typeCheckbox4"
                        >
                          On the market
                        </label>
                        <Checkbox
                          className="form-check-input"
                          value="onMarket"
                        />
                      </div>
                      <div className="form-check">
                        <label
                          className="form-check-label"
                          htmlFor="typeCheckbox5"
                        >
                          Not on market
                        </label>
                        <Checkbox
                          className="form-check-input"
                          value="notOnMarket"
                        />
                      </div>
                    </div>
                  )}
                </CheckboxGroup>
              </div>
            </Collapse>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Filter;
