import { useSelector } from 'react-redux';
import _get from 'lodash/get';

import { State } from 'state/types';
import { getBep20Contract } from 'utils/contractHelpers';
import { useWeb3React } from '@web3-react/core';
import {
  API_MARKETPLACE_URL,
  BUSD_ADDRESS,
  ETC_ADDRESS,
  TOKEN_ADDRESS,
} from 'config/constants/envConst';
import { formatBigNumber } from 'utils/formatBalance';
import { simpleRpcProvider } from 'utils/providers';
import { useAppDispatch } from 'state';
import {
  setBnbBalance,
  setBusdBalance,
  setCountDown,
  setEtcBalance,
  setETCInGameBalance,
  setEtmBalance,
  setETMInGameBalance,
} from '.';
import ApiService from 'services/api';

export const useGlobal = () => {
  const { account } = useWeb3React();
  const dispatch = useAppDispatch();

  const fetchBalance = async () => {
    const contractETM = getBep20Contract(TOKEN_ADDRESS);
    const contractBUSD = getBep20Contract(BUSD_ADDRESS);
    const contractETC = getBep20Contract(ETC_ADDRESS);

    const [etmBalance, busdBalance, etcBalance, bnbBalance, inGameBalance] =
      await Promise.all([
        contractETM.balanceOf(account),
        contractBUSD.balanceOf(account),
        contractETC.balanceOf(account),
        simpleRpcProvider.getBalance(account || ''),
        ApiService.get(`${API_MARKETPLACE_URL}/api/v1/nft/token/get-balance`),
      ]);

    dispatch(setEtmBalance(formatBigNumber(etmBalance)));
    dispatch(setBusdBalance(formatBigNumber(busdBalance)));
    dispatch(setEtcBalance(formatBigNumber(etcBalance)));
    dispatch(setBnbBalance(formatBigNumber(bnbBalance)));
    dispatch(
      setETMInGameBalance(_get(inGameBalance, 'data.data.amount.etm', 0))
    );
    dispatch(
      setETCInGameBalance(_get(inGameBalance, 'data.data.amount.etc', 0))
    );
    dispatch(setCountDown(_get(inGameBalance, 'data.data.countdown', 0)));
  };

  return {
    fetchBalance,
  };
};

export const useGlobalState = () => {
  const global = useSelector((state: State) => state.global);

  return global;
};
