/* eslint-disable react-hooks/exhaustive-deps */
import { useWeb3React } from '@web3-react/core';
import _get from 'lodash/get';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import CommingSoonModal from 'components/CommingSoonModal';
import { BOX_TYPE } from 'config';
import { truncate } from 'utils';
import SellingModal from './SellingModal';
import { useMarket } from 'state/market/hooks';
import { Spinner } from 'reactstrap';
import BoxConfirmModal from './BoxConfirmModal';
import { useMyNft } from 'state/myNft/hooks';
import { ETERMON_BOX_ADDRESS } from 'config/constants/envConst';

interface Props {
  item: any;
  setSelected: (item: any) => void;
}

const BoxDetail: React.FC<Props> = ({ setSelected, item }) => {
  const { account } = useWeb3React();
  const { cancelListing, getTransactionRawData, loading } = useMarket();
  const { getBoxes } = useMyNft();

  const [isOpen, setOpen] = useState(false);
  const [isOpenModalComing, setOpenModalComing] = useState(false);
  const [isOpenBoxConfirm, setOpenBoxConfirm] = useState(false);
  const [rawBox, setRawBox] = useState(null);

  const isListing = _get(item, 'is_listing', false);
  const bscUrl = `${process.env.REACT_APP_BSC_SCAN}/address`;

  useEffect(() => {
    const fetchRawTransaction = async () => {
      try {
        const { data } = await getTransactionRawData(
          item.token_id,
          'BOX',
          ETERMON_BOX_ADDRESS
        );

        setRawBox(data.data);
      } catch (error) {}
    };
    if (item.token_id && item.is_listing) {
      fetchRawTransaction();
    }
  }, [item]);

  const stopCelling = async () => {
    await cancelListing(rawBox);
    setSelected(null);
    getBoxes(account || '');
  };

  return (
    <div className="in-nft-body box-detail">
      <div className="body-detail row">
        <div className="col-12 col-md-12 col-lg-5">
          <div className="d-lg-flex">
            <div className="monster-detail-back">
              <div
                onClick={() => setSelected(null)}
                className="back-wrapper cursor-pointer"
              >
                <img src="images/back.png" className="back-img" alt="back" />
              </div>
            </div>
            <div className="d-flex flex-column">
              <div className="box-img-container">
                <img
                  src={BOX_TYPE[item.type || ''].imgDetail}
                  className="detail-img box-detail-img"
                  alt="box"
                />
              </div>
              <div className="box-title-bg">
                <span className="box-txt">
                  {BOX_TYPE[item.type || ''].name}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-12 col-lg-7">
          <div className="detail-info detail-split">
            <div className="detail-owner">
              Owner:{' '}
              <a href={`${bscUrl}/${account}`} target="_blank" rel="noreferrer">
                {truncate(account || '', 6, 6)}
              </a>
              <button
                className="btn btn-copy"
                id="jsCopyButton"
                onClick={() => {
                  if (navigator.clipboard) {
                    navigator.clipboard.writeText(account || '');
                    toast.info('Copied');
                  }
                }}
              >
                <i className="fas fa-copy"></i>
              </button>
            </div>
            <div className="detail-owner">#{item.token_id}</div>
          </div>
          <div className="detail-info">
            <div className="detail-title">
              <span>Box description</span>
            </div>
            <span className="detail-txt">{item.box_decription}</span>
          </div>
          <div className="detail-info detail-split mb-3">
            <div className="detail-title">
              <span>Drop Rate Detail</span>
            </div>
            <div className="detail-rate row">
              <div className="rate col-12 col-md-6">
                <div className="rate-img-container">
                  <img src="images/detail/n.png" alt="normal" />
                </div>
                <div className="rate-percent">
                  {item.box_droprate.N || '---'}
                </div>
              </div>
              <div className="rate col-12 col-md-6">
                <div className="rate-img-container">
                  <img src="images/detail/r.png" alt="rare" />
                </div>
                <div className="rate-percent">
                  {item.box_droprate.R || '---'}
                </div>
              </div>
              <div className="rate col-12 col-md-6">
                <div className="rate-img-container">
                  <img src="images/detail/sr.png" alt="super rare" />
                </div>
                <div className="rate-percent">
                  {item.box_droprate.SR || '---'}
                </div>
              </div>
              <div className="rate col-12 col-md-6">
                <div className="rate-img-container">
                  <img src="images/detail/ssr.png" alt="s super rare" />
                </div>
                <div className="rate-percent">
                  {item.box_droprate.SSR || '---'}
                </div>
              </div>
            </div>
          </div>
          <div className="detail-info-footer wallet-action">
            <div className="d-flex justify-content-around">
              <div className="claim-btn-wrapper w-100">
                {!isListing && (
                  <button className="claim-btn" onClick={() => setOpen(true)}>
                    Selling
                  </button>
                )}
                {isListing && (
                  <button
                    disabled={loading}
                    className="claim-btn"
                    onClick={stopCelling}
                  >
                    {loading && <Spinner size="sm" />}
                    {!loading && 'Stop Selling'}
                  </button>
                )}
              </div>
              {!isListing && (
                <div className="claim-btn-wrapper w-100">
                  <button
                    className="claim-btn"
                    onClick={() => setOpenBoxConfirm(true)}
                  >
                    Open Box
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <SellingModal
        isOpen={isOpen}
        item={item}
        closeModal={() => setOpen(false)}
        type="box"
      />
      <CommingSoonModal
        isOpen={isOpenModalComing}
        closeModal={() => setOpenModalComing(false)}
      />
      <BoxConfirmModal
        isOpen={isOpenBoxConfirm}
        closeModal={() => setOpenBoxConfirm(false)}
        item={item}
        setSelected={setSelected}
      />
    </div>
  );
};

export default BoxDetail;
