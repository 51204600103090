/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import { useMediaQuery } from 'react-responsive';
import { Nav, NavItem, TabContent, TabPane } from 'reactstrap';

import 'nouislider/dist/nouislider.css';
import Nouislider from 'components/Nouislider';

interface Props {
  filter: any;
  openDrawer: boolean;
  setFilter: (filter: any) => void;
  setOpenDrawer: (openDrawer: boolean) => void;
}
const BoxesFilter: React.FC<Props> = ({
  filter,
  setFilter,
  openDrawer,
  setOpenDrawer,
}) => {
  const isMobileScreen = useMediaQuery({ maxWidth: 480 });

  const [activeTab, setActiveTab] = useState('price');
  const [common, setCommon] = useState(true);
  const [ultimate, setUltimate] = useState(true);
  const [priceMin, setPriceMin] = useState(0);
  const [priceMax, setPriceMax] = useState(99999999);
  const [style, setStyle] = useState({});
  const [styleOverlay, setStyleOverlay] = useState({});

  useEffect(() => {
    if (!isMobileScreen) {
      setOpenDrawer(false);
      setStyle({});
      setStyleOverlay({});
    } else {
      if (openDrawer) {
        setStyle({ left: 0 });
        setStyleOverlay({ display: 'block' });
      } else {
        setStyle({});
        setStyleOverlay({});
      }
    }
  }, [openDrawer, isMobileScreen]);

  const onEnd = (value: any) => {
    const temp = { ...filter };
    temp.price_min = parseInt(value[0]);
    temp.price_max = parseInt(value[1]);

    setPriceMin(temp.price_min);
    setPriceMax(temp.price_max);

    setFilter(temp);
  };

  const onChangeCommon = (e: any) => {
    const checked = e.target.checked;
    const temp = { ...filter };

    if (checked) {
      if (ultimate === true) {
        delete temp.type;
      } else {
        temp.type = 'common';
      }
    } else {
      if (ultimate === true) {
        temp.type = 'ultimate';
      } else {
        temp.type = '';
      }
    }

    setFilter(temp);
    setCommon(checked);
  };

  const onChangeUltimate = (e: any) => {
    const checked = e.target.checked;
    const temp = { ...filter };

    if (checked) {
      if (common === true) {
        delete temp.type;
      } else {
        temp.type = 'ultimate';
      }
    } else {
      if (common === true) {
        temp.type = 'common';
      } else {
        temp.type = '';
      }
    }

    setFilter(temp);
    setUltimate(checked);
  };

  const handleClearAll = () => {
    window.location.reload();
  };

  return (
    <>
      <div className="overlay" id="overlay" style={styleOverlay}></div>
      <div id="jsSideMenu" className="main-menu-left" style={style}>
        <span
          className="close-btn"
          id="jsCloseSideMenu"
          style={{ fontSize: 33 }}
          onClick={() => setOpenDrawer(false)}
        >
          &times;
        </span>
        <div className="menu-clear">
          <span>Filter</span>
          <button
            className="clear-filter"
            id="clearFilter"
            onClick={() => handleClearAll()}
          >
            Clear all
          </button>
        </div>
        <div className="menu-tabs">
          <Nav pills className="mb-4" id="pills-tab">
            <NavItem>
              <button
                className={cn('nav-link', {
                  active: activeTab === 'price',
                })}
                id="price-tab"
                onClick={() => setActiveTab('price')}
                role="tab"
              >
                price
              </button>
            </NavItem>
            <NavItem>
              <button
                type="button"
                className={cn('nav-link', {
                  active: activeTab === 'type',
                })}
                id="type-tab"
                onClick={() => setActiveTab('type')}
                role="tab"
              >
                Type
              </button>
            </NavItem>
          </Nav>

          <TabContent activeTab={activeTab} id="pills-tabContent">
            <TabPane tabId="price">
              <div className="general-body">
                <div className="general-item general-price">
                  <h5 className="menu-header">Price</h5>
                  <Nouislider
                    range={{ min: 0, max: 99999999 }}
                    start={[0, 99999999]}
                    connect
                    onEnd={onEnd}
                    className="noUi-background"
                    id="m-price-range"
                  />
                  <div className="common-range-slider">
                    <div className="slider-info slider-labels">
                      <div className="caption">
                        <span id="price-range-value1">{priceMin}</span>
                      </div>
                      <div className="text-right caption">
                        <span id="price-range-value2">{priceMax}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </TabPane>

            <TabPane tabId="type">
              <div className="type-body box-type">
                <div className="box-form-check">
                  <div className="form-check">
                    <label className="form-check-label" htmlFor="typeCheckbox1">
                      Common box
                    </label>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      checked={common}
                      onChange={onChangeCommon}
                    />
                  </div>
                  <div className="form-check">
                    <label className="form-check-label" htmlFor="typeCheckbox2">
                      Ultimate box
                    </label>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      checked={ultimate}
                      onChange={onChangeUltimate}
                    />
                  </div>
                </div>
              </div>
            </TabPane>
          </TabContent>
        </div>
      </div>
    </>
  );
};

export default BoxesFilter;
