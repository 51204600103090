import React from 'react';
import cn from 'classnames';
import Header from './Header';

interface Props {
  rootClass?: string;
}

const Layout: React.FC<Props> = ({ children, rootClass }) => {
  return (
    <div className="wrapper bg">
      <Header />

      <main className={cn('main main-box main-item', rootClass)} id="main">
        <div
          className="alert alert-warning d-flex align-items-center alert-dismissible fade show justify-content-center"
          role="alert"
        >
          <i className="fas fa-exclamation-triangle"></i>
          <div className="alert-text ms-3">
            Please do not leave or reload the page and wait for the notification
            while it loads from the system after receiving the notification from
            Metamask
          </div>
        </div>
        <div className="main-nav">
          <div className="white-space"></div>
        </div>
        {children}
      </main>
    </div>
  );
};

export default Layout;
