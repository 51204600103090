/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Modal, Spinner } from 'reactstrap';
import numeral from 'numeral';
import { useWeb3React } from '@web3-react/core';
import { toast } from 'react-toastify';
import Web3 from 'web3';

import { useMarket, useMarketData } from 'state/market/hooks';
import { ETC_ADDRESS, TOKEN_ADDRESS } from 'config/constants/envConst';
import { getGameAddress } from 'utils/addressHelpers';
import { useGlobal, useGlobalState } from 'state/global/hooks';

interface ModalProps {
  isOpen: boolean;
  type: string;
  closeModal: () => void;
}

export const displayNumeralNoDecimal = (amount: any) =>
  numeral(amount).format('0,0');

const DepositModal: React.FC<ModalProps> = ({ isOpen, type, closeModal }) => {
  const web3 = new Web3(
    new Web3.providers.HttpProvider('https://bsc-dataseed.binance.org/')
  );
  const { isApproved } = useMarketData();
  const {
    setApprovalBEP20ForAll,
    checkApprovedBEP20ForAll,
    depositERC20,
    loading,
  } = useMarket();
  const { account } = useWeb3React();
  const { fetchBalance } = useGlobal();
  const { etmBalance, etcBalance } = useGlobalState();

  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [error, setError] = useState('');
  const [displayValue, setDisplayValue] = useState('');
  const [valueFinal, setValueFinal] = useState('');

  const tokenAddress = type === 'ETM' ? TOKEN_ADDRESS : ETC_ADDRESS;
  const balance = type === 'ETM' ? etmBalance : etcBalance;

  useEffect(() => {
    if (account) {
      checkApprovedBEP20ForAll(tokenAddress, getGameAddress(), 0);
    }
  }, [account, type]);

  const inputChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.currentTarget.value;
    setError('');
    const regex = /^-?\d*[.,]?\d*$/;
    const value = val.split(',').join('');

    if (parseFloat(value) < 9999999999) {
      if (regex.test(value)) {
        // @ts-ignore
        setValueFinal(numeral(value).value());

        if (val.length >= 2 && val.charAt(0) === '0' && val.charAt(1) === '0') {
          return setDisplayValue('0');
        }
        if (value.indexOf('.') > -1) {
          const decimal = value.substring(
            value.indexOf('.') + 1,
            value.indexOf('.') + 19
          );
          const int = value.substring(0, value.indexOf('.'));
          const data = displayNumeralNoDecimal(int) + '.' + decimal;
          return setDisplayValue(data);
        }
        setDisplayValue(value ? numeral(value).format('0,0') : '');
      }
    }
  };

  useEffect(() => {
    if (Number(valueFinal) > balance) {
      setError('Over balance');
    }
  }, [valueFinal]);

  const handApprove = async () => {
    await setApprovalBEP20ForAll(tokenAddress, getGameAddress());
    await checkApprovedBEP20ForAll(tokenAddress, getGameAddress(), 0);
  };

  const onSubmit = async () => {
    if (valueFinal === '0' || valueFinal === '') {
      setError('Wrong price for sale. Please check again!');
    } else if (parseFloat(valueFinal) > 99999999) {
      setError('The number you typed is too large.');
    } else {
      if (account) {
        try {
          setLoadingSubmit(true);
          const params = {
            amount: web3.utils.toWei(valueFinal.toString(), 'ether'),
            token: tokenAddress,
            type,
          };

          const result = await depositERC20(params);
          if (result) {
            await fetchBalance();
            toast.success('Deposit success');
            closeModal();
            setValueFinal('');
            setDisplayValue('');
          }

          setLoadingSubmit(false);
        } catch (e: any) {
          setValueFinal('');
          setDisplayValue('');
          setLoadingSubmit(false);
          if (e.message) {
            toast.error(e.message);
          } else {
            toast.error('Error! Try again.');
          }
        }
      }
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      wrapClassName="modal-confirm"
      className="modal-xl"
      id="sellingModal"
      backdrop="static"
    >
      <button type="button" className="btn-close" onClick={() => closeModal()}>
        <i className="fas fa-times"></i>
      </button>
      <img
        src="images/wallet/confirm-bg.png"
        alt="confirm background"
        className="modal-link-bg"
      />
      <div className="modal-body">
        <div className="form">
          <h1 className="modal-confirm-heading">Deposit Amount</h1>
          {error && <span className="invalid-input-message">{error}</span>}
          <div className="input-group">
            <button
              className="btn btn-outline-secondary"
              type="button"
              id="button-addon1"
            >
              {type}
            </button>
            <input
              className="form-control"
              placeholder="0.0"
              value={displayValue}
              onChange={inputChanged}
            />
          </div>

          {isApproved && (
            <button
              disabled={loadingSubmit || error !== ''}
              onClick={onSubmit}
              className="modal-btn-action"
            >
              {loadingSubmit && <Spinner size="sm" className="me-1" />}
              {!loadingSubmit && 'Confirm'}
            </button>
          )}
          {!isApproved && (
            <button
              disabled={loading}
              className="modal-btn-action"
              onClick={handApprove}
            >
              {loading && <Spinner size="sm" className="me-1" />}
              {!loading && 'Approve'}
            </button>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default DepositModal;
