/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import cn from 'classnames';
import _get from 'lodash/get';

import _isEmpty from 'lodash/isEmpty';
import BoxDetail from './BoxDetail';
import { useBoxState } from 'state/box/hooks';

interface Props {
  filter: any;
  setFilter: (filter: any) => void;
}

const Boxes: React.FC<Props> = ({ filter, setFilter }) => {
  const { boxes, metaBox } = useBoxState();

  const hasPreviousPage = _get(metaBox, 'hasPreviousPage', false);
  const hasNextPage = _get(metaBox, 'hasNextPage', false);
  const pageCount = _get(metaBox, 'pageCount', 0);
  const page = _get(metaBox, 'page', 1);

  let pages = [];
  for (let i = 0; i < pageCount; i++) {
    pages.push(i + 1);
  }

  const handleChangePage = (p: number) => {
    const temp = { ...filter };

    temp.page = p;

    setFilter(temp);
  };

  return (
    <>
      <div className="list-card custom-scrollbar">
        {_isEmpty(boxes) && (
          <div className="empty-txt">
            <span>Not box available</span>
          </div>
        )}

        {!_isEmpty(boxes) && (
          <>
            <div className="list-item-row box-list">
              {boxes.map((item: any, idx: number) => (
                <BoxDetail item={item} key={idx} />
              ))}
            </div>
          </>
        )}
      </div>

      <div className="list-footer">
        {pages.length > 0 && (
          <nav aria-label="Page navigation">
            <ul className="pagination">
              <li
                className={cn('page-item', { disabled: !hasPreviousPage })}
                onClick={() => {
                  if (hasPreviousPage) handleChangePage(page - 1);
                }}
              >
                <a className="page-link" aria-label="Previous">
                  <span aria-hidden="true">
                    <i className="fas fa-chevron-left"></i>
                  </span>
                </a>
              </li>
              {pages.map((item) => (
                <li
                  className={cn('page-item cursor-pointer', {
                    active: page === item,
                  })}
                  onClick={() => {
                    if (page !== item) handleChangePage(item);
                  }}
                  key={item}
                >
                  <a className="page-link">{item}</a>
                </li>
              ))}

              <li
                className={cn('page-item', { disabled: !hasNextPage })}
                onClick={() => {
                  if (hasNextPage) handleChangePage(page + 1);
                }}
              >
                <a className="page-link" aria-label="Next">
                  <span aria-hidden="true">
                    <i className="fas fa-chevron-right"></i>
                  </span>
                </a>
              </li>
            </ul>
          </nav>
        )}

        <div className="list-social">
          <a
            href="https://t.me/EtermonAnnouncement"
            className="social-item"
            target="_blank"
            rel="noreferrer"
          >
            <img src="images/marketplace-footer/tele.png" alt="telegram" />
          </a>
          <a
            href="https://discord.com/invite/m6QTpYK7pW"
            className="social-item"
            target="_blank"
            rel="noreferrer"
          >
            <img src="images/marketplace-footer/dc.png" alt="discord" />
          </a>
          <a
            href="https://twitter.com/EtermonOfficial"
            className="social-item"
            target="_blank"
            rel="noreferrer"
          >
            <img src="images/marketplace-footer/tw.png" alt="twitter" />
          </a>
          <a
            href="https://www.facebook.com/groups/EtermonCommunity"
            className="social-item"
            target="_blank"
            rel="noreferrer"
          >
            <img src="images/marketplace-footer/fb.png" alt="facebook" />
          </a>
          <a
            href="https://www.youtube.com/EtermonOfficial"
            className="social-item"
            target="_blank"
            rel="noreferrer"
          >
            <img src="images/marketplace-footer/ytb.png" alt="youtube" />
          </a>
        </div>
      </div>
    </>
  );
};

export default Boxes;
