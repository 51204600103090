/* eslint-disable react-hooks/exhaustive-deps */
import { useWeb3React } from '@web3-react/core';
import { BOX_TYPE } from 'config';
import { ETERMON_BOX_ADDRESS } from 'config/constants/envConst';
import React, { useEffect, useState } from 'react';
import { Modal, Spinner } from 'reactstrap';
import { useMarket, useMarketData } from 'state/market/hooks';
import { getMarketplaceAddress } from 'utils/addressHelpers';
import OpenBoxModal from './OpenBoxModal';

interface ModalProps {
  isOpen: boolean;
  item: any;
  closeModal: () => void;
  setSelected: (selected: any) => void;
}

const BoxConfirmModal: React.FC<ModalProps> = ({
  isOpen,
  closeModal,
  item,
  setSelected,
}) => {
  const { account } = useWeb3React();
  const { openBox, checkApprovedForAll, loading, setApprovalForAll } =
    useMarket();
  const { needApproveAll } = useMarketData();

  const [isOpenBox, setOpenBox] = useState(true);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [nftsOpened, setNftsOpened] = useState(null);

  useEffect(() => {
    if (account) {
      checkApprovedForAll(ETERMON_BOX_ADDRESS, getMarketplaceAddress());
    }
  }, [account]);

  const handApprove = async () => {
    await setApprovalForAll(ETERMON_BOX_ADDRESS, getMarketplaceAddress());
    await checkApprovedForAll(ETERMON_BOX_ADDRESS, getMarketplaceAddress());
  };

  const handleOpenBox = async () => {
    if (account) {
      try {
        setLoadingSubmit(true);
        const result = await openBox(item);
        setLoadingSubmit(false);
        if (result) {
          setOpenBox(true);
          setNftsOpened(result.data.data);
          closeModal();
        } else {
          closeModal();
        }
      } catch (error) {}
    }
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        role="dialog"
        autoFocus={true}
        centered={true}
        wrapClassName="modal-open-box"
        className="modal-xxl"
        id="openBoxModalConfirm"
        backdrop="static"
      >
        <div className="modal-content">
          <button
            type="button"
            className="btn-close"
            onClick={() => closeModal()}
            style={{
              position: 'absolute',
              top: 6,
              right: 30,
              zIndex: 99999999,
            }}
          ></button>
          <img
            src="images/open-box/open-box-bg.png"
            alt="confirm background"
            className="modal-open-bg"
          />
          <div className="modal-body">
            <div className="box-information" id="boxConfirm">
              <div className="box-information-right information-confirm">
                <img
                  src={BOX_TYPE[item.type || ''].img}
                  className="box-confirm-img shake-chunk shake-constant"
                  alt="box img"
                />
                <div className="confirm-btn">
                  <img
                    src="images/open-box/btn-action-bg.png"
                    alt="confirm button bg"
                  />
                  {!needApproveAll && (
                    <button
                      disabled={loadingSubmit}
                      className="btn"
                      onClick={handleOpenBox}
                    >
                      {loadingSubmit && <Spinner size="sm" className="me-1" />}
                      {!loadingSubmit && 'Confirm'}
                    </button>
                  )}

                  {needApproveAll && (
                    <button
                      disabled={loading}
                      className="btn"
                      onClick={handApprove}
                    >
                      {loading && <Spinner size="sm" className="me-1" />}
                      {!loading && 'Approve'}
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      {nftsOpened && (
        <OpenBoxModal
          isOpen={isOpenBox}
          closeModal={() => setOpenBox(false)}
          nftsOpened={nftsOpened}
          setSelected={setSelected}
        />
      )}
    </>
  );
};

export default BoxConfirmModal;
