/* eslint-disable jsx-a11y/anchor-is-valid */
import { useWeb3React } from '@web3-react/core';
import _get from 'lodash/get';
import useAuth from 'hooks/useAuth';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Modal, Spinner } from 'reactstrap';
import { useAuthen } from 'state/authen/hooks';
import { useAppDispatch } from 'state';
import { setEmail } from 'state/authen';

interface ModalProps {
  isOpen: boolean;
  isLink: boolean;
  closeModal: () => void;
  setLink: (link: boolean) => void;
}

function validateEmail(input: string) {
  var validRegex =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

  if (input.match(validRegex)) {
    return true;
  } else {
    return false;
  }
}

const WalletCreateModal: React.FC<ModalProps> = ({
  isOpen,
  closeModal,
  isLink,
  setLink,
}) => {
  const { verifyEmail, linkWallet } = useAuthen();
  const { account } = useWeb3React();
  const { logout } = useAuth();
  const history = useHistory();
  const dispatch = useAppDispatch();

  const [email, setLocalEmail] = useState('');
  const [refCode, setRefCode] = useState('');
  const [errorEmail, setErrorEmail] = useState('');
  const [errorCode, setErrorCode] = useState('');
  const [isSent, setIsSent] = useState(false);
  const [code, setCode] = useState('');
  const [loadingSendCode, setLoadingSendCode] = useState(false);
  const [loadingLinkWallet, setLoadingLinkWallet] = useState(false);

  useEffect(() => {
    const utm_source = localStorage.getItem('utm_source');
    if (utm_source) {
      setRefCode(utm_source);
    }
  }, []);

  const sendCode = async () => {
    if (email && account) {
      if (!validateEmail(email)) {
        setErrorEmail('Wrong format email');
      } else {
        setErrorEmail('');
        setLoadingSendCode(true);
        const res = await verifyEmail(account, email);
        setLoadingSendCode(false);
        if (res.status === 200) {
          toast.success(res.data.data);
          setIsSent(true);
        }
      }
    } else {
      setErrorEmail('Please enter email');
    }
  };

  const submit = async () => {
    if (code && account) {
      try {
        setErrorCode('');
        setLoadingLinkWallet(true);
        const res = await linkWallet(account, email, code, refCode);
        setLoadingLinkWallet(false);
        if (res.status === 200) {
          dispatch(setEmail(email));
          if (res.data.re_login) {
            toast.success('Link account successful. Please login again!');
            logout();
            localStorage.removeItem('access_token');
            closeModal();
          } else {
            toast.success('Create account successful');
          }
          history.push('/');
          setIsSent(true);
        }
      } catch (error: any) {
        setLoadingLinkWallet(false);
        const message = _get(error, 'response.data.errors.msg', '');
        toast.error(message || 'Error!');
      }
    } else {
      setErrorCode('Please enter code');
    }
  };

  const handleChangeEmail = (e: any) => {
    if (e.target.value) {
      setErrorEmail('');
    } else {
      setErrorEmail('Please enter email');
    }
    setLocalEmail(e.target.value);
  };

  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      modalClassName="modal-user-wallet modal-wallet-link"
      className="modal-xl"
      toggle={closeModal}
      backdrop="static"
    >
      <div className="modal-content">
        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          onClick={() => closeModal()}
        >
          <i className="fas fa-times"></i>
        </button>
        <img
          src="images/wallet/link-bg.png"
          alt="link background"
          className="modal-link-bg"
        />
        <h1 className="modal-header-txt">
          {isLink ? 'Link Game Account' : 'Create Game Account'}
        </h1>
        <div className="modal-body">
          <div className="wallet-link-form">
            <div>
              <div className="mb-3">
                <label htmlFor="InputEmail" className="form-label email-label">
                  Email
                </label>
                <input
                  type="email"
                  className="form-control"
                  id="InputEmail"
                  aria-describedby="email"
                  onChange={handleChangeEmail}
                />
                {errorEmail && (
                  <small id="emailHelp" className="form-text  text-warning">
                    {errorEmail}
                  </small>
                )}
              </div>
              <div className="get-code">
                <div className="form-code">
                  <label htmlFor="inputCode" className="form-label email-label">
                    Enter code
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="inputCode"
                    placeholder=""
                    onChange={(e) => setCode(e.target.value)}
                  />
                  {errorCode && (
                    <small id="emailHelp" className="form-text text-warning">
                      {errorCode}
                    </small>
                  )}
                </div>
                <button
                  className="btn btn-primary"
                  onClick={sendCode}
                  disabled={loadingSendCode}
                >
                  {loadingSendCode && <Spinner size="sm" className="me-1" />}

                  {isSent ? 'Resend' : 'Send code via email'}
                </button>
              </div>

              <div className="mb-5">
                <label
                  htmlFor="referralCode"
                  className="form-label email-label"
                >
                  Referral Code
                </label>
                <input
                  value={refCode}
                  className="form-control"
                  id="referralCode"
                  onChange={(e) => setRefCode(e.target.value)}
                />
              </div>
              <div className="btn-account-action-wrapper">
                <button
                  className="btn-account-action"
                  onClick={submit}
                  disabled={loadingLinkWallet}
                >
                  {loadingLinkWallet && <Spinner size="sm" className="me-1" />}
                  {isLink ? 'Link Account' : 'Create Account'}
                </button>
              </div>
            </div>
          </div>
          <div className="link-create">
            {isLink ? "Don't have an account?" : 'You already have an account?'}

            <a
              className="link-relate ms-1 cursor-pointer"
              onClick={() => setLink(!isLink)}
            >
              {isLink ? 'Create Now' : 'Link Now'}
            </a>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default WalletCreateModal;
