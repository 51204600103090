/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import nouislider from 'nouislider';

import { isEqual } from './utils';

const areEqual = (prevProps: any, nextProps: any) => {
  const { start, step, disabled, range } = prevProps;
  return (
    nextProps.step === step &&
    isEqual(nextProps.start, start) &&
    nextProps.disabled === disabled &&
    isEqual(nextProps.range, range)
  );
};

const Nouislider = (props: any) => {
  const [slider, setSlider] = useState<any>(null);
  const sliderContainer: any = React.createRef();

  useEffect(() => {
    const { instanceRef } = props;
    const isCreatedRef =
      instanceRef &&
      Object.prototype.hasOwnProperty.call(instanceRef, 'current');
    if (instanceRef && instanceRef instanceof Function) {
      instanceRef(sliderContainer.current);
    }

    if (isCreatedRef) {
      // eslint-disable-next-line no-param-reassign
      instanceRef.current = sliderContainer.current;
    }

    return () => {
      if (isCreatedRef) {
        // eslint-disable-next-line no-param-reassign
        instanceRef.current = null;
      }
    };
  }, [sliderContainer]);

  const clickOnPip = (pip: any) => {
    const value = Number(pip.target.getAttribute('data-value'));
    if (slider) {
      slider.set(value);
    }
  };

  const toggleDisable = (disabled: any) => {
    const sliderHTML: any = sliderContainer.current;
    if (sliderHTML) {
      if (!disabled) {
        sliderHTML.removeAttribute('disabled');
      } else {
        sliderHTML.setAttribute('disabled', true);
      }
    }
  };

  const { onUpdate, onChange, onSlide, onStart, onEnd, onSet } = props;

  const updateEvents = (sliderComponent: any) => {
    if (onStart) {
      sliderComponent.off('start');
      sliderComponent.on('start', onStart);
    }

    if (onSlide) {
      sliderComponent.off('slide');
      sliderComponent.on('slide', onSlide);
    }

    if (onUpdate) {
      sliderComponent.off('update');
      sliderComponent.on('update', onUpdate);
    }

    if (onChange) {
      sliderComponent.off('change');
      sliderComponent.on('change', onChange);
    }

    if (onSet) {
      sliderComponent.off('set');
      sliderComponent.on('set', onSet);
    }

    if (onEnd) {
      sliderComponent.off('end');
      sliderComponent.on('end', onEnd);
    }
  };

  const updateOptions = (options: any) => {
    const sliderHTML: any = sliderContainer.current;
    sliderHTML.noUiSlider.updateOptions(options);
  };

  const setClickableListeners = () => {
    if (props.clickablePips) {
      const sliderHTML: any = sliderContainer.current;
      [...sliderHTML.querySelectorAll('.noUi-value')].forEach((pip) => {
        pip.style.cursor = 'pointer';
        pip.addEventListener('click', clickOnPip);
      });
    }
  };

  const createSlider = () => {
    const sliderComponent = nouislider.create(sliderContainer.current, {
      ...props,
    });

    updateEvents(sliderComponent);

    setSlider(sliderComponent);
  };

  useEffect(() => {
    const { disabled } = props;
    const sliderHTML = sliderContainer.current;
    if (sliderHTML) {
      toggleDisable(disabled);
      createSlider();
    }
    return () => {
      if (slider) slider.destroy();
      if (sliderHTML) {
        [...sliderHTML.querySelectorAll('.noUi-value')].forEach((pip) => {
          pip.removeEventListener('click', clickOnPip);
        });
      }
    };
  }, []);

  useEffect(() => {
    if (slider) {
      setClickableListeners();
    }
  }, [slider]);

  const {
    start,
    disabled,
    range,
    step,
    margin,
    padding,
    limit,
    pips,
    snap,
    animate,
  } = props;

  useEffect(() => {
    if (slider) {
      updateOptions({
        range,
        step,
        padding,
        margin,
        limit,
        pips,
        snap,
        animate,
      });
      slider.set(start);
      setClickableListeners();
    }
    toggleDisable(disabled);
  }, [
    start,
    disabled,
    range,
    step,
    margin,
    padding,
    limit,
    pips,
    snap,
    animate,
  ]);

  useEffect(() => {
    if (slider) {
      updateEvents(slider);
    }
  }, [onUpdate, onChange, onSlide, onStart, onEnd, onSet]);

  const { id, className, style } = props;
  const options: any = {};
  if (id) {
    options.id = id;
  }
  if (className) {
    options.className = className;
  }
  return <div {...options} ref={sliderContainer} style={style} />;
};

Nouislider.defaultProps = {
  animate: true,
  behaviour: 'tap',
  className: null,
  clickablePips: false,
  connect: false,
  direction: 'ltr',
  disabled: false,
  format: null,
  margin: null,
  limit: null,
  keyboardSupport: true,
  id: null,
  instanceRef: null,
  padding: 0,
  pips: null,
  snap: false,
  step: null,
  style: null,
  orientation: 'horizontal',
  tooltips: false,
  onChange: () => {},
  onEnd: () => {},
  onSet: () => {},
  onSlide: () => {},
  onStart: () => {},
  onUpdate: () => {},
};

export default React.memo(Nouislider, areEqual);
