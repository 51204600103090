import { useWeb3React } from '@web3-react/core';
import React, { useState } from 'react';
import { Modal, Spinner } from 'reactstrap';
import { useMyNft } from 'state/myNft/hooks';

interface ModalProps {
  isOpen: boolean;
  nftsOpened: any;
  closeModal: () => void;
  setSelected: (selected: any) => void;
}

async function delay(ms: number) {
  // return await for better async stack trace support in case of errors.
  return await new Promise((resolve) => setTimeout(resolve, ms));
}

const OpenBoxModal: React.FC<ModalProps> = ({
  isOpen,
  closeModal,
  nftsOpened,
  setSelected,
}) => {
  const { getBoxes, getMonsters } = useMyNft();
  const { account } = useWeb3React();

  const [index, setIndex] = useState(0);
  const [done, setDone] = useState(false);
  const [loading, setLoading] = useState(false);
  // const [styleOpening, setStyleOpening] = useState({ display: 'none' });
  const [currentNft, setCurrentNft] = useState(nftsOpened[index]);

  const handleNext = async () => {
    if (index + 1 < nftsOpened.length) {
      setLoading(true);
      await delay(3000);
      setIndex(index + 1);
      setCurrentNft(nftsOpened[index + 1]);
      setLoading(false);
    } else {
      setDone(true);
      closeModal();
      setSelected(null);
      if (account) {
        getBoxes(account);
        getMonsters(account || '');
      }
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      wrapClassName="modal-open-box"
      className="modal-xxl"
      id="openBoxModalConfirm"
      backdrop="static"
    >
      <div className="modal-content">
        <img
          src="images/open-box/open-box-bg.png"
          alt="confirm background"
          className="modal-open-bg"
        />
        <div className="modal-body">
          <div
            className="box-opening-gif"
            id="boxOpening"
            style={{ display: 'none' }}
          >
            <img src="images/Gif/Common_Open.gif" alt="gif opening box" />
          </div>
          <div
            className="box-information zoomInDown"
            id="boxReveal"
            style={{ display: 'flex' }}
          >
            <div className="box-information-left">
              <span className="unlocked-txt">You have unlocked</span>
              <span className="monster-name">Windora</span>
              <div id="monsterAnim" className="spin-box-animation">
                <img
                  className="monster-gif"
                  src={currentNft.image_open_box}
                  alt="monster animation gif"
                />
              </div>
            </div>
            <div className="box-information-right">
              <span className="unlocked-txt">You have unlocked</span>
              <span className="monster-name">{currentNft.name}</span>
              <div className="monster-info">
                <div className="info-wrapper">
                  <span className="info-txt">Rarity</span>
                  <div className="info-bg">
                    <div className="rarity">
                      <img
                        src={currentNft.rank_icon}
                        className="info-rarity"
                        alt="rarity"
                      />
                    </div>
                  </div>
                </div>
                <div className="info-wrapper">
                  <span className="info-txt">Element</span>
                  <div className="info-bg">
                    <div className="element">
                      <img
                        src={currentNft.element_icon}
                        className="info-element"
                        alt="element"
                      />
                    </div>
                  </div>
                </div>
                <div className="info-wrapper">
                  <span className="info-txt">Class</span>
                  <div className="info-bg">
                    <div className="class">
                      <img
                        src={currentNft.class_icon}
                        className="info-class"
                        alt="class"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="confirm-btn">
                <img
                  src="images/open-box/btn-action-bg.png"
                  alt="confirm button bg"
                />
                <button disabled={loading} className="btn" onClick={handleNext}>
                  {loading && <Spinner size="sm" />}
                  {!loading && <> {done ? 'Confirm' : 'Next'}</>}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default OpenBoxModal;
