/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import { useMediaQuery } from 'react-responsive';
import { Nav, NavItem, TabContent, TabPane } from 'reactstrap';
import 'nouislider/dist/nouislider.css';

import CheckboxGroup from 'components/CheckboxGroup';
import Nouislider from 'components/Nouislider';
import { useMonsters } from 'state/monster/hooks';

interface Props {
  filter: any;
  openDrawer: boolean;
  setFilter: (filter: any) => void;
  setOpenDrawer: (openDrawer: boolean) => void;
}

const MonstersFilter: React.FC<Props> = ({
  filter,
  setFilter,
  openDrawer,
  setOpenDrawer,
}) => {
  const [activeTab, setActiveTab] = useState('general');
  const isMobileScreen = useMediaQuery({ maxWidth: 480 });
  const { getMonsters } = useMonsters();

  const [priceMin, setPriceMin] = useState(0);
  const [priceMax, setPriceMax] = useState(99999999);
  const [breedCountMin, setBreedCountMin] = useState(0);
  const [breedCountMax, setBreedCountMax] = useState(5);
  const [atkMin, setAtkMin] = useState(0);
  const [atkMax, setAtkMax] = useState(99999999);
  const [defMin, setDefMin] = useState(0);
  const [defMax, setDefMax] = useState(99999999);
  const [critMin, setCritMin] = useState(0);
  const [critMax, setCritMax] = useState(99999999);
  const [dodgeMin, setDodgeMin] = useState(0);
  const [dodgeMax, setDodgeMax] = useState(99999999);
  const [hpMin, setHpMin] = useState(0);
  const [hpMax, setHpMax] = useState(99999999);
  const [spdMin, setSpdMin] = useState(0);
  const [spdMax, setSpdMax] = useState(99999999);
  const [matkMin, setMatkMin] = useState(0);
  const [matkMax, setMatkMax] = useState(99999999);
  const [mdefMin, setMdefMin] = useState(0);
  const [mdefMax, setMdefMax] = useState(99999999);
  const [style, setStyle] = useState({});
  const [styleOverlay, setStyleOverlay] = useState({});
  const [element, setElement] = useState<string[]>([
    'Fire',
    'Water',
    'Electric',
    'Plant',
    'Earth',
  ]);
  const [classes, setClasses] = useState<string[]>([
    'tank',
    'damage',
    'damage_mage',
    'affect',
  ]);
  const [rank, setRank] = useState<string[]>(['N', 'R', 'SR', 'SSR']);

  useEffect(() => {
    if (!isMobileScreen) {
      setOpenDrawer(false);
      setStyle({});
      setStyleOverlay({});
    } else {
      if (openDrawer) {
        setStyle({ left: 0 });
        setStyleOverlay({ display: 'block' });
      } else {
        setStyle({});
        setStyleOverlay({});
      }
    }
  }, [openDrawer, isMobileScreen]);

  const onEnd = (value: any, type: string) => {
    if (type === 'price') {
      setPriceMin(parseInt(value[0]));
      setPriceMax(parseInt(value[1]));
    }
    if (type === 'breedCount') {
      setBreedCountMin(parseInt(value[0]));
      setBreedCountMax(parseInt(value[1]));
    }
    if (type === 'atk') {
      setAtkMin(parseInt(value[0]));
      setAtkMax(parseInt(value[1]));
    }
    if (type === 'def') {
      setDefMin(parseInt(value[0]));
      setDefMax(parseInt(value[1]));
    }
    if (type === 'crit') {
      setCritMin(parseInt(value[0]));
      setCritMax(parseInt(value[1]));
    }
    if (type === 'dodge') {
      setDodgeMin(parseInt(value[0]));
      setDodgeMax(parseInt(value[1]));
    }
    if (type === 'hp') {
      setHpMin(parseInt(value[0]));
      setHpMax(parseInt(value[1]));
    }
    if (type === 'spd') {
      setSpdMin(parseInt(value[0]));
      setSpdMax(parseInt(value[1]));
    }
    if (type === 'matk') {
      setMatkMin(parseInt(value[0]));
      setMatkMax(parseInt(value[1]));
    }
    if (type === 'mdef') {
      setMdefMin(parseInt(value[0]));
      setMdefMax(parseInt(value[1]));
    }
  };

  const handleClearAll = () => {
    window.location.reload();
  };

  const handleSubmit = () => {
    const temp = { ...filter };
    temp.price = {};
    temp.breed = {};
    temp.hp = {};
    temp.atk = {};
    temp.def = {};
    temp.matk = {};
    temp.mdef = {};
    temp.spd = {};
    temp.dodge = {};
    temp.crit = {};
    temp.price.min = priceMin;
    temp.price.max = priceMax;
    temp.breed.min = breedCountMin;
    temp.breed.max = breedCountMax;
    temp.hp.min = hpMin;
    temp.hp.max = hpMax;
    temp.atk.min = atkMin;
    temp.atk.max = atkMax;
    temp.def.min = defMin;
    temp.def.max = defMax;
    temp.matk.min = matkMin;
    temp.matk.max = matkMax;
    temp.mdef.min = mdefMin;
    temp.mdef.max = mdefMax;
    temp.spd.min = spdMin;
    temp.spd.max = spdMax;
    temp.dodge.min = dodgeMin;
    temp.dodge.max = dodgeMin;
    temp.crit.min = critMin;
    temp.crit.max = critMax;

    temp.rank = rank;
    temp.class = classes;
    temp.element = element;

    temp.page = 1;
    setFilter(temp);
    getMonsters(temp);
  };

  return (
    <>
      <div className="overlay" id="overlay" style={styleOverlay}></div>
      <div id="jsSideMenu" className="main-menu-left" style={style}>
        <span
          className="close-btn"
          id="jsCloseSideMenu"
          style={{ fontSize: 33 }}
          onClick={() => setOpenDrawer(false)}
        >
          &times;
        </span>
        <div className="menu-clear">
          <span>Filter</span>
          <button
            className="clear-filter"
            id="clearFilter"
            onClick={() => handleClearAll()}
          >
            Clear all
          </button>
        </div>
        <div className="menu-tabs">
          <Nav pills className="mb-4" id="pills-tab">
            <NavItem>
              <button
                className={cn('nav-link', {
                  active: activeTab === 'general',
                })}
                id="general-tab"
                onClick={() => setActiveTab('general')}
                role="tab"
              >
                General
              </button>
            </NavItem>
            <NavItem>
              <button
                type="button"
                className={cn('nav-link', {
                  active: activeTab === 'ability',
                })}
                id="ability-tab"
                onClick={() => setActiveTab('ability')}
                role="tab"
              >
                Ability
              </button>
            </NavItem>
          </Nav>

          <TabContent activeTab={activeTab} id="pills-tabContent">
            <TabPane tabId="general">
              <div className="general-body">
                <div className="general-item general-price">
                  <h5 className="menu-header">Price</h5>

                  <Nouislider
                    range={{ min: 0, max: 99999999 }}
                    start={[0, 99999999]}
                    connect
                    onEnd={(value: any) => onEnd(value, 'price')}
                    className="noUi-background"
                    id="m-price-range"
                  />
                  <div className="common-range-slider">
                    <div className="slider-info slider-labels">
                      <div className="caption">
                        <span id="price-range-value1">{priceMin}</span>
                      </div>
                      <div className="text-right caption">
                        <span id="price-range-value2">{priceMax}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="general-item general-element">
                  <h5 className="menu-header">Element</h5>

                  <CheckboxGroup
                    name="element"
                    value={element}
                    onChange={setElement}
                  >
                    {(Checkbox) => (
                      <div className="common-form-check">
                        <div className="form-check mx-1">
                          <label
                            className="form-check-label"
                            htmlFor="elementCheckbox1"
                          >
                            <img
                              src="images/element/elect.png"
                              alt="element 1"
                            />
                          </label>
                          <Checkbox
                            className="form-check-input ms-2"
                            value="Electric"
                          />
                        </div>
                        <div className="form-check mx-1">
                          <label
                            className="form-check-label"
                            htmlFor="elementCheckbox2"
                          >
                            <img
                              src="images/element/fire.png"
                              alt="element 2"
                            />
                          </label>
                          <Checkbox
                            className="form-check-input ms-2"
                            value="Fire"
                          />
                        </div>
                        <div className="form-check mx-1">
                          <label
                            className="form-check-label"
                            htmlFor="elementCheckbox3"
                          >
                            <img
                              src="images/element/water.png"
                              alt="element 3"
                            />
                          </label>
                          <Checkbox
                            className="form-check-input ms-2"
                            value="Water"
                          />
                        </div>
                        <div className="form-check mx-1">
                          <label
                            className="form-check-label"
                            htmlFor="elementCheckbox4"
                          >
                            <img
                              src="images/element/plant.png"
                              alt="element 4"
                            />
                          </label>
                          <Checkbox
                            className="form-check-input ms-2"
                            value="Plant"
                          />
                        </div>
                        <div className="form-check">
                          <label
                            className="form-check-label"
                            htmlFor="elementCheckbox5"
                          >
                            <img
                              src="images/element/earth.png"
                              alt="element 5"
                            />
                          </label>
                          <Checkbox
                            className="form-check-input ms-2"
                            value="Earth"
                          />
                        </div>
                      </div>
                    )}
                  </CheckboxGroup>
                </div>
                <div className="general-item general-class">
                  <h5 className="menu-header">Class</h5>
                  <CheckboxGroup
                    name="classes"
                    value={classes}
                    onChange={setClasses}
                  >
                    {(Checkbox) => (
                      <div className="common-form-check">
                        <div className="form-check">
                          <label
                            className="form-check-label"
                            htmlFor="classCheckbox1"
                          >
                            <img src="images/class/class1.png" alt="class 1" />
                          </label>
                          <Checkbox
                            className="form-check-input ms-2"
                            value="damage"
                          />
                        </div>
                        <div className="form-check">
                          <label
                            className="form-check-label"
                            htmlFor="classCheckbox2"
                          >
                            <img src="images/class/class2.png" alt="class 2" />
                          </label>
                          <Checkbox
                            className="form-check-input ms-2"
                            value="tank"
                          />
                        </div>
                        <div className="form-check">
                          <label
                            className="form-check-label"
                            htmlFor="classCheckbox3"
                          >
                            <img src="images/class/class3.png" alt="class 3" />
                          </label>
                          <Checkbox
                            className="form-check-input ms-2"
                            value="affect"
                          />
                        </div>
                        <div className="form-check">
                          <label
                            className="form-check-label"
                            htmlFor="classCheckbox4"
                          >
                            <img src="images/class/class4.png" alt="class 4" />
                          </label>
                          <Checkbox
                            className="form-check-input ms-2"
                            value="damage_mage"
                          />
                        </div>
                      </div>
                    )}
                  </CheckboxGroup>
                </div>
                <div className="general-item general-rarity">
                  <h5 className="menu-header">Rarity</h5>
                  <CheckboxGroup name="rank" value={rank} onChange={setRank}>
                    {(Checkbox) => (
                      <div className="common-form-check">
                        <div className="form-check">
                          <label
                            className="form-check-label"
                            htmlFor="rarityCheckbox1"
                          >
                            <img
                              src="images/rarity/rarity1.png"
                              alt="rarity 1"
                            />
                          </label>
                          <Checkbox className="form-check-input" value="N" />
                        </div>
                        <div className="form-check">
                          <label
                            className="form-check-label"
                            htmlFor="rarityCheckbox2"
                          >
                            <img
                              src="images/rarity/rarity2.png"
                              alt="rarity 2"
                            />
                          </label>
                          <Checkbox className="form-check-input" value="R" />
                        </div>
                        <div className="form-check">
                          <label
                            className="form-check-label"
                            htmlFor="rarityCheckbox3"
                          >
                            <img
                              src="images/rarity/rarity3.png"
                              alt="rarity 3"
                            />
                          </label>
                          <Checkbox className="form-check-input" value="SR" />
                        </div>
                        <div className="form-check">
                          <label
                            className="form-check-label"
                            htmlFor="rarityCheckbox4"
                          >
                            <img
                              src="images/rarity/rarity4.png"
                              alt="rarity 4"
                            />
                          </label>
                          <Checkbox className="form-check-input" value="SSR" />
                        </div>
                      </div>
                    )}
                  </CheckboxGroup>
                </div>
                <div className="general-item general-breed-count">
                  <h5 className="menu-header">Breed Count</h5>
                  <Nouislider
                    range={{ min: 0, max: 5 }}
                    start={[0, 5]}
                    connect
                    onEnd={(value: any) => onEnd(value, 'breedCount')}
                    className="noUi-background"
                    id="m-price-range"
                  />
                  <div className="common-range-slider">
                    <div className="slider-info slider-labels">
                      <div className="caption">
                        <span id="breed-range-value1">{breedCountMin}</span>
                      </div>
                      <div className="text-right caption">
                        <span id="breed-range-value2">{breedCountMax}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </TabPane>

            <TabPane tabId="ability">
              <div className="ability-body">
                <div className="ability-list">
                  <div className="ability-item">
                    <div className="ability-icon">
                      <div className="ability-img-box">
                        <img
                          className="ability-img"
                          src="images/ability/ability1.png"
                          alt="ability 1"
                        />
                      </div>
                    </div>
                    <Nouislider
                      range={{ min: 0, max: 99999999 }}
                      start={[0, 99999999]}
                      connect
                      onEnd={(value: any) => onEnd(value, 'atk')}
                      className="noUi-background"
                      id="m-atk-range"
                    />
                    <div className="common-range-slider">
                      <div className="row">
                        <div className="col-sm-12">
                          <div id="attack-range"></div>
                        </div>
                      </div>

                      <div className="slider-info slider-labels">
                        <div className="caption">
                          <span id="attack-range-value1">{atkMin}</span>
                        </div>
                        <div className="text-right caption">
                          <span id="attack-range-value2">{atkMax}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="ability-item">
                    <div className="ability-icon">
                      <div className="ability-img-box">
                        <img
                          className="ability-img"
                          src="images/ability/ability2.png"
                          alt="ability 2"
                        />
                      </div>
                    </div>
                    <Nouislider
                      range={{ min: 0, max: 99999999 }}
                      start={[0, 99999999]}
                      connect
                      onEnd={(value: any) => onEnd(value, 'def')}
                      className="noUi-background"
                      id="m-price-range"
                    />
                    <div className="common-range-slider">
                      <div className="row">
                        <div className="col-sm-12">
                          <div id="defense-range"></div>
                        </div>
                      </div>
                      <div className="slider-info slider-labels">
                        <div className="caption">
                          <span id="defense-range-value1">{defMin}</span>
                        </div>
                        <div className="text-right caption">
                          <span id="defense-range-value2">{defMax}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="ability-item">
                    <div className="ability-icon">
                      <div className="ability-img-box">
                        <img
                          className="ability-img"
                          src="images/ability/ability3.png"
                          alt="ability 3"
                        />
                      </div>
                    </div>
                    <Nouislider
                      range={{ min: 0, max: 99999999 }}
                      start={[0, 99999999]}
                      connect
                      onEnd={(value: any) => onEnd(value, 'crit')}
                      className="noUi-background"
                      id="m-price-range"
                    />
                    <div className="common-range-slider">
                      <div className="row">
                        <div className="col-sm-12">
                          <div id="crit-range"></div>
                        </div>
                      </div>
                      <div className="slider-info slider-labels">
                        <div className="caption">
                          <span id="crit-range-value1">{critMin}</span>
                        </div>
                        <div className="text-right caption">
                          <span id="crit-range-value2">{critMax}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="ability-item">
                    <div className="ability-icon">
                      <div className="ability-img-box">
                        <img
                          className="ability-img"
                          src="images/ability/ability4.png"
                          alt="ability 4"
                        />
                      </div>
                    </div>
                    <Nouislider
                      range={{ min: 0, max: 99999999 }}
                      start={[0, 99999999]}
                      connect
                      onEnd={(value: any) => onEnd(value, 'dodge')}
                      className="noUi-background"
                      id="m-price-range"
                    />
                    <div className="common-range-slider">
                      <div className="row">
                        <div className="col-sm-12">
                          <div id="armour-range"></div>
                        </div>
                      </div>
                      <div className="slider-info slider-labels">
                        <div className="caption">
                          <span id="armour-range-value1">{dodgeMin}</span>
                        </div>
                        <div className="text-right caption">
                          <span id="armour-range-value2">{dodgeMax}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="ability-item">
                    <div className="ability-icon">
                      <div className="ability-img-box">
                        <img
                          className="ability-img"
                          src="images/ability/ability5.png"
                          alt="ability 5"
                        />
                      </div>
                    </div>
                    <Nouislider
                      range={{ min: 0, max: 99999999 }}
                      start={[0, 99999999]}
                      connect
                      onEnd={(value: any) => onEnd(value, 'hp')}
                      className="noUi-background"
                      id="m-price-range"
                    />
                    <div className="common-range-slider">
                      <div className="row">
                        <div className="col-sm-12">
                          <div id="heart-range"></div>
                        </div>
                      </div>
                      <div className="slider-info slider-labels">
                        <div className="caption">
                          <span id="heart-range-value1">{hpMin}</span>
                        </div>
                        <div className="text-right caption">
                          <span id="heart-range-value2">{hpMax}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="ability-item">
                    <div className="ability-icon">
                      <div className="ability-img-box">
                        <img
                          className="ability-img"
                          src="images/ability/ability6.png"
                          alt="ability 6"
                        />
                      </div>
                    </div>
                    <Nouislider
                      range={{ min: 0, max: 99999999 }}
                      start={[0, 99999999]}
                      connect
                      onEnd={(value: any) => onEnd(value, 'spd')}
                      className="noUi-background"
                      id="m-price-range"
                    />
                    <div className="common-range-slider">
                      <div className="row">
                        <div className="col-sm-12">
                          <div id="speed-range"></div>
                        </div>
                      </div>
                      <div className="slider-info slider-labels">
                        <div className="caption">
                          <span id="speed-range-value1">{spdMin}</span>
                        </div>
                        <div className="text-right caption">
                          <span id="speed-range-value2">{spdMax}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="ability-item">
                    <div className="ability-icon">
                      <div className="ability-img-box">
                        <img
                          className="ability-img"
                          src="images/ability/ability7.png"
                          alt="ability 7"
                        />
                      </div>
                    </div>
                    <Nouislider
                      range={{ min: 0, max: 99999999 }}
                      start={[0, 99999999]}
                      connect
                      onEnd={(value: any) => onEnd(value, 'matk')}
                      className="noUi-background"
                      id="m-price-range"
                    />
                    <div className="common-range-slider">
                      <div className="row">
                        <div className="col-sm-12">
                          <div id="luck-range"></div>
                        </div>
                      </div>
                      <div className="slider-info slider-labels">
                        <div className="caption">
                          <span id="luck-range-value1">{matkMin}</span>
                        </div>
                        <div className="text-right caption">
                          <span id="luck-range-value2">{matkMax}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="ability-item">
                    <div className="ability-icon">
                      <div className="ability-img-box">
                        <img
                          className="ability-img"
                          src="images/ability/ability8.png"
                          alt="ability 8"
                        />
                      </div>
                    </div>
                    <Nouislider
                      range={{ min: 0, max: 99999999 }}
                      start={[0, 99999999]}
                      connect
                      onEnd={(value: any) => onEnd(value, 'mdef')}
                      className="noUi-background"
                      id="m-price-range"
                    />
                    <div className="common-range-slider">
                      <div className="slider-info slider-labels">
                        <div className="caption">
                          <span id="book-range-value1">{mdefMin}</span>
                        </div>
                        <div className="text-right caption">
                          <span id="book-range-value2">{mdefMax}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </TabPane>
            <div className="special-button m-auto mt-5">
              <button className="sp-button" onClick={handleSubmit}>
                Submit
              </button>
            </div>
          </TabContent>
        </div>
      </div>
    </>
  );
};

export default MonstersFilter;
