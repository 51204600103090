import { createSlice } from '@reduxjs/toolkit';

export interface MonsterState {
  monsters: any;
  metaMonster: any;
}

const initialState: MonsterState = {
  monsters: [],
  metaMonster: null,
};

export const monsterSlice = createSlice({
  name: 'monster',
  initialState,
  reducers: {
    setMonsters: (state, action) => {
      state.monsters = action.payload;
    },
    setMetaMonster: (state, action) => {
      state.metaMonster = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setMonsters, setMetaMonster } = monsterSlice.actions;

export default monsterSlice.reducer;
