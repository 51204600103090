import { configureStore } from '@reduxjs/toolkit';
import { save, load } from 'redux-localstorage-simple';
import { useDispatch } from 'react-redux';

import box from './box';
import monster from './monster';
import item from './item';
import inventory from './inventory';
import authen from './authen';
import myNft from './myNft';
import market from './market';
import layout from './layout';
import global from './global';

const PERSISTED_KEYS: string[] = ['box', 'authen'];

const store = configureStore({
  devTools: process.env.NODE_ENV !== 'production',
  reducer: {
    box,
    monster,
    item,
    inventory,
    authen,
    myNft,
    market,
    layout,
    global,
  },
  middleware: [save({ states: PERSISTED_KEYS })],
  preloadedState: load({ states: PERSISTED_KEYS }),
});

export type AppDispatch = typeof store.dispatch;
export type AppState = ReturnType<typeof store.getState>;
export const useAppDispatch = () => useDispatch();

export default store;
