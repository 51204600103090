import { createSlice } from '@reduxjs/toolkit';

export interface AuthenState {
  email: string;
  nonce: string;
  loading: boolean;
}

const initialState: AuthenState = {
  email: '',
  nonce: '',
  loading: false,
};

export const authenSlice = createSlice({
  name: 'authen',
  initialState,
  reducers: {
    setEmail: (state, action) => {
      state.email = action.payload;
    },
    setNonce: (state, action) => {
      state.nonce = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setEmail, setNonce, setLoading } = authenSlice.actions;

export default authenSlice.reducer;
