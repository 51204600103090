import BigNumber from 'bignumber.js/bignumber';
import { BIG_TEN } from 'utils/bigNumber';

import commonBoxImg from 'assets/images/box/common.png';
import ultimateBoxImg from 'assets/images/box/ultimate.png';
import commonBoxDetailImg from 'assets/images/detail/common-detail-box.png';
import ultimateBoxDetailImg from 'assets/images/detail/ultimate-detail-box.png';

export const ChainId = {
  MAINNET: 56,
  TESTNET: 97,
};
BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
});

export const BSC_BLOCK_TIME = 3;

export const BASE_BSC_SCAN_URLS = {
  [ChainId.MAINNET]: 'https://bscscan.com',
  [ChainId.TESTNET]: 'https://testnet.bscscan.com',
};

// CAKE_PER_BLOCK details
// 40 CAKE is minted per block
// 20 CAKE per block is sent to Burn pool (A farm just for burning cake)
// 10 CAKE per block goes to CAKE syrup pool
// 9 CAKE per block goes to Yield farms and lottery
// CAKE_PER_BLOCK in config/index.ts = 40 as we only change the amount sent to the burn pool which is effectively a farm.
// CAKE/Block in src/views/Home/components/CakeDataRow.tsx = 15 (40 - Amount sent to burn pool)
export const CAKE_PER_BLOCK = 40;
export const BLOCKS_PER_YEAR = (60 / BSC_BLOCK_TIME) * 60 * 24 * 365; // 10512000
export const CAKE_PER_YEAR = CAKE_PER_BLOCK * BLOCKS_PER_YEAR;
export const BASE_URL = 'https://pancakeswap.finance';
export const BASE_ADD_LIQUIDITY_URL = `${BASE_URL}/add`;
export const BASE_BSC_SCAN_URL = BASE_BSC_SCAN_URLS[ChainId.MAINNET];
export const DEFAULT_TOKEN_DECIMAL = BIG_TEN.pow(18);
export const DEFAULT_GAS_LIMIT = 200000;
export const AUCTION_BIDDERS_TO_FETCH = 500;
export const RECLAIM_AUCTIONS_TO_FETCH = 500;
export const AUCTION_WHITELISTED_BIDDERS_TO_FETCH = 500;
export const IPFS_GATEWAY = 'https://ipfs.io/ipfs';

export const paramsTetnet = [
  {
    chainId: '0x61',
    chainName: 'BSC Testnet',
    nativeCurrency: {
      name: 'Binance Coin',
      symbol: 'BNB',
      decimals: 18,
    },
    rpcUrls: ['https://data-seed-prebsc-1-s1.binance.org:8545/'],
    blockExplorerUrls: ['https://testnet.bscscan.com/'],
  },
];

export const paramsMainnet = [
  {
    chainId: '0x38',
    chainName: 'BSC Mainnet',
    nativeCurrency: {
      name: 'Binance Coin',
      symbol: 'BNB',
      decimals: 18,
    },
    rpcUrls: ['https://bsc-dataseed.binance.org/'],
    blockExplorerUrls: ['https://bscscan.com/'],
  },
];
export const connectorLocalStorageKey = 'connectorIdv2';
export enum ConnectorNames {
  Injected = 'injected',
  WalletConnect = 'walletconnect',
  BSC = 'bsc',
}
export const walletLocalStorageKey = 'wallet';

export const BOX_TYPE: any = {
  ultimate: {
    name: 'Ultimate Box',
    img: ultimateBoxImg,
    imgDetail: ultimateBoxDetailImg,
  },
  common: {
    name: 'Common Box',
    img: commonBoxImg,
    imgDetail: commonBoxDetailImg,
  },
};
