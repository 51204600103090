import { GAME_ADDRESS, MARKETPLACE_ADDRESS } from 'config/constants/envConst';

export const getBoxStoreAddress = () => {
  return process.env.REACT_APP_BOX_STORE_ADDRESS || '';
};

export const getEtermonBoxAddress = () => {
  return process.env.REACT_APP_ETERMON_BOX_ADDRESS || '';
};

export const getTokenAddress = () => {
  return process.env.REACT_APP_TOKEN_ADDRESS || '';
};

export const getMarketplaceAddress = () => {
  return MARKETPLACE_ADDRESS;
};

export const getGameAddress = () => {
  return GAME_ADDRESS;
};
