import { createSlice } from '@reduxjs/toolkit';

export interface LayoutState {
  openDrawer: boolean;
}

const initialState: LayoutState = {
  openDrawer: false,
};

export const layoutSlice = createSlice({
  name: 'layout',
  initialState,
  reducers: {
    setOpenDrawer: (state, action) => {
      state.openDrawer = action.payload;
    },
  },
});

export const { setOpenDrawer } = layoutSlice.actions;

export default layoutSlice.reducer;
