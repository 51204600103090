import axios from 'axios';
import { API_MARKETPLACE_URL } from 'config/constants/envConst';

const ApiService = axios.create({
  baseURL: `${API_MARKETPLACE_URL}/api/v1/`,
  headers: {
    'Content-Type': 'application/json',
  },
});

ApiService.interceptors.request.use(
  (config: any) => {
    const token = localStorage.getItem('access_token');
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
ApiService.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    if (error.response.status === 401) {
      const token = localStorage.getItem('access_token');

      if (token) {
        localStorage.removeItem('access_token');
        window.location.reload();
      }

      return Promise.reject(error);
    }
    return Promise.reject(error);
  }
);

export default ApiService;
