import { useMemo } from 'react';
import { Contract } from '@ethersproject/contracts';
import { getContract } from 'utils';
import useActiveWeb3React from './useActiveWeb3React';

import {
  getBoxStoreContract,
  getBep20Contract,
  getEtermonBoxContract,
  getMarketplaceContract,
  getGameContract,
} from 'utils/contractHelpers';

export function useContract(
  address: string | undefined,
  ABI: any,
  withSignerIfPossible = true
): Contract | null {
  const { library, account } = useActiveWeb3React();

  return useMemo(() => {
    if (!address || !ABI || !library) return null;
    try {
      return getContract(
        address,
        ABI,
        library,
        withSignerIfPossible && account ? account : undefined
      );
    } catch (error) {
      console.error('Failed to get contract', error);
      return null;
    }
  }, [address, ABI, library, withSignerIfPossible, account]);
}

export const useBoxStoreContract = () => {
  const { library } = useActiveWeb3React();

  return useMemo(() => getBoxStoreContract(library?.getSigner()), [library]);
};

export const useMarketplaceContract = () => {
  const { library } = useActiveWeb3React();

  return useMemo(() => getMarketplaceContract(library?.getSigner()), [library]);
};

export const useGameContract = () => {
  const { library } = useActiveWeb3React();

  return useMemo(() => getGameContract(library?.getSigner()), [library]);
};

export const useEtermonBoxContract = () => {
  const { library } = useActiveWeb3React();

  return useMemo(() => getEtermonBoxContract(library?.getSigner()), [library]);
};

export const useERC20 = (address: string) => {
  const { library } = useActiveWeb3React();
  return useMemo(
    () => getBep20Contract(address, library?.getSigner()),
    [address, library]
  );
};
