export const API_MARKETPLACE_URL = process.env.REACT_APP_API_MARKETPLACE;
export const MARKETPLACE_ADDRESS =
  process.env.REACT_APP_PROXY_MARKETPLACE_ADDRESS || '';
export const ETERMON_BOX_ADDRESS =
  process.env.REACT_APP_ETERMON_BOX_ADDRESS || '';
export const ETERMON_MONSTER_ADDRESS =
  process.env.REACT_APP_ETMON_ADDRESS || '';
export const ETERMON_ITEM_ADDRESS = process.env.REACT_APP_ETI_ADDRESS || '';
export const BUSD_ADDRESS = process.env.REACT_APP_BUSD_ADDRESS || '';
export const GAME_ADDRESS = process.env.REACT_APP_PROXY_GAME_ADDRESS || '';
export const TOKEN_ADDRESS = process.env.REACT_APP_TOKEN_ADDRESS || '';
export const ETC_ADDRESS = process.env.REACT_APP_ETC_ADDRESS || '';
