import { useWeb3React } from '@web3-react/core';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import MyNftLayout from 'components/Layout/MyNftLayout';
import { useAuthenState } from 'state/authen/hooks';

const Settings = () => {
  const { email } = useAuthenState();
  const { account } = useWeb3React();
  const history = useHistory();

  const token = localStorage.getItem('access_token');

  useEffect(() => {
    if (account && token && !email) {
      history.push('/user-link');
    }
  }, [token, account, email, history]);

  return (
    <MyNftLayout rootClass="user-settings">
      <div className="in-wallet in-settings">
        <div className="in-settings-wrapper">
          <div className="in-user-header">
            <h1 className="page-heading">Email Address</h1>
          </div>
          <div className="user-mail-verify">
            <div className="user-mail">
              <span className="mail">{email}</span>
            </div>
            <div className="checked-verify">
              <img
                src="images/wallet/check.png"
                alt="check"
                className="img-check"
              />
              <span className="verify">Verified</span>
            </div>
          </div>
        </div>
      </div>
    </MyNftLayout>
  );
};

export default Settings;
