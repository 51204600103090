import { createSlice } from '@reduxjs/toolkit';

export interface MarketState {
  needApproveAll: boolean;
  isApproved: boolean;
}

const initialState: MarketState = {
  needApproveAll: false,
  isApproved: false,
};

export const marketSlice = createSlice({
  name: 'market',
  initialState,
  reducers: {
    setNeedApproveAll: (state, action) => {
      const needApproveAll = action.payload;
      state.needApproveAll = needApproveAll;
    },
    setApproved: (state, action) => {
      const isApproved = action.payload;
      state.isApproved = isApproved;
    },
  },
});

export const { setNeedApproveAll, setApproved } = marketSlice.actions;

export default marketSlice.reducer;
