import { createSlice } from '@reduxjs/toolkit';

export interface MyNftState {
  boxes: any;
  monsters: any;
  items: any;
  monstersInGame: any;
  itemsInGame: any;
}

const initialState: MyNftState = {
  boxes: [],
  monsters: [],
  items: [],
  monstersInGame: [],
  itemsInGame: [],
};

export const myNftSlice = createSlice({
  name: 'myNft',
  initialState,
  reducers: {
    setBoxes: (state, action) => {
      state.boxes = action.payload;
    },
    setMonsters: (state, action) => {
      state.monsters = action.payload;
    },
    setItems: (state, action) => {
      state.items = action.payload;
    },
    setMonstersInGame: (state, action) => {
      state.monstersInGame = action.payload;
    },
    setItemsInGame: (state, action) => {
      state.itemsInGame = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setBoxes,
  setMonsters,
  setItems,
  setMonstersInGame,
  setItemsInGame,
} = myNftSlice.actions;

export default myNftSlice.reducer;
