import { useSelector } from 'react-redux';
import axios from 'axios';
import _get from 'lodash/get';

import { State } from 'state/types';
import { API_MARKETPLACE_URL } from 'config/constants/envConst';
import { setBoxes, setMetaBox } from '.';
import { useAppDispatch } from 'state';
import { setLoading } from 'state/authen';

export const useBoxes = () => {
  const dispatch = useAppDispatch();

  const getBoxes = async (params: any) => {
    try {
      dispatch(setLoading(true));
      const { data } = await axios.get(`${API_MARKETPLACE_URL}/api/v1/box`, {
        params,
      });
      dispatch(setLoading(false));
      const boxes = _get(data, 'data', []);
      const metaBox = _get(data, 'meta', null);

      dispatch(setBoxes(boxes));
      dispatch(setMetaBox(metaBox));
    } catch (error) {
      dispatch(setLoading(false));
    }
  };

  const getBoxDetail = async (token_id: string) => {
    return await axios.get(
      `${API_MARKETPLACE_URL}/api/v1/box/detail?token_id=${token_id}`
    );
  };

  return { getBoxes, getBoxDetail };
};

export const useBoxState = () => {
  const box = useSelector((state: State) => state.box);

  return box;
};
