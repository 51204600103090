import React from 'react';
import { Link } from 'react-router-dom';

import { BOX_TYPE } from 'config';

interface Props {
  item: any;
}

const BoxDetail: React.FC<Props> = ({ item }) => {
  return (
    <Link
      to={`/box/${item.token_id}`}
      className="list-item-col"
      key={item.token_id}
    >
      <div className="monster-id">
        <span className="id-number">#{item.token_id}</span>
      </div>
      <div className="item-img-box">
        <img
          src={BOX_TYPE[item.type || ''].img}
          className="item-img"
          alt="item 1"
        />
        <div className="name-type">
          <span className="name-type-small-txt">
            {BOX_TYPE[item.type || ''].name}
          </span>
        </div>
        <div className="price-box">
          <div className="price">
            <img src="images/box/busd.png" alt="busd" className="busd-icon" />
          </div>
          <span className="price-number">
            {item.price.toLocaleString('en-US', {
              maximumFractionDigits: 3,
            }) || 0}{' '}
            BUSD
          </span>
        </div>
      </div>
    </Link>
  );
};

export default BoxDetail;
