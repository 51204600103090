/* eslint-disable react-hooks/exhaustive-deps */
import { useWeb3React } from '@web3-react/core';
import _get from 'lodash/get';

import {
  API_MARKETPLACE_URL,
  ETERMON_MONSTER_ADDRESS,
} from 'config/constants/envConst';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Modal, Spinner } from 'reactstrap';
import ApiService from 'services/api';
import { useMarket, useMarketData } from 'state/market/hooks';
import { useMyNft } from 'state/myNft/hooks';
import { getGameAddress } from 'utils/addressHelpers';

interface ModalProps {
  isOpen: boolean;
  item: any;
  closeModal: () => void;
  setSelected: (selected: any) => void;
}

const MonsterClaimConfirm: React.FC<ModalProps> = ({
  isOpen,
  item,
  closeModal,
  setSelected,
}) => {
  const { needApproveAll } = useMarketData();
  const { account } = useWeb3React();
  const { setApprovalForAll, checkApprovedForAll, claimERC721 } = useMarket();
  const { getMonstersInGame } = useMyNft();

  const [loading, setLoading] = useState(false);

  const handleClaim = async () => {
    try {
      setLoading(true);
      const { data } = await ApiService.post(
        `${API_MARKETPLACE_URL}/api/v1/nft/monsters/get-signature`,
        { id: item.id }
      );

      if (data.status === 'success') {
        const signature = _get(data, 'data.signature', '');
        const nonce = _get(data, 'data.nonce', '');
        const params = {
          burnIds: item.burnTokenIds,
          signature: signature,
          etermonNft: ETERMON_MONSTER_ADDRESS,
          tokenId: item.tokenNFT,
          randomNumber: nonce.toString(),
          id: item.id,
          type: 'monster',
        };

        await claimERC721(params);
        closeModal();
        setSelected(null);
        if (account) {
          getMonstersInGame(account);
        }
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
      const message = _get(error, 'response.data.errors.msg', '');
      if (message) {
        toast.error(message);
      } else {
        toast.error('Error! Try again.');
      }
    }
  };

  const handApprove = async () => {
    await setApprovalForAll(ETERMON_MONSTER_ADDRESS, getGameAddress());
    await checkApprovedForAll(ETERMON_MONSTER_ADDRESS, getGameAddress());
  };

  useEffect(() => {
    if (account) {
      checkApprovedForAll(ETERMON_MONSTER_ADDRESS, getGameAddress());
    }
  }, [account]);

  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      wrapClassName="modal-confirm"
      className="modal-xl"
      id="depositModal"
      backdrop="static"
    >
      <div className="modal-content">
        <button
          type="button"
          className="btn-close"
          onClick={() => closeModal()}
        >
          <i className="fas fa-times"></i>
        </button>
        <img
          src="images/wallet/confirm-bg.png"
          alt="confirm background"
          className="modal-link-bg"
        />
        <div className="modal-body">
          <div className="form">
            <h1 className="modal-confirm-heading">Claim Confirmation</h1>
            <span className="modal-small-heading">
              Are you sure you want to claim?
            </span>
            {!needApproveAll && (
              <button
                disabled={loading}
                onClick={handleClaim}
                className="modal-btn-action"
              >
                {loading && <Spinner size="sm" className="me-1" />}
                {!loading && 'Claim'}
              </button>
            )}
            {needApproveAll && (
              <button
                disabled={loading}
                className="modal-btn-action"
                onClick={handApprove}
              >
                {loading && <Spinner size="sm" className="me-1" />}
                {!loading && 'Approve'}
              </button>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default MonsterClaimConfirm;
