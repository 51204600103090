import { createSlice } from '@reduxjs/toolkit';

export interface InventoryState {
  buyBoxes: any[];
}

const initialState: InventoryState = {
  buyBoxes: [],
};

export const inventorySlice = createSlice({
  name: 'inventory',
  initialState,
  reducers: {
    setBoxes: (state, action) => {
      const buyBoxes = action.payload;

      state.buyBoxes = buyBoxes;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setBoxes } = inventorySlice.actions;

export default inventorySlice.reducer;
