import { useSelector } from 'react-redux';
import axios from 'axios';
import _get from 'lodash/get';

import { State } from 'state/types';
import { API_MARKETPLACE_URL } from 'config/constants/envConst';
import { setMonsters, setMetaMonster } from '.';
import { useAppDispatch } from 'state';
import { setLoading } from 'state/authen';

export const useMonsters = () => {
  const dispatch = useAppDispatch();

  const getMonsters = async (body: any) => {
    try {
      dispatch(setLoading(true));
      const { data } = await axios.post(
        `${API_MARKETPLACE_URL}/api/v1/monster`,
        body
      );
      dispatch(setLoading(false));
      const monsters = _get(data, 'data', []);
      const metaBox = _get(data, 'meta', null);

      dispatch(setMonsters(monsters));
      dispatch(setMetaMonster(metaBox));
    } catch (error) {
      dispatch(setLoading(false));
    }
  };

  const getMonsterDetail = async (token_id: string) => {
    return await axios.get(
      `${API_MARKETPLACE_URL}/api/v1/monster/detail?token_id=${token_id}`
    );
  };

  const getMonsterInGameDetail = async (token_id: string) => {
    return await axios.get(
      `${API_MARKETPLACE_URL}/api/v1/monster/detail?monsterId=${token_id}`
    );
  };

  return { getMonsters, getMonsterDetail, getMonsterInGameDetail };
};

export const useMonsterState = () => {
  const box = useSelector((state: State) => state.monster);

  return box;
};
