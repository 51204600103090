import { useWeb3React } from '@web3-react/core';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import MyNftLayout from 'components/Layout/MyNftLayout';
import { useAuthenState } from 'state/authen/hooks';
import { truncate } from 'utils';
import { useGlobalState } from 'state/global/hooks';
import ClaimModal from './ClaimModal';
import DepositModal from './DepositModal';

const WalletPage = () => {
  const { email } = useAuthenState();
  const { account } = useWeb3React();
  const history = useHistory();
  const {
    etmBalance,
    etcBalance,
    bnbBalance,
    busdBalance,
    etmInGameBalance,
    etcInGameBalance,
  } = useGlobalState();

  const [isOpenClaimModal, setOpenClaimModal] = useState(false);
  const [isOpenDepositModal, setOpenDepositModal] = useState(false);
  const [type, setType] = useState('ETM');

  const token = localStorage.getItem('access_token');

  useEffect(() => {
    if (account && token && !email) {
      history.push('/user-link');
    }
  }, [token, account, email, history]);

  return (
    <MyNftLayout rootClass="user-settings">
      <div className="in-wallet">
        <div className="in-wallet-wrapper">
          <div className="in-user-header">
            <h1 className="page-heading">In wallet</h1>
            <span className="user-id-wallet">
              {truncate(account || '', 6, 4)}
            </span>
          </div>
          <div className="in-wallet-body">
            <div className="in-wallet-deposit">
              <div className="in-wallet-deposit-box deposit-coin">
                <span className="box-heading">Etermon Token (ETM)</span>
                <div className="currency-group">
                  <span className="currency-coin">
                    <span>
                      {Number(etmBalance).toLocaleString('en-US', {
                        maximumFractionDigits: 2,
                      })}
                    </span>
                  </span>
                </div>
                <img
                  src="images/wallet/etm.png"
                  alt="ETM"
                  className="coin-img"
                />
                <button
                  className="transfer-btn"
                  type="button"
                  onClick={() => {
                    setType('ETM');
                    setOpenDepositModal(true);
                  }}
                >
                  Deposit
                </button>
              </div>
              <div className="in-wallet-deposit-box deposit-coin">
                <span className="box-heading">Etermon Coin (ETC)</span>
                <div className="currency-group">
                  <span className="currency-coin">
                    <span>
                      {Number(etcBalance).toLocaleString('en-US', {
                        maximumFractionDigits: 2,
                      })}
                    </span>
                  </span>
                </div>
                <img
                  src="images/wallet/etc.png"
                  alt="ETC"
                  className="coin-img"
                />
                <button
                  className="transfer-btn"
                  type="button"
                  onClick={() => {
                    setType('ETC');
                    setOpenDepositModal(true);
                  }}
                >
                  Deposit
                </button>
              </div>
              <div className="in-wallet-deposit-box other-currencies">
                <span className="box-heading">Other Currencies</span>
                <div className="currency-group">
                  <span className="currency-coin">
                    <span>
                      {Number(bnbBalance).toLocaleString('en-US', {
                        maximumFractionDigits: 2,
                      })}
                    </span>{' '}
                    BNB
                  </span>
                </div>
                <div className="currency-group">
                  <span className="currency-coin">
                    <span>
                      {Number(busdBalance).toLocaleString('en-US', {
                        maximumFractionDigits: 2,
                      })}
                    </span>{' '}
                    BUSD
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="in-wallet-wrapper">
          <div className="in-user-header">
            <h1 className="page-heading">In Game</h1>
          </div>
          <div className="in-wallet-body">
            <div className="in-wallet-claim">
              <div className="in-wallet-claim-box">
                <span className="box-heading">Etermon Token: ETM</span>
                <div className="currency-group">
                  <span className="currency-coin">
                    <span>{etmInGameBalance}</span>
                  </span>
                </div>
                <img
                  src="images/wallet/etm.png"
                  alt="ETM"
                  className="coin-img"
                />
                <button
                  className="transfer-btn"
                  type="button"
                  onClick={() => {
                    setType('ETM');
                    setOpenClaimModal(true);
                  }}
                >
                  Claim
                </button>
              </div>
              <div className="in-wallet-claim-box">
                <span className="box-heading">Etermon Coin: ETC</span>
                <div className="currency-group">
                  <span className="currency-coin">
                    <span>{etcInGameBalance}</span>
                  </span>
                </div>
                <img
                  src="images/wallet/etc.png"
                  alt="ETC"
                  className="coin-img"
                />
                <button
                  className="transfer-btn"
                  type="button"
                  onClick={() => {
                    setType('ETC');
                    setOpenClaimModal(true);
                  }}
                >
                  Claim
                </button>
              </div>
              <div className="in-wallet-claim-box invisible">
                <span className="box-heading">Etermon Coin: ETC</span>
                <div className="currency-group">
                  <span className="currency-coin">
                    <span>0</span>
                  </span>
                  <span className="currency-usd">
                    <span>0</span> USD
                  </span>
                </div>
                <img
                  src="images/wallet/etc.png"
                  alt="ETC"
                  className="coin-img"
                />
                <button className="transfer-btn" type="button">
                  Claim
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {account && (
        <>
          <ClaimModal
            isOpen={isOpenClaimModal}
            closeModal={() => setOpenClaimModal(false)}
            type={type}
          />
          <DepositModal
            isOpen={isOpenDepositModal}
            closeModal={() => setOpenDepositModal(false)}
            type={type}
          />
        </>
      )}
    </MyNftLayout>
  );
};

export default WalletPage;
