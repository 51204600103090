/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { Switch, BrowserRouter as Router, Route } from 'react-router-dom';
import LoadingOverlay from 'react-loading-overlay';

import useEagerConnect from 'hooks/useEagerConnect';
import { useAuthenState } from 'state/authen/hooks';
import { routes } from 'routes';
import { useGlobal } from 'state/global/hooks';
import { useWeb3React } from '@web3-react/core';
import { useDispatch } from 'react-redux';
import { setLoading } from 'state/authen';

function App() {
  const queryParams = new URLSearchParams(window.location.search);
  const utm_source = queryParams.get('utm_source');

  if (utm_source) {
    localStorage.setItem('utm_source', utm_source);
  }

  useEagerConnect();
  const { fetchBalance } = useGlobal();
  const { account } = useWeb3React();
  const { loading } = useAuthenState();
  const dispatch = useDispatch();

  const access_token = localStorage.getItem('access_token');

  useEffect(() => {
    if (account && access_token) {
      fetchBalance();
    }
  }, [account, access_token]);

  useEffect(() => {
    dispatch(setLoading(false));
  }, []);

  return (
    <LoadingOverlay active={loading} spinner text="">
      <Router>
        <Switch>
          {routes.map((route, idx) => (
            <Route
              path={route.path}
              component={route.component}
              key={idx}
              exact
            />
          ))}
        </Switch>
      </Router>
    </LoadingOverlay>
  );
}

export default App;
