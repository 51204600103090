/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import Layout from 'components/Layout';
import React, { useEffect, useState } from 'react';
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
import { useWeb3React } from '@web3-react/core';

import SideMenu from './components/SideMenu';
import Boxes from './components/Boxes';
import CommingSoonModal from 'components/CommingSoonModal';
import { useBoxes, useBoxState } from 'state/box/hooks';
import useDebounce from 'hooks/useDebounce';
import { useAuthenState } from 'state/authen/hooks';
import NavMarket from 'components/NavMarket';
import { useHistory } from 'react-router-dom';
import _get from 'lodash/get';

const sortByText: any = {
  lastest: 'Lastest',
  lowest_price: 'Lowest Price',
  highest_price: 'Highest Price',
};
const DEBOUNCE_DELAY = 500;

const BoxPage = () => {
  const { getBoxes } = useBoxes();
  const { email } = useAuthenState();
  const { account } = useWeb3React();
  const history = useHistory();
  const { metaBox } = useBoxState();

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [isOpenModalComing, setOpenModalComing] = useState(false);
  const [filter, setFilter] = useState({ price_min: 0, price_max: 99999999 });
  const [sortBy, setSortBy] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [openDrawer, setOpenDrawer] = useState(false);
  const page = _get(metaBox, 'page', 1);

  const debouncedSearchTerm = useDebounce(searchValue, DEBOUNCE_DELAY);
  const token = localStorage.getItem('access_token');

  useEffect(() => {
    getBoxes({ page, ...filter });
  }, [filter]);

  useEffect(() => {
    if (account && token && !email) {
      history.push('/user-link');
    }
  }, [token, account, email]);

  useEffect(() => {
    if (sortBy) {
      const temp: any = { ...filter };
      temp.sort = sortBy;
      setFilter(temp);
    }
  }, [sortBy]);

  useEffect(
    () => {
      const temp: any = { ...filter };
      temp.q = searchValue;

      if (!searchValue) {
        delete temp.q;
      }

      setFilter(temp);
    },
    [debouncedSearchTerm] // Only call effect if debounced search term changes
  );

  const handleFilter = async (e: any) => {
    const value = e.target.value;
    setSearchValue(value);
  };

  return (
    <Layout>
      <div className="main-split">
        <SideMenu
          setFilter={setFilter}
          filter={filter}
          openDrawer={openDrawer}
          setOpenDrawer={setOpenDrawer}
        />

        <div className="main-list-right">
          <div className="main-nav">
            <NavMarket />
          </div>
          <div className="list-sort">
            <span
              className="filter-toggle"
              id="jsFilterToggle"
              onClick={() => setOpenDrawer(true)}
            >
              <i className="fas fa-bars"></i> Filter
            </span>
            <div className="search-box">
              <div className="form-group has-search">
                <i className="fas fa-search form-control-feedback"></i>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search By ID/Owner"
                  onChange={handleFilter}
                />
              </div>
            </div>
            <div className="sort-box">
              <Dropdown
                isOpen={dropdownOpen}
                toggle={() => setDropdownOpen(!dropdownOpen)}
              >
                <div className="btn-group">
                  <DropdownToggle className="btn btn-txt">
                    {sortBy ? sortByText[sortBy] : 'Sort by'}
                  </DropdownToggle>
                  <DropdownToggle
                    caret
                    className="btn btn-drop dropdown-toggle dropdown-toggle-split"
                  ></DropdownToggle>
                </div>

                <DropdownMenu>
                  <li>
                    <div
                      className="dropdown-item cursor-pointer"
                      onClick={() => setSortBy('lastest')}
                    >
                      Lastest
                    </div>
                  </li>
                  <li>
                    <div
                      className="dropdown-item cursor-pointer"
                      onClick={() => setSortBy('lowest_price')}
                    >
                      Lowest Price
                    </div>
                  </li>
                  <li>
                    <div
                      className="dropdown-item cursor-pointer"
                      onClick={() => setSortBy('highest_price')}
                    >
                      Highest Price
                    </div>
                  </li>
                </DropdownMenu>
              </Dropdown>
            </div>
          </div>

          <Boxes filter={filter} setFilter={setFilter} />
        </div>
      </div>

      <CommingSoonModal
        isOpen={isOpenModalComing}
        closeModal={() => setOpenModalComing(false)}
      />
    </Layout>
  );
};

export default BoxPage;
