import React from 'react';
import { Redirect } from 'react-router-dom';

import BoxPage from 'pages/BoxPage';
import MonsterPage from 'pages/MonsterPage';
import ItemPage from 'pages/ItemPage';
import DetailMonsterPage from 'pages/DetailMonsterPage';
import InWallet from 'pages/InWallet';
import DetailItemPage from 'pages/DetailItemPage';
import DetailBoxPage from 'pages/DetailBoxPage';
import Settings from 'pages/Settings';
import UserTransactions from 'pages/UserTransactions';
import UserLink from 'pages/UserLink';
import WalletPage from 'pages/WalletPage';
import InGame from 'pages/InGame';

export const routes = [
  { path: '/box', component: BoxPage },
  { path: '/monster', component: MonsterPage },
  { path: '/item', component: ItemPage },
  { path: '/user-link', component: UserLink },
  { path: '/monster/:id', component: DetailMonsterPage },
  { path: '/item/:id', component: DetailItemPage },
  { path: '/box/:id', component: DetailBoxPage },
  { path: '/settings', component: Settings },
  { path: '/wallet', component: WalletPage },
  { path: '/in-wallet', component: InWallet },
  { path: '/in-game', component: InGame },
  { path: '/user-transactions', component: UserTransactions },
  { path: '/', exact: true, component: () => <Redirect to="/box" /> },
];
