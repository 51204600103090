/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useWeb3React } from '@web3-react/core';
import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';
import cn from 'classnames';

import MyNftLayout from 'components/Layout/MyNftLayout';
import { useEffect, useState } from 'react';
import { useMyNft } from 'state/myNft/hooks';
import { truncate } from 'utils';
import dayjs from 'dayjs';
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
import { useAuthenState } from 'state/authen/hooks';
import { useHistory } from 'react-router-dom';

const sortByText: any = {
  all: 'all',
  listing: 'Listing',
  sold: 'Sold',
  unlisted: 'Unlisted',
  bought: 'Bought',
};

const UserTransactions = () => {
  const { getAllTransactions } = useMyNft();
  const { account } = useWeb3React();
  const { email } = useAuthenState();
  const history = useHistory();

  const token = localStorage.getItem('access_token');

  const [transactions, setTransactions] = useState([]);
  const [meta, setMeta] = useState(null);
  const [params, setParam] = useState({ limit: 50, offset: 1 });
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [sortBy, setSortBy] = useState('all');

  const hasPreviousPage = _get(meta, 'hasPreviousPage', false);
  const hasNextPage = _get(meta, 'hasNextPage', false);
  const pageCount = _get(meta, 'pageCount', 0);
  const page = _get(meta, 'page', 1);
  let pages = [];
  for (let i = 0; i < pageCount; i++) {
    pages.push(i + 1);
  }

  useEffect(() => {
    if (account && token && !email) {
      history.push('/user-link');
    }
  }, [token, account, email]);

  const fetchTransaction = async () => {
    const { data } = await getAllTransactions(params);

    if (data.status) {
      setTransactions(data.data);
      setMeta(data.meta);
    }
  };

  const handleChangePage = (p: number) => {
    let temp: any = { ...params };

    temp.offset = p;

    setParam(temp);
  };

  const handleFilter = (type: string) => {
    let temp: any = { ...params };
    if (type === 'all') {
      setSortBy('all');
      delete temp.status;
    } else if (type === 'listing') {
      setSortBy('listing');
      temp.status = 'LISTING';
    } else if (type === 'sold') {
      setSortBy('sold');
      temp.status = 'SOLD';
    } else if (type === 'unlisted') {
      setSortBy('unlisted');
      temp.status = 'UNLISTED';
    } else if (type === 'bought') {
      setSortBy('bought');
      temp.status = 'BOUGHT';
    }

    setParam(temp);
  };

  useEffect(() => {
    fetchTransaction();
  }, [params]);

  return (
    <MyNftLayout rootClass="user-transaction">
      <div className="in-wallet in-transaction">
        <div className="dropdown-filter">
          <Dropdown
            isOpen={dropdownOpen}
            toggle={() => setDropdownOpen(!dropdownOpen)}
          >
            <DropdownToggle
              className="btn btn-custom dropdown-toggle"
              id="dropdownMenuFilter"
            >
              {sortBy ? sortByText[sortBy] : 'Sort by'}
            </DropdownToggle>

            <DropdownMenu className="dropdown-menu-filter">
              <li>
                <div
                  className="dropdown-item cursor-pointer"
                  onClick={() => handleFilter('all')}
                >
                  All
                </div>
              </li>

              <li>
                <div
                  className="dropdown-item cursor-pointer"
                  onClick={() => handleFilter('listing')}
                >
                  Listing
                </div>
              </li>
              <li>
                <div
                  className="dropdown-item cursor-pointer"
                  onClick={() => handleFilter('sold')}
                >
                  Sold
                </div>
              </li>
              <li>
                <div
                  className="dropdown-item cursor-pointer"
                  onClick={() => handleFilter('unlisted')}
                >
                  Unlisted
                </div>
              </li>
              <li>
                <div
                  className="dropdown-item cursor-pointer"
                  onClick={() => handleFilter('bought')}
                >
                  Bought
                </div>
              </li>
            </DropdownMenu>
          </Dropdown>
        </div>
        <div className="in-transaction-wrapper">
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th>NFT ID</th>
                  <th>Trans ID</th>
                  <th>From</th>
                  <th>To</th>
                  <th>Total</th>
                  <th>Date</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {_isEmpty(transactions) && (
                  <tr>
                    <td colSpan={8}>Make your first transaction now</td>
                  </tr>
                )}

                {!_isEmpty(transactions) &&
                  transactions.map((item: any, idx: number) => (
                    <tr key={idx}>
                      <td>{item.tokenid}</td>
                      <td>
                        <a
                          href={`${process.env.REACT_APP_BSC_SCAN}/tx/${item.tx}`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {truncate(item.tx, 2, 4)}
                        </a>
                      </td>
                      <td>
                        <a
                          href={`${process.env.REACT_APP_BSC_SCAN}/address/${item.from}`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {truncate(item.from, 2, 4)}
                        </a>
                      </td>
                      <td>
                        <a
                          href={`${process.env.REACT_APP_BSC_SCAN}/address/${item.to}`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {truncate(item.to, 2, 4)}
                        </a>
                      </td>
                      <td>
                        {Number(item.total).toLocaleString('en-US', {
                          maximumFractionDigits: 3,
                        }) || '0'}{' '}
                        {item.nftType === 'BOX' ? 'BUSD' : 'ETM'}
                      </td>
                      <td>{dayjs.unix(item.time).format('DD/MM/YYYY')}</td>
                      <td>{item.status}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
          <div className="list-footer">
            {pages.length > 0 && (
              <nav aria-label="Page navigation">
                <ul className="pagination">
                  <li
                    className={cn('page-item', { disabled: !hasPreviousPage })}
                    onClick={() => {
                      if (hasPreviousPage) handleChangePage(page - 1);
                    }}
                  >
                    <a className="page-link" aria-label="Previous">
                      <span aria-hidden="true">
                        <i className="fas fa-chevron-left"></i>
                      </span>
                    </a>
                  </li>
                  {pages.map((item) => (
                    <li
                      className={cn('page-item cursor-pointer', {
                        active: page === item,
                      })}
                      onClick={() => {
                        if (page !== item) handleChangePage(item);
                      }}
                      key={item}
                    >
                      <a className="page-link">{item}</a>
                    </li>
                  ))}

                  <li
                    className={cn('page-item', { disabled: !hasNextPage })}
                    onClick={() => {
                      if (hasNextPage) handleChangePage(page + 1);
                    }}
                  >
                    <a className="page-link" aria-label="Next">
                      <span aria-hidden="true">
                        <i className="fas fa-chevron-right"></i>
                      </span>
                    </a>
                  </li>
                </ul>
              </nav>
            )}

            <div className="list-social">
              <a
                href="https://t.me/EtermonAnnouncement"
                className="social-item"
                target="_blank"
                rel="noreferrer"
              >
                <img src="images/marketplace-footer/tele.png" alt="telegram" />
              </a>
              <a
                href="https://discord.com/invite/m6QTpYK7pW"
                className="social-item"
                target="_blank"
                rel="noreferrer"
              >
                <img src="images/marketplace-footer/dc.png" alt="discord" />
              </a>
              <a
                href="https://twitter.com/EtermonOfficial"
                className="social-item"
                target="_blank"
                rel="noreferrer"
              >
                <img src="images/marketplace-footer/tw.png" alt="twitter" />
              </a>
              <a
                href="https://www.facebook.com/groups/EtermonCommunity"
                className="social-item"
                target="_blank"
                rel="noreferrer"
              >
                <img src="images/marketplace-footer/fb.png" alt="facebook" />
              </a>
              <a
                href="https://www.youtube.com/EtermonOfficial"
                className="social-item"
                target="_blank"
                rel="noreferrer"
              >
                <img src="images/marketplace-footer/ytb.png" alt="youtube" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </MyNftLayout>
  );
};

export default UserTransactions;
