import { useWeb3React } from '@web3-react/core';

import Layout from 'components/Layout';
import React, { useEffect, useState } from 'react';
import { useAuthenState } from 'state/authen/hooks';
import WalletCreateModal from './WalletCreateModal';

const UserLink = () => {
  const { email } = useAuthenState();
  const { account } = useWeb3React();

  const [isOpen, setOpen] = useState(false);
  const [isLink, setLink] = useState(false);

  const token = localStorage.getItem('access_token');

  const openWalletCreateModal = () => {
    setOpen(true);
    setLink(false);
  };

  const openWalletModal = () => {
    setOpen(true);
    setLink(true);
  };

  useEffect(() => {
    if (account && token && email) {
      window.location.replace('/in-wallet');
    }
  }, [token, account, email]);

  return (
    <Layout>
      <div className="container">
        <div className="account-connect">
          <img
            src="images/wallet/connect-bg.png"
            className="connect-bg-img"
            alt="connect background"
          />
          <div className="account-connect-body">
            <div>
              <h1 className="connect-header">You are almost there!</h1>
              <h4 className="connect-small-header">
                Connect your game account to continue on marketplace
              </h4>
            </div>
            <div className="button-link">
              <button className="link-account" onClick={openWalletModal}>
                Link to Existing game account
              </button>
              <button
                className="create-account"
                onClick={openWalletCreateModal}
              >
                Create new game account
              </button>
            </div>
          </div>
        </div>
      </div>

      <WalletCreateModal
        isOpen={isOpen}
        isLink={isLink}
        setLink={setLink}
        closeModal={() => {
          setOpen(false);
        }}
      />
    </Layout>
  );
};

export default UserLink;
