/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import useAuth from 'hooks/useAuth';
import { connectorLocalStorageKey, ConnectorNames } from 'config';

const _binanceChainListener = async () =>
  new Promise<void>((resolve) =>
    Object.defineProperty(window, 'BinanceChain', {
      get() {
        return this.bsc;
      },
      set(bsc) {
        this.bsc = bsc;

        resolve();
      },
    })
  );

const useEagerConnect = () => {
  const { login, logout } = useAuth();
  const token = localStorage.getItem('access_token');

  useEffect(() => {
    if (window && window.ethereum) {
      // @ts-ignore
      window.ethereum.on('accountsChanged', async () => {
        logout();
        localStorage.removeItem('access_token');
      });
    }
  }, []);

  useEffect(() => {
    if (!token) {
      logout();
      if (
        ['/in-wallet', '/settings', '/user-transactions'].includes(
          window.location.pathname
        )
      ) {
        window.location.replace('/');
      }
    }
  }, [token]);

  useEffect(() => {
    const connectorId = window.localStorage.getItem(
      connectorLocalStorageKey
    ) as ConnectorNames;

    if (connectorId) {
      const isConnectorBinanceChain = connectorId === ConnectorNames.BSC;
      const isBinanceChainDefined = Reflect.has(window, 'BinanceChain');

      // Currently BSC extension doesn't always inject in time.
      // We must check to see if it exists, and if not, wait for it before proceeding.
      if (isConnectorBinanceChain && !isBinanceChainDefined) {
        _binanceChainListener().then(() => login(connectorId));

        return;
      }

      login(connectorId);
    }
  }, [login]);
};

export default useEagerConnect;
