import React from 'react';
import { Modal } from 'reactstrap';
import QRCode from 'react-qr-code';
import { useWeb3React } from '@web3-react/core';

interface ModalProps {
  isOpen: boolean;
  closeModal: () => void;
}

const QRCodeModal: React.FC<ModalProps> = ({ isOpen, closeModal }) => {
  const { account } = useWeb3React();
  return (
    <Modal
      isOpen={isOpen}
      toggle={closeModal}
      role="dialog"
      autoFocus={true}
      centered={true}
      wrapClassName="modal-qr"
      className="modal-lg"
      id="QRModal"
      backdrop="static"
    >
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title" id="QRModalLabel">
            Wallet QR Code
          </h5>
          <button
            type="button"
            className="btn-close"
            onClick={() => closeModal()}
          ></button>
        </div>
        <div className="modal-body">
          <QRCode value={account || ''} />
        </div>
      </div>
    </Modal>
  );
};

export default QRCodeModal;
