import { useSelector } from 'react-redux';
import _get from 'lodash/get';

import { State } from 'state/types';
import { API_MARKETPLACE_URL } from 'config/constants/envConst';
import {
  setBoxes,
  setItems,
  setItemsInGame,
  setMonsters,
  setMonstersInGame,
} from '.';
import { useAppDispatch } from 'state';
import ApiService from 'services/api';
import { toast } from 'react-toastify';
import { setLoading } from 'state/authen';

export const useMyNft = () => {
  const dispatch = useAppDispatch();

  const getBoxes = async (account: string) => {
    try {
      dispatch(setLoading(true));
      const { data } = await ApiService.get(
        `${API_MARKETPLACE_URL}/api/v1/nft/box?address=${account}`
      );

      dispatch(setLoading(false));

      const boxes = _get(data, 'data', []);

      dispatch(setBoxes(boxes));
    } catch (error) {
      dispatch(setLoading(false));
      toast.error('Get box error!');
      console.log(error);
    }
  };

  const getMonsters = async (account: string) => {
    try {
      dispatch(setLoading(true));
      const { data } = await ApiService.get(
        `${API_MARKETPLACE_URL}/api/v1/nft/monsters?address=${account}`
      );
      dispatch(setLoading(false));
      const monsters = _get(data, 'data', []);

      dispatch(setMonsters(monsters));
    } catch (error) {
      dispatch(setLoading(false));
    }
  };

  const getItems = async (account: string) => {
    try {
      dispatch(setLoading(true));
      const { data } = await ApiService.get(
        `${API_MARKETPLACE_URL}/api/v1/nft/item?address=${account}`
      );
      dispatch(setLoading(false));
      const items = _get(data, 'data', []);

      dispatch(setItems(items));
    } catch (error) {
      dispatch(setLoading(false));
    }
  };

  const getMonstersInGame = async (account: string) => {
    try {
      dispatch(setLoading(true));
      const { data } = await ApiService.get(
        `${API_MARKETPLACE_URL}/api/v1/nft/monsters/in-game?address=${account}`
      );
      dispatch(setLoading(false));
      const monsters = _get(data, 'data', []);
      dispatch(setMonstersInGame(monsters));
    } catch (error) {
      dispatch(setLoading(false));
    }
  };

  const getItemsInGame = async (account: string) => {
    try {
      dispatch(setLoading(true));
      const { data } = await ApiService.get(
        `${API_MARKETPLACE_URL}/api/v1/nft/item/in-game?address=${account}`
      );
      dispatch(setLoading(false));

      const items = _get(data, 'data', []);
      dispatch(setItemsInGame(items));
    } catch (error) {
      dispatch(setLoading(false));
    }
  };

  const getAllTransactions = (params: any) => {
    return ApiService.get(
      `${API_MARKETPLACE_URL}/api/v1/transactions-history`,
      { params }
    );
  };

  return {
    getBoxes,
    getAllTransactions,
    getMonsters,
    getItems,
    getMonstersInGame,
    getItemsInGame,
  };
};

export const useMyNftState = () => {
  const myNft = useSelector((state: State) => state.myNft);

  return myNft;
};
