import React from 'react';
import BoxesFilter from './BoxesFilter';

interface Props {
  filter: any;
  openDrawer: boolean;
  setFilter: (filter: any) => void;
  setOpenDrawer: (openDrawer: boolean) => void;
}

const SideMenu: React.FC<Props> = ({
  filter,
  setFilter,
  openDrawer,
  setOpenDrawer,
}) => {
  return (
    <BoxesFilter
      setFilter={setFilter}
      filter={filter}
      openDrawer={openDrawer}
      setOpenDrawer={setOpenDrawer}
    />
  );
};

export default SideMenu;
